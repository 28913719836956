import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  Divider,
  ListItemText,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Avatar from "react-avatar";
const PersonalInfo = (props) => {
  const { name, email, leetcode, batch } = props;
 
  return (
    <>
      <List
      >
        <Box sx={{  mt : -5, justifyContent: "center", display: "flex"}}>
          <Avatar round={true} name={name} />
        </Box>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16, fontWeight: 600, mb : "8px" }}>
               Full Name
              </Typography>
            }
            secondary={<Typography sx={{ fontSize: 16 }}>{name}</Typography>}
          />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16, fontWeight: 600 , mb : "8px",overflow :"hidden"}}>
                Email
              </Typography>
            }
            secondary={<Typography sx={{ fontSize: 16 }}>
              
              {/* {email.length() <= 27 ? {email} : email.substring(0,24) + "..."} */}
              {email}
              </Typography>}
            
          />
        </ListItem> 
        <Divider variant="middle" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16, fontWeight: 600 , mb : "8px"  }}>
                Leetcode id 
              </Typography>
            }
            secondary={<Typography sx={{ fontSize: 16 }}>{leetcode ? leetcode : "NOT ADDED"}</Typography>}
          />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16, fontWeight: 600 , mb : "8px" }}>
                Batch
              </Typography>
            }
            secondary={<Typography sx={{ fontSize: 16 }}>{batch}</Typography>}
          />
        </ListItem>
      </List>
    </>
  );
};

export default PersonalInfo;
