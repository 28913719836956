import React, { Component } from "react";
import SplitPane from "split-pane-react";
import MenuItem from "@mui/material/MenuItem";
import "split-pane-react/esm/themes/default.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Toolbar } from "@mui/material";
import { createQuestion } from "../../../api/adminApi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import ReactGA from "react-ga";
import axios from "../../../api/axios";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class CreateQuestionForm extends Component {
  state = {
    constraints: "",
    inputOutput: "",
    panelsizes: ["50%", "50%"],
    topic: "",
    difficulty_level: "easy",
    tags_input: "",
    tags: [],
    success_toast: false,
    error_toast: false,
    question_number: 1,
    problem_name: "",
    leetcode_link: "",
    search_loading: false,
    inputs: [{ value: "" }],
    question_details:
      "<p> Hello </p> \n <br /> \n <b> <i style='color: green; background-color: white;'>  Write Anything  </i> </b> ",
  };

  componentDidMount() {}
  onPanelSizeChange = (e) => {
    this.setState({ panelsizes: e });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSelectDifficulty = (e) => {
    this.setState({
      difficulty_level: e.target.value,
    });
  };

  handleSearch = (e) => {
    this.setState({ search_loading: true });
    axios
      .post("/admin/get_question_by_number", {
        question_number: this.state.question_number,
      })
      .then((res) => {
        if (res?.data?.success) {
          let data = res.data.response;
          this.setState(
            (prevState) => ({
              success_toast: true,
              search_loading: false,
              difficulty_level: data.difficulty_level,
              question_details: res?.data?.response?.question_details,
            }),
            () => {
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ search_loading: false, error_toast: true });
      });
  };

  handleSearch = (e) => {
    this.setState({ search_loading: true });
    axios
      .post("/admin/get_question_by_number", {
        question_number: this.state.question_number,
      })
      .then((res) => {
        if (res?.data?.success) {
          let data = res.data.response;
          this.setState({
            search_loading: false,
            difficulty_level: data[0].difficulty_level,
            leetcode_link: data[0].leetcode_link,
            problem_name: data[0].problem_name,
            question_details: data[0].question_details,
            inputs: data[0].input ? data[0]?.input : this.state?.inputs,
            constraints: data[0].constraints,
            question_number: data[0].question_number,
            tags_input: data[0].tags,
            topic: data[0].topic,
          });
        }
      })
      .catch((err) => {
        this.setState({ search_loading: false });
        this.setState({ error_toast: true });
      });
  };

  handleSubmit = (e) => {
    let tags = this.state.tags_input.replace(" ", "");
    tags = tags.split(",");
    let data = {
      topic: this.state.topic,
      difficulty_level: this.state.difficulty_level,
      tags: tags,
      question_details: this.state.question_details,
      problem_name: this.state.problem_name,
      question_number: this.state.question_number,
      leetcode_link: this.state.leetcode_link,
      input: this.state?.inputs?.filter((input) => input.value !== ""),
      constraints: this.state.constraints,
    };
    createQuestion(data).then((res) => {
      if (res.data.success) {
        this.setState({ success_toast: true });
      } else {
        this.setState({ error_toast: true });
      }
    });
  };
  handleAddInput = () => {
    this.setState({
      inputs: [...this.state.inputs, { value: "" }],
    });
  };

  handleInputChange = (e, index) => {
    const newInputs = [...this.state.inputs];
    newInputs[index].value = e.target.value;
    this.setState({ inputs: newInputs });
  };

  render() {
    let htmlString = this.state.question_details;
    if (this.state && this.state.inputs) {
      this.state?.inputs?.map((input, index) => {
        htmlString += input.value + " ";
      });
    }
    htmlString += `<br/>  ${this.state.constraints}`;
    const ColorButton = styled(LoadingButton)(({ theme }) => ({
      backgroundColor: "#1d99be",
      "&:hover": {
        backgroundColor: "#1d8cbe",
      },
    }));
    return (
      <div style={{ height: "150vh" }}>
        <Snackbar
          open={this.state.success_toast}
          autoHideDuration={3000}
          onClose={this.handleToastClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Question Created Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.error_toast}
          autoHideDuration={3000}
          onClose={this.handleToastClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
        <Box style={{ backgroundColor: "#333333" }}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <img
              src="https://uploads-ssl.webflow.com/60e1490057a19b506bd4ebb3/60fd54c519be8bccfd2922de_bosscoder_header_logo_2.svg"
              alt="logo"
            />
          </Toolbar>
        </Box>
        <SplitPane
          split="vertical"
          sizes={this.state.panelsizes}
          onChange={this.onPanelSizeChange}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              background: "#333333",
              borderStyle: "solid",
              overflow: "auto",
            }}
          >
            <div>
              <h2 style={{ margin: "50px 0px 0px 50px", color: "white" }}>
                {" "}
                {this.state.question_number}. {this.state.problem_name}
              </h2>
              <hr style={{ marginLeft: "50px", width: "600px" }} />
              <div
                dangerouslySetInnerHTML={{ __html: htmlString }}
                style={{ margin: "0px 50px", color: "white" }}
              />
            </div>
          </div>
          <div style={{ height: "100%", display: "flex" }}>
            <div>
              <div style={{ marginTop: "10px" }}>
                <TextField
                  id="question_number"
                  label="Question Number"
                  onChange={this.handleChange}
                  defaultValue={this.state.question_number}
                  placeholder="1"
                  style={{
                    margin: "10px 10px 10px 10px",
                    width: "30%",
                    color: "white",
                  }}
                />
                <ColorButton
                  type="submit"
                  // fullWidth
                  variant="contained"
                  loadingIndicator="searching"
                  loading={this.state.search_loading}
                  sx={{ paddingLeft: "30px", paddingRight: "30px", mt: "20px" }}
                  onClick={this.handleSearch}
                >
                  Search
                </ColorButton>
              </div>
              <TextField
                id="topic"
                label="Question Topic [Write the topic separated by -]"
                onChange={this.handleChange}
                value={this.state.topic}
                placeholder="two_sum_problem"
                style={{
                  margin: "30px 10px 10px 10px",
                  width: "30%",
                  color: "white",
                }}
              />
              <TextField
                id="problem_name"
                label="Problem Name"
                onChange={this.handleChange}
                value={this.state.problem_name}
                placeholder="Two Sum Problem"
                style={{
                  margin: "30px 10px 10px 10px",
                  width: "30%",
                  color: "white",
                }}
              />
              <TextField
                id="tags_input"
                label="Tags [Separated by ,]"
                placeholder="math,array"
                onChange={this.handleChange}
                value={this.state.tags_input}
                style={{
                  margin: "30px 10px 10px 10px",
                  width: "30%",
                  color: "white",
                }}
              />
              <TextField
                id="leetcode_link"
                label="Leetcode Link"
                onChange={this.handleChange}
                value={this.state.leetcode_link}
                placeholder="1"
                style={{ margin: "10px", width: "47%", color: "white" }}
              />
              <TextField
                select
                name="difficulty_level"
                id="difficulty_level"
                label="Difficulty Level"
                value={
                  this.state.difficulty_level === "easy"
                    ? "easy"
                    : this.state.difficulty_level === "medium"
                    ? "medium"
                    : "hard"
                }
                onChange={this.onSelectDifficulty}
                variant="outlined"
                style={{ margin: "10px", width: "45%", color: "white" }}
              >
                <MenuItem
                  name="difficulty_level"
                  id="difficulty_level"
                  value={"easy"}
                >
                  easy
                </MenuItem>
                <MenuItem
                  name="difficulty_level"
                  id="difficulty_level"
                  value={"medium"}
                >
                  medium
                </MenuItem>
                <MenuItem
                  name="difficulty_level"
                  id="difficulty_level"
                  value={"hard"}
                >
                  hard
                </MenuItem>
              </TextField>

              <TextField
                id="question_details"
                label="Question"
                onChange={this.handleChange}
                multiline
                rows={15}
                defaultValue={this.state.question_details}
                value={this.state.question_details}
                style={{ margin: "10px", width: "95%", color: "white" }}
              />
              {this.state.inputs?.map((input, index) => (
                <TextField
                  id="input"
                  key={index}
                  label={`Input ${index + 1}`}
                  value={input.value}
                  multiline
                  rows={5}
                  onChange={(e) => this.handleInputChange(e, index)}
                  style={{ margin: "10px", width: "40%", color: "white" }}
                />
              ))}
              <Fab
                color="primary"
                aria-label="add"
                onClick={this.handleAddInput}
                size="small"
                sx={{ mt: 2 }}
              >
                <AddIcon />
              </Fab>
              <TextField
                id="constraints"
                label="constraints"
                onChange={this.handleChange}
                multiline
                rows={6}
                value={this.state.constraints}
                style={{ margin: "10px", width: "95%", color: "white" }}
              />
              <ColorButton
                onClick={() => this.handleSubmit()}
                style={{
                  borderColor: "white",
                  padding: "10px",
                  // backgroundColor: "#1D99BE",
                  color: "white",
                  width: "95%",
                  margin: "10px",
                }}
                variant="contained"
              >
                Submit
              </ColorButton>
            </div>
          </div>
        </SplitPane>
      </div>
    );
  }
}

export default CreateQuestionForm;
