import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NameCard from "./components/NameCard.jsx";
import axios from "../../../api/axios";
import { isAuthenticated, signout } from "../../../api/authApi";
import PersonalDetails from "./components/PersonalDetails";
import MentorStats from "./components/MentorStats.jsx";
import Loading from "../../notificationPages/Loading";

const MentorProfile = () => {
  const navigate = useNavigate();
  const auth = isAuthenticated()?.data;
  const [mentorDetails, setmentorDetails] = useState();
  const [mentorRating, setmentorRating] = useState();

  let isMounted = true;
  const getUserFromBackend = () => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.status === 201) {
          if (res?.data) {
            var existing = JSON.parse(localStorage.getItem("jwt"));
            existing.data.idToken = res?.data?.access_token;
            localStorage.setItem("jwt", JSON.stringify(existing));
          }
          axios
            .post("/mentors/fetch_mentor_profile", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (isMounted) {
                if (res?.data?.success) {
                  setmentorDetails(res?.data?.response?.mentor);
                  setmentorRating(res?.data?.response?.rating);
                  
                  localStorage.setItem(
                    "mentor_profile",
                    JSON.stringify(res?.data?.response?.mentor)
                  );
                  localStorage.setItem(
                    "mentor_rating",
                    JSON.stringify(res?.data?.response?.rating)
                  );
                }
              }
            })
            .catch((e) => {
              console.log("error", e);
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  useEffect(() => {
    try {
      if (
        localStorage.getItem("mentor_profile") &&
        localStorage.getItem("mentor_profile") !== undefined 
      )
        setmentorDetails(JSON.parse(localStorage.getItem("mentor_profile")));
        setmentorRating(JSON.parse(localStorage.getItem("mentor_rating")));
      getUserFromBackend();
    } catch {
      getUserFromBackend();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  return mentorDetails ? (
    <>
      <Grid container spacing={2} columns={12} paddingTop={0}>
        <Grid item xs={12} md={7} lg={7}>
          <NameCard
            name={mentorDetails?.name}
            linkedin_profile={mentorDetails?.linkedin_profile}
          />
        </Grid>
        <Grid item xs={12} md={5} lg={5} sx={{ mt: -1 }}>
          <MentorStats
            mentees={mentorDetails?.mentees}
            referral_code={mentorDetails?.referral_code}
            mentorRating={mentorRating}
            calendly_link={mentorDetails?.calendly_link}
            linkedin_profile={mentorDetails?.linkedin_profile}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} columns={12} paddingTop={0} sx={{ mt: 2 }}>
        <Grid item xs={12} md={12} lg={12} sx={{ mt: -3 }}>
            <PersonalDetails
              name={mentorDetails?.name}
              email={mentorDetails?.email}
              current_company={mentorDetails?.current_company}
              experience={mentorDetails?.experience}
              photo = {mentorDetails?.photo}
            />
        </Grid>
       
      </Grid>
    </>
  ):
  <Loading />
};

export default MentorProfile;
