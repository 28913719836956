import {
  Box,
  Paper,
  Typography,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import React from "react";
import Avatar from "react-avatar";
import { Colors } from "../../../../assets/Colors";

const StudnetInfo = (props) => {
  const { currentUserObj } = props;
  return (
    <>
      <Paper
        sx={{ flexFlow: 1, bgcolor: Colors.primaryLite, mb: 2 }}
        variant="outlined"
      >
        <Box sx={{ display: "flex", justifyContent: "center", mt: "32px" }}>
          <Avatar round={true} name={currentUserObj.name} />
        </Box>
        <Typography></Typography>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 600,
                    textAlign: `center`,
                  }}
                >
                  {currentUserObj.name}
                </Typography>
              }
            />
          </ListItem>
          <Typography sx={{ textAlign: "center", mt: -2, mb: 1 }}>
            (YOU)
          </Typography>
          <ListItemText
            primary={
              <Typography
                sx={{
                  textAlign: `center`,
                  color: Colors.primaryMain,
                }}
              >
                Rank : {currentUserObj.rank}
              </Typography>
            }
          />
          <ListItemText
            primary={
              <Typography
                sx={{
                  textAlign: `center`,
                  color: Colors.primaryMain,
                }}
              >
                Batch : {currentUserObj.batch}
              </Typography>
            }
          />
        </List>
      </Paper>
    </>
  );
};

export default StudnetInfo;
