import React from "react";
import { Typography, Paper, Box } from "@mui/material";
import Container from "@mui/material/Container";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
const MentorAssignDate = () => {
  return (
    <>
      <Container
        sx={{ mt: "20px !important", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            width: "80% !important",
            justify: "",
            alignItems: "center",
            textAlign: "center",
            pl: 10,
          }}
        >
          <Typography
            sx={{ fontSize: "32px !important", color: "#084652 !important", fontWeight: 500 }}
          >
            Update!
          </Typography>
          <Typography sx={{ mt: "12px !important", mb: 4 }}>
            We have good news! Your mentor shall be assigned before the batch starts.
          </Typography>

          <div style={{}}>
            <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FassignDate.svg?alt=media&token=1c68a674-0378-44a1-b790-ef6c0233c4d7" alt="" sx={{}} />
          </div>
          {/* <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              bgcolor: "#F1F9FB !important",
            }}
            variant="outlined"
          >
             <CalendarMonthIcon sx={{ fontSize: "26px !important", mr: 1 }} />{" "} 
            <Typography sx={{ fontSize: "26px !important" }}>1 Sept 2022</Typography> 
          </Paper> */}
        </Box>
      </Container>
    </>
  );
};

export default MentorAssignDate;
