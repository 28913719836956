import axios from "./axios";
const LOGIN_URL = "/common/login";
const LOGOUT_URL = "/common/logout";

export const signin = async (user) => {
  return await axios
    .post(LOGIN_URL, user)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};

export const signout = async (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");
    next();

    return await axios
      .post(LOGOUT_URL, {
        uid: data,
      })
      .then((response) => console.log("response"))
      .catch((err) => console.log("err"));
  }
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (
    localStorage.getItem("jwt") != "undefined" ||
    localStorage.getItem("jwt") != undefined
  ) {
    try {
      return JSON.parse(localStorage.getItem("jwt"));
    } catch {
      localStorage.removeItem("jwt");
      return false;
    }
  }
  return false;
};

export const updateIdToken = async (rt) => {
  const response = await axios.post("/common/refresh_token", {
    refresh_token: rt,
  });

  if (response?.data) {
    var existing = JSON.parse(localStorage.getItem("jwt"));
    existing.data.idToken = response?.data?.access_token;
    localStorage.setItem("jwt", JSON.stringify(existing));
  }
};
