import React, { useEffect, useState } from "react";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Divider,
  Checkbox,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Loading from "../../../notificationPages/Loading";
import { styled } from "@mui/material/styles";
import InformationPanel from "./InformationPanel";
import LoadingButton from "@mui/lab/LoadingButton";
import BlockDates from "./BlockDates";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MentorSchedule = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [values, setValues] = React.useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ]);
  // For monday values
  const [mondayCheck, setmondayCheck] = useState(false);
  const [mondayTimerValues, setMondayTimerValues] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleMondayFirstTime = (event, index) => {
    let timerValue = [...mondayTimerValues];
    timerValue[index].start = event.target.value;
    timerValue[index].end = (parseInt(event.target.value) + 1).toString();
    setMondayTimerValues(timerValue);
  };
  const addMondayTimer = () => {
    let obj = {
      start: "19",
      end: "20",
    };
    setMondayTimerValues([...mondayTimerValues, obj]);
  };

  //   For tuesday values
  const [tuesdayCheck, setTuesdayCheck] = useState(false);
  const [tuesdayTimerValues, setTuesdayTimerValues] = useState([]);

  const handleTuesdayFirstTime = (event, index) => {
    let timerValue = [...tuesdayTimerValues];
    timerValue[index].start = event.target.value;
    timerValue[index].end = (parseInt(event.target.value) + 1).toString();
    setTuesdayTimerValues(timerValue);
  };
  const addTuesdayTimer = () => {
    let obj = {
      start: "19",
      end: "20",
    };
    setTuesdayTimerValues([...tuesdayTimerValues, obj]);
  };

  //   For Wednesday value
  const [wednesdayCheck, setWednesdayCheck] = useState(false);
  const [wednesdayTimerValues, setWednesdayTimerValues] = useState([]);

  const handleWednesdayFirstTime = (event, index) => {
    let timerValue = [...wednesdayTimerValues];
    timerValue[index].start = event.target.value;
    timerValue[index].end = (parseInt(event.target.value) + 1).toString();
    setWednesdayTimerValues(timerValue);
  };
  const addwednesdayTimer = () => {
    let obj = {
      start: "19",
      end: "20",
    };
    setWednesdayTimerValues([...wednesdayTimerValues, obj]);
  };

  //   For Thursday values
  const [thursdayCheck, setThursdayCheck] = useState(false);
  const [thursdayTimerValues, setThursdayTimerValues] = useState([]);

  const handleThursdayFirstTime = (event, index) => {
    let timerValue = [...thursdayTimerValues];
    timerValue[index].start = event.target.value;
    timerValue[index].end = (parseInt(event.target.value) + 1).toString();
    setThursdayTimerValues(timerValue);
  };
  const addThursdayTimer = () => {
    let obj = {
      start: "19",
      end: "20",
    };
    setThursdayTimerValues([...thursdayTimerValues, obj]);
  };
  //   For Friday values
  const [fridayCheck, setFridayCheck] = useState(false);
  const [fridayTimerValues, setFridayTimerValues] = useState([]);

  const handleFridayFirstTime = (event, index) => {
    let timerValue = [...fridayTimerValues];
    timerValue[index].start = event.target.value;
    timerValue[index].end = (parseInt(event.target.value) + 1).toString();
    setFridayTimerValues(timerValue);
  };
  const addFridayTimer = () => {
    let obj1 = {
      start: "19",
      end: "20",
    };
    setFridayTimerValues([...fridayTimerValues, obj1]);
  };

  //   For Saturday values
  const [saturdayCheck, setSaturdayCheck] = useState(false);
  const [saturdayTimerValues, setSaturdayTimerValues] = useState([]);

  const handleSaturdayFirstTime = (event, index) => {
    let timerValue = [...saturdayTimerValues];
    timerValue[index].start = event.target.value;
    timerValue[index].end = (parseInt(event.target.value) + 1).toString();
    setSaturdayTimerValues(timerValue);
  };
  const addSaurdayTimer = () => {
    let obj = {
      start: "19",
      end: "20",
    };
    setSaturdayTimerValues([...saturdayTimerValues, obj]);
  };

  //For Sunday Values
  const [sundayCheck, setSundayCheck] = useState(false);
  const [sundayTimerValues, setSundayTimerValues] = useState([]);

  const handleSundayFirstTime = (event, index) => {
    let timerValue = [...sundayTimerValues];
    timerValue[index].start = event.target.value;
    timerValue[index].end = (parseInt(event.target.value) + 1).toString();
    setSundayTimerValues(timerValue);
  };
  const addSundayTimer = () => {
    let obj = {
      start: "19",
      end: "20",
    };
    setSundayTimerValues([...sundayTimerValues, obj]);
  };

  //   Delete Time stap
  const deleteMondayTimeStamp = () => {
    let filteredTimers = mondayTimerValues.filter(
      (timer, index) => index !== mondayTimerValues.length - 1
    );
    setMondayTimerValues([...filteredTimers]);
  };
  const deleteTuesdayTimeStamp = () => {
    let TuesdayTimers = tuesdayTimerValues.filter(
      (timer, index) => index !== tuesdayTimerValues.length - 1
    );
    setTuesdayTimerValues([...TuesdayTimers]);
  };
  const deleteWednesdayTimeStamp = () => {
    let wednesdayTimers = wednesdayTimerValues.filter(
      (timer, index) => index !== wednesdayTimerValues.length - 1
    );
    setWednesdayTimerValues([...wednesdayTimers]);
  };
  const deletethursdayTimeStamp = () => {
    let thursdayTimers = thursdayTimerValues.filter(
      (timer, index) => index !== thursdayTimerValues.length - 1
    );
    setThursdayTimerValues([...thursdayTimers]);
  };

  const deleteFridayTimeStamp = () => {
    let fridayTimers = fridayTimerValues.filter(
      (timer, index) => index !== fridayTimerValues.length - 1
    );
    setFridayTimerValues([...fridayTimers]);
  };
  const deleteSaturdayTimeStamp = () => {
    let saturdayTimers = saturdayTimerValues.filter(
      (timer, index) => index !== saturdayTimerValues.length - 1
    );
    setSaturdayTimerValues([...saturdayTimers]);
  };
  const deleteSundayTimeStamp = () => {
    let sundayTimers = sundayTimerValues.filter(
      (timer, index) => index !== sundayTimerValues.length - 1
    );
    setSundayTimerValues([...sundayTimers]);
  };

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: " #1D99BE",
    "&:hover": {
      backgroundColor: "#33A3C4",
    },
    float: "right",
  }));

  const [availability, setavailability] = useState();
  const [successToast, setsuccessToast] = useState(false);
  const [errorToast, seterrorToast] = useState(false);
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/mentors/get_availability", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (isMounted) {
                let availability = res?.data.availability;
                setavailability(res?.data.availability);
                setMondayTimerValues(availability.monday);
                setmondayCheck(Boolean(availability.monday.length));
                setTuesdayTimerValues(availability.tuesday);
                setTuesdayCheck(Boolean(availability.tuesday.length));
                setWednesdayTimerValues(availability.wednesday);
                setWednesdayCheck(Boolean(availability.wednesday.length));
                setThursdayTimerValues(availability.thursday);
                setThursdayCheck(Boolean(availability.thursday.length));
                setFridayTimerValues(availability.friday);
                setFridayCheck(Boolean(availability.friday.length));
                setSaturdayTimerValues(availability.saturday);
                setSaturdayCheck(Boolean(availability.saturday.length));
                setSundayTimerValues(availability.sunday);
                setSundayCheck(Boolean(availability.sunday.length));
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  let obj = {
    id_token: isAuthenticated()?.data?.idToken,
    sunday: sundayCheck ? sundayTimerValues : {},
    monday: mondayCheck ? mondayTimerValues : {},
    tuesday: tuesdayCheck ? tuesdayTimerValues : {},
    wednesday: wednesdayCheck ? wednesdayTimerValues : {},
    thursday: thursdayCheck ? thursdayTimerValues : {},
    friday: fridayCheck ? fridayTimerValues : {},
    saturday: saturdayCheck ? saturdayTimerValues : {},
  };

  const handleToastClose = (e) => {
    setsuccessToast(false);
    seterrorToast(false);
  };

  const submitResponce = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios.post("/mentors/update_availability", obj).then((res) => {
            if (res?.data?.success) {
              setLoading(false);
              setsuccessToast(true);
            } else {
              setLoading(false);
              seterrorToast(true);
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  return availability ? (
    <>
      <Grid container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Container maxWidth="sm" sx={{}}>
            <Typography sx={{ fontSize: "32px" }}>Availability</Typography>
            <Divider
              sx={{ mt: 2, color: "#A0A3BD", borderBottomWidth: 3 }}
            ></Divider>
            <div>
              <Snackbar
                open={successToast}
                autoHideDuration={3000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert severity="success" sx={{ width: "100%" }}>
                Response Save 
                </Alert>
              </Snackbar>
              <Snackbar
                open={errorToast}
                autoHideDuration={3000}
                onClose={handleToastClose}
              >
                <Alert severity="error" sx={{ width: "100%" }}>
                  Some Error Occured!
                </Alert>
              </Snackbar>
            </div>
            <ColorButton
              onClick={submitResponce}
              variant="contained"
              sx={{ mt: " 24px", width : "20%"}}
              loadingIndicator="Saving…"
              loading={loading}
            >
              Save
            </ColorButton>
            <Typography sx={{ fontSize: "26px", mt: "24px" }}>Slots</Typography>
            <List sx={{}}>
              <ListItem
                sx={{
                  alignItems: "flex-start",
                  verticalAlign: "top",
                  ml: -2,
                }}
              >
                <Checkbox
                  onChange={() => {
                    setmondayCheck(!mondayCheck);
                    setMondayTimerValues([
                      {
                        start: "19",
                        end: "20",
                      },
                    ]);
                  }}
                  checked={mondayCheck}
                  {...label}
                  sx={{
                    color: "#1D99BE",
                    "&.Mui-checked": { color: "#1D99BE" },
                  }}
                />

                <ListItemText
                  primary="Monday"
                  sx={{ fontSize: "16px", mt: 1 }}
                />

                {mondayCheck ? (
                  <>
                    <Stack>
                      {mondayTimerValues.map((row, index) => {
                        return (
                          <>
                            <Box sx={{ display: "flex", mr: 4, mb: 3 }}>
                              <FormControl
                                size="small"
                                sx={{
                                  minWidth: 100,
                                  mr: 2,
                                }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.start}
                                  label="Choose Time"
                                  onChange={(event) =>
                                    handleMondayFirstTime(event, index)
                                  }
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                              <FormControl size="small" sx={{ minWidth: 100 }}>
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.end}
                                  label="Choose Time"
                                  disabled
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </>
                        );
                      })}
                    </Stack>
                    <Stack>
                      <div>
                        {mondayTimerValues.length < 0 ? (
                          <div></div>
                        ) : (
                          <div>
                            <AddCircleOutlineIcon
                              onClick={addMondayTimer}
                              sx={{
                                color: "#1D99BE",
                                float: "right",
                                alignItems: "flex-start",
                                verticalAlign: "top",
                                mt: 1,
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {mondayTimerValues.length >= 2 ? (
                        <div>
                          <RemoveCircleOutlineIcon
                            onClick={deleteMondayTimeStamp}
                            sx={{
                              color: "#1D99BE",
                              float: "right ",
                              display: "block",
                              mt: 2,
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Stack>
                  </>
                ) : (
                  <Typography sx={{ fontWeight: 100, mr: 10, mt: 1 }}>
                    Unavailable
                  </Typography>
                )}
              </ListItem>
              <Divider sx={{ mb: 1, mt: -1 }} />
              <ListItem
                sx={{
                  alignItems: "flex-start",
                  verticalAlign: "top",
                  ml: -2,
                }}
              >
                <Checkbox
                  onChange={() => {
                    setTuesdayCheck(!tuesdayCheck);
                    setTuesdayTimerValues([
                      {
                        start: "19",
                        end: "20",
                      },
                    ]);
                  }}
                  checked={tuesdayCheck}
                  {...label}
                  sx={{
                    color: "#1D99BE",
                    "&.Mui-checked": { color: "#1D99BE" },
                  }}
                />

                <ListItemText
                  primary="Tuesday"
                  sx={{ fontSize: "16px", mt: 1 }}
                />

                {tuesdayCheck ? (
                  <>
                    <Stack>
                      {tuesdayTimerValues.map((row, index) => {
                        return (
                          <>
                            <Box sx={{ display: "flex", mr: 4, mb: 2 }}>
                              <FormControl
                                size="small"
                                sx={{
                                  minWidth: 100,
                                  mr: 2,
                                  mt: 1,
                                }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.start}
                                  label="Choose Time"
                                  onChange={(event) =>
                                    handleTuesdayFirstTime(event, index)
                                  }
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                              <FormControl
                                size="small"
                                sx={{ minWidth: 100, mt: 1 }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.end}
                                  label="Choose Time"
                                  disabled
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </>
                        );
                      })}
                    </Stack>
                    <Stack>
                      <div>
                        {tuesdayTimerValues.length < 0 ? (
                          <div></div>
                        ) : (
                          <div>
                            <AddCircleOutlineIcon
                              onClick={addTuesdayTimer}
                              sx={{
                                color: "#1D99BE",
                                float: "right",
                                alignItems: "flex-start",
                                verticalAlign: "top",
                                mt: 1,
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {tuesdayTimerValues.length >= 2 ? (
                        <div>
                          <RemoveCircleOutlineIcon
                            onClick={deleteTuesdayTimeStamp}
                            sx={{
                              color: "#1D99BE",
                              float: "right ",
                              display: "block",
                              mt: 2,
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Stack>
                  </>
                ) : (
                  <Typography sx={{ fontWeight: 100, mr: 10, mt: 1 }}>
                    Unavailable
                  </Typography>
                )}
              </ListItem>
              <Divider sx={{ mb: 1 }} />
              <ListItem
                sx={{
                  alignItems: "flex-start",
                  verticalAlign: "top",
                  ml: -2,
                }}
              >
                <Checkbox
                  onChange={() => {
                    setWednesdayCheck(!wednesdayCheck);
                    setWednesdayTimerValues([
                      {
                        start: "19",
                        end: "20",
                      },
                    ]);
                  }}
                  checked={wednesdayCheck}
                  {...label}
                  sx={{
                    color: "#1D99BE",
                    "&.Mui-checked": { color: "#1D99BE" },
                  }}
                />

                <ListItemText
                  primary="Wednesday"
                  sx={{ fontSize: "16px", mt: 1 }}
                />

                {wednesdayCheck ? (
                  <>
                    <Stack>
                      {wednesdayTimerValues.map((row, index) => {
                        return (
                          <>
                            <Box sx={{ display: "flex", mr: 4, mb: 2 }}>
                              <FormControl
                                size="small"
                                sx={{
                                  minWidth: 100,
                                  mr: 2,
                                  mt: 1,
                                }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.start}
                                  label="Choose Time"
                                  onChange={(event) =>
                                    handleWednesdayFirstTime(event, index)
                                  }
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                              <FormControl
                                size="small"
                                sx={{ minWidth: 100, mt: 1 }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.end}
                                  label="Choose Time"
                                  disabled
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </>
                        );
                      })}
                    </Stack>
                    <Stack>
                      <div>
                        {wednesdayTimerValues.length < 0 ? (
                          <div></div>
                        ) : (
                          <div>
                            <AddCircleOutlineIcon
                              onClick={addwednesdayTimer}
                              sx={{
                                color: "#1D99BE",
                                float: "right",
                                alignItems: "flex-start",
                                verticalAlign: "top",
                                mt: 1,
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {wednesdayTimerValues.length >= 2 ? (
                        <div>
                          <RemoveCircleOutlineIcon
                            onClick={deleteWednesdayTimeStamp}
                            sx={{
                              color: "#1D99BE",
                              float: "right ",
                              display: "block",
                              mt: 2,
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Stack>
                  </>
                ) : (
                  <Typography sx={{ fontWeight: 100, mr: 10, mt: 1 }}>
                    Unavailable
                  </Typography>
                )}
              </ListItem>
              <Divider sx={{ mb: 1 }} />
              <ListItem
                sx={{
                  alignItems: "flex-start",
                  verticalAlign: "top",
                  ml: -2,
                }}
              >
                <Checkbox
                  onChange={() => {
                    setThursdayCheck(!thursdayCheck);
                    setThursdayTimerValues([
                      {
                        start: "19",
                        end: "20",
                      },
                    ]);
                  }}
                  checked={thursdayCheck}
                  {...label}
                  sx={{
                    color: "#1D99BE",
                    "&.Mui-checked": { color: "#1D99BE" },
                  }}
                />

                <ListItemText
                  primary="Thursday"
                  sx={{ fontSize: "16px", mt: 1 }}
                />

                {thursdayCheck ? (
                  <>
                    <Stack>
                      {thursdayTimerValues.map((row, index) => {
                        return (
                          <>
                            <Box sx={{ display: "flex", mr: 4, mb: 2 }}>
                              <FormControl
                                size="small"
                                sx={{
                                  minWidth: 100,
                                  mr: 2,
                                  mt: 1,
                                }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.start}
                                  label="Choose Time"
                                  onChange={(event) =>
                                    handleThursdayFirstTime(event, index)
                                  }
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                              <FormControl
                                size="small"
                                sx={{ minWidth: 100, mt: 1 }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.end}
                                  label="Choose Time"
                                  disabled
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </>
                        );
                      })}
                    </Stack>
                    <Stack>
                      <div>
                        {thursdayTimerValues.length < 0 ? (
                          <div></div>
                        ) : (
                          <div>
                            <AddCircleOutlineIcon
                              onClick={addThursdayTimer}
                              sx={{
                                color: "#1D99BE",
                                float: "right",
                                alignItems: "flex-start",
                                verticalAlign: "top",
                                mt: 1,
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {thursdayTimerValues.length >= 2 ? (
                        <div>
                          <RemoveCircleOutlineIcon
                            onClick={deletethursdayTimeStamp}
                            sx={{
                              color: "#1D99BE",
                              float: "right ",
                              display: "block",
                              mt: 2,
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Stack>
                  </>
                ) : (
                  <Typography sx={{ fontWeight: 100, mr: 10, mt: 1 }}>
                    Unavailable
                  </Typography>
                )}
              </ListItem>
              <Divider sx={{ mb: 1 }} />
              <ListItem
                sx={{
                  alignItems: "flex-start",
                  verticalAlign: "top",
                  ml: -2,
                }}
              >
                <Checkbox
                  onChange={() => {
                    setFridayCheck(!fridayCheck);
                    setFridayTimerValues([
                      {
                        start: "19",
                        end: "20",
                      },
                    ]);
                  }}
                  checked={fridayCheck}
                  {...label}
                  sx={{
                    color: "#1D99BE",
                    "&.Mui-checked": { color: "#1D99BE" },
                  }}
                />

                <ListItemText
                  primary="Friday"
                  sx={{ fontSize: "16px", mt: 1 }}
                />

                {fridayCheck ? (
                  <>
                    <Stack>
                      {fridayTimerValues.map((row, index) => {
                        return (
                          <>
                            <Box sx={{ display: "flex", mr: 4, mb: 2 }}>
                              <FormControl
                                size="small"
                                sx={{
                                  minWidth: 100,
                                  mr: 2,
                                  mt: 1,
                                }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.start}
                                  label="Choose Time"
                                  onChange={(event) =>
                                    handleFridayFirstTime(event, index)
                                  }
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                              <FormControl
                                size="small"
                                sx={{ minWidth: 100, mt: 1 }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.end}
                                  label="Choose Time"
                                  disabled
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </>
                        );
                      })}
                    </Stack>
                    <Stack>
                      <div>
                        {fridayTimerValues.length < 0 ? (
                          <div></div>
                        ) : (
                          <div>
                            <AddCircleOutlineIcon
                              onClick={addFridayTimer}
                              sx={{
                                color: "#1D99BE",
                                float: "right",
                                alignItems: "flex-start",
                                verticalAlign: "top",
                                mt: 1,
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {fridayTimerValues.length >= 2 ? (
                        <div>
                          <RemoveCircleOutlineIcon
                            onClick={deleteFridayTimeStamp}
                            sx={{
                              color: "#1D99BE",
                              float: "right ",
                              display: "block",
                              mt: 2,
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Stack>
                  </>
                ) : (
                  <Typography sx={{ fontWeight: 100, mr: 10, mt: 1 }}>
                    Unavailable
                  </Typography>
                )}
              </ListItem>
              <Divider sx={{ mb: 1 }} />
              <ListItem
                sx={{
                  alignItems: "flex-start",
                  verticalAlign: "top",
                  ml: -2,
                }}
              >
                <Checkbox
                  onChange={() => {
                    setSaturdayCheck(!saturdayCheck);
                    setSaturdayTimerValues([
                      {
                        start: "19",
                        end: "20",
                      },
                    ]);
                  }}
                  checked={saturdayCheck}
                  {...label}
                  sx={{
                    color: "#1D99BE",
                    "&.Mui-checked": { color: "#1D99BE" },
                  }}
                />

                <ListItemText
                  primary="Saturday"
                  sx={{ fontSize: "16px", mt: 1 }}
                />

                {saturdayCheck ? (
                  <>
                    <Stack>
                      {saturdayTimerValues.map((row, index) => {
                        return (
                          <>
                            <Box sx={{ display: "flex", mr: 4, mb: 2 }}>
                              <FormControl
                                size="small"
                                sx={{
                                  minWidth: 100,
                                  mr: 2,
                                  mt: 1,
                                }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.start}
                                  label="Choose Time"
                                  onChange={(event) =>
                                    handleSaturdayFirstTime(event, index)
                                  }
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                              <FormControl
                                size="small"
                                sx={{ minWidth: 100, mt: 1 }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.end}
                                  label="Choose Time"
                                  disabled
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </>
                        );
                      })}
                    </Stack>
                    <Stack>
                      <div>
                        {saturdayTimerValues.length < 0 ? (
                          <div></div>
                        ) : (
                          <div>
                            <AddCircleOutlineIcon
                              onClick={addSaurdayTimer}
                              sx={{
                                color: "#1D99BE",
                                float: "right",
                                alignItems: "flex-start",
                                verticalAlign: "top",
                                mt: 1,
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {saturdayTimerValues.length >= 2 ? (
                        <div>
                          <RemoveCircleOutlineIcon
                            onClick={deleteSaturdayTimeStamp}
                            sx={{
                              color: "#1D99BE",
                              float: "right ",
                              display: "block",
                              mt: 2,
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Stack>
                  </>
                ) : (
                  <Typography sx={{ fontWeight: 100, mr: 10, mt: 1 }}>
                    Unavailable
                  </Typography>
                )}
              </ListItem>
              <Divider sx={{ mb: 1 }} />
              <ListItem
                sx={{
                  alignItems: "flex-start",
                  verticalAlign: "top",
                  ml: -2,
                }}
              >
                <Checkbox
                  onChange={() => {
                    setSundayCheck(!sundayCheck);
                    setSundayTimerValues([
                      {
                        start: "19",
                        end: "20",
                      },
                    ]);
                  }}
                  checked={sundayCheck}
                  {...label}
                  sx={{
                    color: "#1D99BE",
                    "&.Mui-checked": { color: "#1D99BE" },
                  }}
                />

                <ListItemText
                  primary="Sunday"
                  sx={{ fontSize: "16px", mt: 1 }}
                />

                {sundayCheck ? (
                  <>
                    <Stack>
                      {sundayTimerValues.map((row, index) => {
                        return (
                          <>
                            <Box sx={{ display: "flex", mr: 4, mb: 2 }}>
                              <FormControl
                                size="small"
                                sx={{
                                  minWidth: 100,
                                  mr: 2,
                                  mt: 1,
                                }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.start}
                                  label="Choose Time"
                                  onChange={(event) =>
                                    handleSundayFirstTime(event, index)
                                  }
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                              <FormControl
                                size="small"
                                sx={{ minWidth: 100, mt: 1 }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Choose Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={row.end}
                                  label="Choose Time"
                                  disabled
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </>
                        );
                      })}
                    </Stack>
                    <Stack>
                      <div>
                        {sundayTimerValues.length < 0 ? (
                          <div></div>
                        ) : (
                          <div>
                            <AddCircleOutlineIcon
                              onClick={addSundayTimer}
                              sx={{
                                color: "#1D99BE",
                                float: "right",
                                alignItems: "flex-start",
                                verticalAlign: "top",
                                mt: 1,
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {sundayTimerValues.length >= 2 ? (
                        <div>
                          <RemoveCircleOutlineIcon
                            onClick={deleteSundayTimeStamp}
                            sx={{
                              color: "#1D99BE",
                              float: "right ",
                              display: "block",
                              mt: 2,
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Stack>
                  </>
                ) : (
                  <Typography sx={{ fontWeight: 100, mr: 10, mt: 1 }}>
                    Unavailable
                  </Typography>
                )}
              </ListItem>
              <Divider sx={{ mb: 1 }} />
            </List>
          </Container>
        </Grid>
        <Grid item xs={4}>
          <Divider
            sx={{ mt: 8, color: "#A0A3BD", borderBottomWidth: 3 }}
          ></Divider>
          <BlockDates />
        </Grid>
        <Grid item xs={4}>
          <InformationPanel />
        </Grid>
      </Grid>
    </>
  ) : (
    <Loading />
  );
};

export default MentorSchedule;
