import React, { Component } from "react";
import {
  getStudentDetails,
  updateStudentDetails,
  getMentorsList,
} from "../../../api/adminApi";
import { isAuthenticated } from "../../../api/authApi";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Moment from "react-moment";
import Grid from "@mui/material/Grid/Grid";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class ProfilePage extends Component {
  state = {
    student: {},
    advance_student: {},
    refund: {},
    placement: {
      placement_history: [],
    },
    comments: [],
    search_email: "",
    success_toast: false,
    error_toast: false,
    mentor_priority: [],
    mentorList: [],
    mentor_id: "",
    comment: "",
    leetcode_id : ""
  };

  componentDidMount() {
    let email = window.location.pathname.split("/")[4];
    getStudentDetails({
      admin_uid: isAuthenticated()?.data?.localId,
      email: email,
    })
      .then((res) => {
        let data = res.data.response;
        this.setState({
          student: data.student,
          advance_student: data.advance_student,
          refund: data.advance_student.refund,
          placement: data.advance_student.placement,
          search_email: email,
          comments:
            data.advance_student.comments === undefined
              ? []
              : data.advance_student.comments,
          mentor_priority: data.student.mentor_priority,
          mentor_id: data.student.mentor_id,
          leetcode_id : data.student.leetcode
        });
      })
      .catch((err) => console.log(err));

    getMentorsList({
      email: email,
    })
      .then((res) => {
        let data = res?.data?.response;
        this.setState({
          mentorList: data.all_mentors,
        });
      })
      .catch((err) => console.log(err));
  }

  onMentorChange = (e) => {
    let student_obj = this.state.student;
    student_obj[e.target.mentor_id] = e.target.value;
    this.setState({
      student: student_obj,
    });
  };

  onChangeSearchEmail = (e) => {
    this.setState({
      search_email: e.target.value,
    });
  };

  onChangeStudentObj = (e) => {
    let student_obj = this.state.student;
    student_obj[e.target.id] = e.target.value;
    this.setState({
      student: student_obj,
    });
  };
  onChangeAdvStudentObj = (e) => {
    let student_obj = this.state.advance_student;
    student_obj[e.target.id] = e.target.value;
    this.setState({
      advance_student: student_obj,
    });
  };

  onSelectChangeStudentObj = (e) => {
    let student_obj = this.state.student;
    student_obj[e.target.name] = e.target.value;
    this.setState({
      student: student_obj,
    });
  };

  onChangeTypeOfRefund = (e) => {
    let refund_obj = this.state.refund;
    refund_obj.type_of_refund = e.target.value;
    this.setState({
      refund: refund_obj,
    });
  };
  onChangeComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  addComment = (e) => {
    var comment = {
      date: Date.now(),
      comment: this.state.comment,
    };
    this.setState({
      comments: [...this.state.comments, comment],
      comment: "",
    });
  };

  onSelectChangeAdvStudentObj = (e) => {
    let student_obj = this.state.advance_student;
    student_obj[e.target.name] = e.target.value;
    this.setState({
      advance_student: student_obj,
    });
  };

  onSelectChangeStudentStatus = (e) => {
    let student_obj = this.state.advance_student;
    student_obj.student_status = e.target.value;
    this.setState({
      advance_student: student_obj,
    });
  };

  onSelectChangePaymentMethod = (e) => {
    let student_obj = this.state.student;
    student_obj.payment_method = e.target.value;
    this.setState({
      student: student_obj,
    });
  };

  onChangeRefund = (e) => {
    let refund_obj = this.state.refund;
    refund_obj[e.target.id] = e.target.value;
    this.setState({
      refund: refund_obj,
    });
  };

  onChangePlacement = (e) => {
    let placement_obj = this.state.placement;
    placement_obj[e.target.name] = e.target.value;
    this.setState({
      placement: placement_obj,
    });
  };
  addPlacementHistory = (e) => {
    let placement_obj = this.state.placement;
    let placement_history = placement_obj.placement_history;
    placement_history.push("");
    placement_obj.placement_history = placement_history;
    this.setState({
      placement: placement_obj,
    });
  };

  handlePlacementHistoryChange = (e) => {
    var list = this.state.placement.placement_history.map((option, index) => {
      if (index === e.target.id) {
        option = e.target.value;
      }
      return option;
    });
    let placement_obj = this.state.placement;
    let placement_history = placement_obj.placement_history;
    placement_history = list;
    placement_obj.placement_history = placement_history;
    this.setState({
      placement: placement_obj,
    });
  };

  handleToastClose = (e) => {
    this.setState({
      success_toast: false,
      error_toast: false,
    });
  };

  handleSubmit = (e) => {
    let advance_student = this.state.advance_student;
    advance_student.refund = this.state.refund;
    advance_student.placement = this.state.placement;
    advance_student.comments = this.state.comments;
    let data = {
      advance_student: advance_student,
      student: this.state.student,
    };
    updateStudentDetails(data).then((res) => {
      if (res.data.success) {
        this.setState({ success_toast: true });
      } else {
        this.setState({ error_toast: true });
      }
    });
  };

  render() {
    return (
      <div>
        <Snackbar
          open={this.state.success_toast}
          autoHideDuration={3000}
          onClose={this.handleToastClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Student Information Saved Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.error_toast}
          autoHideDuration={3000}
          onClose={this.handleToastClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
        <TextField
          name="search_email"
          label="Search Student by Email"
          value={this.state.search_email}
          onChange={this.onChangeSearchEmail}
          style={{ marginTop: "10px", width: "50%" }}
          variant="outlined"
        />
        <a href={`/admin/profile/edit/${this.state.search_email}`}>
          <button
            style={{
              borderColor: "white",
              padding: "16px",
              backgroundColor: "#1D99BE",
              color: "white",
              margin: "10px",
              width: "20%",
            }}
          >
            Search
          </button>
        </a>

        <h3
          style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}
        >
          Basic user Profile
        </h3>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              disabled
              id="email"
              label="Email"
              type="email"
              onChange={this.onChangeStudentObj}
              value={this.state.student.email}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              name="student_status"
              select
              label="Student Status"
              value={String(this.state.advance_student.student_status)}
              onChange={this.onSelectChangeStudentStatus}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="student_status" value={"process in progress"}>
                {" "}
                process in progress{" "}
              </MenuItem>
              <MenuItem name="student_status" value={"payment action required"}>
                {" "}
                payment action required{" "}
              </MenuItem>
              <MenuItem name="student_status" value={"payment in progress"}>
                {" "}
                payment in progress{" "}
              </MenuItem>
              <MenuItem name="student_status" value={"payment completed"}>
                {" "}
                payment completed{" "}
              </MenuItem>
              <MenuItem name="student_status" value={"batch change"}>
                {" "}
                batch change{" "}
              </MenuItem>
              <MenuItem name="student_status" value={"opted out"}>
                {" "}
                opted out{" "}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="name"
              label="Name"
              type="text"
              onChange={this.onChangeStudentObj}
              value={this.state.student.name}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="phone"
              label="Phone"
              type="text"
              onChange={this.onChangeStudentObj}
              value={this.state.student.phone}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="id"
              label="Student Id"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.id}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="batch"
              label="Batch"
              type="text"
              disabled
              onChange={this.onChangeStudentObj}
              value={this.state.student.batch}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="secondary_phone_number"
              label="Secondary Phone Number"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.secondary_phone_number}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="experience"
              label="Experience"
              type="text"
              onChange={this.onChangeStudentObj}
              value={this.state.student.experience}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="current_company"
              label="Current Company"
              type="text"
              onChange={this.onChangeStudentObj}
              value={this.state.student.current_company}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="dream_company"
              label="Dream Company"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.dream_company}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="college"
              label="College"
              type="text"
              onChange={this.onChangeStudentObj}
              value={this.state.student.college}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              select
              name="course_type"
              id="course_type"
              label="Course Type"
              value={
                this.state.student.course_type === "student"
                  ? "student"
                  : "working"
              }
              onChange={this.onSelectChangeStudentObj}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="course_type" id="course_type" value={"student"}>
                student
              </MenuItem>
              <MenuItem name="course_type" id="course_type" value={"working"}>
                {" "}
                working
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="current_state"
              label="Current State"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.current_state}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="permanent_state"
              label="Permanent State"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.permanent_state}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="sales_rep"
              label="Sales Representative"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.sales_rep}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="reffered_by"
              label="Reffered By"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.reffered_by}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="lead_source"
              label="Lead Source"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.lead_source}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="evangalist"
              label="Evangalist"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.evangalist}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              name="leetcode_verified"
              select
              label="Leetcode Verified"
              value={
                this.state.student.leetcode_verified === true ? true : false
              }
              onChange={this.onSelectChangeStudentObj}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="placement_status" value={true}>
                {" "}
                True{" "}
              </MenuItem>
              <MenuItem name="placement_status" value={false}>
                {" "}
                False{" "}
              </MenuItem>
            </TextField>
          </Grid>
            <Grid item xs={12} md={6} lg={3}>
            <TextField
              disabled
              name="Leecode id"
              type="text"
              label="Leecode ID"
              value={this.state.leetcode_id}
              onChange={this.onSelectChangeStudentObj}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
            </TextField>

            </Grid>
        </Grid>

        <h3
          style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}
        >
          Mentor Details
        </h3>

        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="Mentor Id"
              label="Mentor Id"
              type="text"
              value={
                this.state.mentor_id === ""
                  ? "NOT ALLOTED"
                  : this.state.mentor_id
              }
              disabled={true}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {this.state.mentor_priority?.map((option, index) => {
              return (
                <TextField
                  label="Mentor Prefrence"
                  disabled={true}
                  halfWidth
                  value={this.state.mentor_priority[index]}
                  id={index}
                  type="text"
                  style={{ marginTop: "10px", width: "100%" }}
                />
              );
            })}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              select
              name="mentor_id"
              id="mentor_id"
              label="select Mentor"
              value={this.state.student.mentor_id}
              onChange={this.onSelectChangeStudentObj}
              fullWidth
              style={{ marginTop: "8px" }}
            >
              {this.state.mentorList?.map((row) => (
                <MenuItem value={row} id="mentor_id">
                  {row}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={3}></Grid>
        </Grid>

        <h3
          style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}
        >
          Payment Information
        </h3>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="fees"
              label="Fees"
              type="text"
              onChange={this.onChangeStudentObj}
              value={this.state.student.fees}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              name="payment_method"
              select
              label="Payment method"
              value={
                this.state.student.payment_method === "cash"
                  ? "cash"
                  : this.state.student.payment_method === "loan"
                  ? "loan"
                  : this.state.student.payment_method === "razorpay"
                  ? "razorpay"
                  : "bank transfer"
              }
              onChange={this.onSelectChangePaymentMethod}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="payment_method" value={"cash"}>
                {" "}
                cash{" "}
              </MenuItem>
              <MenuItem name="payment_method" value={"loan"}>
                {" "}
                loan{" "}
              </MenuItem>
              <MenuItem name="payment_method" value={"razorpay"}>
                {" "}
                razorpay{" "}
              </MenuItem>
              <MenuItem name="payment_method" value={"bank transfer"}>
                {" "}
                bank transfer{" "}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="payment_status"
              label="Payment Status"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.payment_status}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="discount"
              label="Discount"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.discount}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="loan_amount"
              label="Loan Amount"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.loan_amount}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="loan_tenure"
              label="Loan Tenure"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.loan_tenure}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="disbursed_amount"
              label="Disbursed Amount"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.disbursed_amount}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="loan_id"
              label="Loan Id"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.loan_id}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="advance"
              label="Advance"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.advance}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="advance_payment_id"
              label="Advance Payment Id"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.advance_payment_id}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="advance_payment_method"
              label="Advance Payment Method"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.advance_payment_method}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <h3
          style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}
        >
          Onboarding
        </h3>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id="actual_batch"
              label="Actual Batch"
              type="text"
              onChange={this.onChangeAdvStudentObj}
              value={this.state.advance_student.actual_batch}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="screen_test_status"
              select
              label="Screen Test Status"
              value={
                this.state.advance_student.screen_test_status === "sent"
                  ? "sent"
                  : this.state.advance_student.screen_test_status === "not_sent"
                  ? "not_sent"
                  : "done"
              }
              onChange={this.onSelectChangeAdvStudentObj}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="screen_test_status" value={"sent"}>
                sent
              </MenuItem>
              <MenuItem name="screen_test_status" value={"not_sent"}>
                not sent
              </MenuItem>
              <MenuItem name="screen_test_status" value={"done"}>
                done
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="prerequisite_status"
              select
              label="Prerequisite Status"
              value={
                this.state.advance_student.prerequisite_status === "sent"
                  ? "sent"
                  : this.state.advance_student.prerequisite_status ===
                    "not_sent"
                  ? "not_sent"
                  : "done"
              }
              onChange={this.onSelectChangeAdvStudentObj}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="prerequisite_status" value={"sent"}>
                sent
              </MenuItem>
              <MenuItem name="prerequisite_status" value={"not_sent"}>
                not sent
              </MenuItem>
              <MenuItem name="prerequisite_status" value={"done"}>
                done
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={4}></Grid>
        </Grid>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="google_group_status"
              select
              label="Google group Status"
              value={
                this.state.advance_student.google_group_status === "sent"
                  ? "sent"
                  : "not_sent"
              }
              onChange={this.onSelectChangeAdvStudentObj}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="google_group_status" value={"sent"}>
                sent
              </MenuItem>
              <MenuItem name="google_group_status" value={"not_sent"}>
                not sent
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="schedule_status"
              select
              label="Schedule Status"
              value={
                this.state.advance_student.schedule_status === "sent"
                  ? "sent"
                  : "not_sent"
              }
              onChange={this.onSelectChangeAdvStudentObj}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="schedule_status" value={"sent"}>
                sent
              </MenuItem>
              <MenuItem name="schedule_status" value={"not_sent"}>
                not sent
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="slack_status"
              select
              label="Slack Status"
              value={
                this.state.advance_student.slack_status === "sent"
                  ? "sent"
                  : "not_sent"
              }
              onChange={this.onSelectChangeAdvStudentObj}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="slack_status" value={"sent"}>
                sent
              </MenuItem>
              <MenuItem name="slack_status" value={"not_sent"}>
                not sent
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <h3
          style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}
        >
          Placement
        </h3>

        <TextField
          name="placement_status"
          select
          label="Placement Status"
          value={this.state.placement.placement_status === true ? true : false}
          onChange={this.onChangePlacement}
          style={{ marginTop: "10px", width: "100%" }}
          variant="outlined"
        >
          <MenuItem name="placement_status" value={true}>
            {" "}
            True{" "}
          </MenuItem>
          <MenuItem name="placement_status" value={false}>
            {" "}
            False{" "}
          </MenuItem>
        </TextField>
        <br />
        <p> Placement History </p>
        {this.state.placement.placement_history.map((option, index) => {
          return (
            <TextField
              label="company"
              halfWidth
              onChange={this.handlePlacementHistoryChange}
              value={this.state.placement.placement_history[index]}
              id={index}
              type="text"
              style={{ marginTop: "10px", width: "100%" }}
            />
          );
        })}
        <button
          size="large"
          onClick={this.addPlacementHistory}
          style={{
            margin: "auto",
            borderColor: "white",
            padding: "13px",
            backgroundColor: "#1D99BE",
            color: "white",
            width: "100%",
            marginTop: "10px",
            fontSize: "16px",
          }}
        >
          Add
        </button>

        <h3
          style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}
        >
          Refund
        </h3>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="type_of_refund"
              select
              label="Type Of Refund"
              value={
                this.state.refund.type_of_refund === "full refund"
                  ? "full refund"
                  : "processing fee refund"
              }
              onChange={this.onChangeTypeOfRefund}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
            >
              <MenuItem name="type_of_refund" value={"full refund"}>
                {" "}
                full refund{" "}
              </MenuItem>
              <MenuItem name="type_of_refund" value={"processing fee refund"}>
                {" "}
                processing fee refund{" "}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id="refund_status"
              label="Refund Status"
              type="text"
              value={this.state.refund.refund_status}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              onChange={this.onChangeRefund}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id="refund_id"
              label="Refund Id"
              type="text"
              value={this.state.refund.refund_id}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              onChange={this.onChangeRefund}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id="refund_amount"
              label="Refund Amount"
              type="text"
              value={this.state.refund.refund_amount}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              onChange={this.onChangeRefund}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id="refund_date"
              label="Refund Date"
              type="text"
              value={this.state.refund.refund_date}
              style={{ marginTop: "10px", width: "100%" }}
              variant="outlined"
              onChange={this.onChangeRefund}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <h3
          style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}
        >
          Comments
        </h3>
        {this.state.comments.map((comment) => {
          return (
            <div>
              <Moment
                format="MMM D, YYYY @ HH.mm"
                date={comment.date}
                style={{ fontFamily: "Lato" }}
              />
              <p> {comment.comment} </p>
            </div>
          );
        })}

        <TextField
          id="comment"
          label="Comment"
          type="text"
          value={this.state.comment}
          style={{ marginTop: "10px", width: "100%" }}
          variant="outlined"
          onChange={this.onChangeComment}
          InputProps={{
            startAdornment: <InputAdornment position="start"> </InputAdornment>,
          }}
        />

        <button
          size="large"
          onClick={this.addComment}
          style={{
            margin: "auto",
            borderColor: "white",
            padding: "13px",
            backgroundColor: "#1D99BE",
            color: "white",
            width: "20%",
            marginTop: "10px",
            fontSize: "16px",
          }}
        >
          Add comment
        </button>

        <button
          onClick={() => this.handleSubmit()}
          style={{
            borderColor: "white",
            padding: "10px",
            backgroundColor: "#1D99BE",
            color: "white",
            margin: "20px 45%",
          }}
        >
          Submit
        </button>
      </div>
    );
  }
}

export default ProfilePage;