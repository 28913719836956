import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import StudnetInfo from "./components/StudentInfo";
import Loading from "../../notificationPages/Loading";
import axios from "../../../api/axios";
import LeaderList from "./components/LeadersList";
import { Grid } from "@mui/material";
import DescribeLeaderboard from "./components/DescribeLeaderboard";
import { Typography, Box } from "@mui/material";
import ProgressDash from "./components/ProgressDash";
import ReactGa from "react-ga";
import Alert from '@mui/material/Alert';

const LeaderBoard = () => {
  const [leaderboard, setleaderboard] = useState();
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  let isMounted = true;
  const getLeaderoard = () => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          {
            auth.batch_status === true
              ? axios
                  .post("/student/get_leaderboard", {
                    id_token: res?.data?.id_token,
                  })
                  .then((res) => {
                    if (isMounted) {
                      setleaderboard(res?.data?.response?.leaderboard);
                      localStorage.setItem(
                        "leaderboardData",
                        JSON.stringify(res?.data?.response?.leaderboard)
                      );
                    }
                  })
              : axios
                  .get("/student/get_leaderboard_top_100", {})
                  .then((res) => {
                    if (isMounted) {
                      setleaderboard(res?.data?.response?.leaderboard);
                      localStorage.setItem(
                        "leaderboardData",
                        JSON.stringify(res?.data?.response?.leaderboard)
                      );
                    }
                  });
          }
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
    try {
      if (
        localStorage.getItem("leaderboardData") &&
        localStorage.getItem("leaderboardData") !== undefined
      )
        setleaderboard(JSON.parse(localStorage.getItem("leaderboardData")));
      getLeaderoard();
    } catch {
      getLeaderoard();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const temp_name = Object.values(leaderboard || {})[0];
  const topperName = Object.values(temp_name || {})[5];

  let currentUserObj = {};
  {
    leaderboard?.map((row, i) => {
      if (row.email === auth.email) {
        currentUserObj["rank"] = i + 1;
        currentUserObj["name"] = row.name;
        currentUserObj["batch"] = row.batch;
        currentUserObj["last_week_count"] = row.last_week_count;
        currentUserObj["leetcode_count"] = row.leetcode_count;
      }
    });
  }
  return leaderboard ? (
    <>
      <div align="left">
        <Alert
          sx={{
            width: "max-content",
            mb: 2,
            textAlign: "center",
            backgroundColor: "#e5f6fd !important",
          }}
          severity="info"
        >
          Leaderboard is updated in every 24 hours.
        </Alert>
      </div>
      <Typography
        variant="h5"
        component="h5"
        sx={{
          textAlign: "center",
          mb: 4,
          fontWeight: 600,
          // fontFamily: "Nunito",
        }}
      >
        Leaderboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={9} lg={9}>
          <Box sx={{ mb: 2 }}>
            <DescribeLeaderboard name={topperName} />
          </Box>
          <LeaderList leaderboard={leaderboard} />
        </Grid>
        <Grid item xs={3} sm={3} lg={3}>
          <StudnetInfo currentUserObj={currentUserObj} />
          <ProgressDash currentUserObj={currentUserObj} />
        </Grid>
      </Grid>
    </>
  ) : (
    <Loading />
  );
};

export default LeaderBoard;
