import React, { Component } from "react";
import { getMentorDetails, updateMentorDetails } from "../../../api/adminApi";
import { isAuthenticated } from "../../../api/authApi";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Moment from 'react-moment'
import Avatar from '@mui/material/Avatar';
import { red } from "@mui/material/colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


class ProfilePage extends Component {
    state = {
        comments: [],
        mentor: {},
        mentees: [],
        availability: {
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
        },
        search_email: "",
    };

    componentDidMount() {
        let email = window.location.pathname.split("/")[4]
        getMentorDetails({
            admin_uid: isAuthenticated()?.data?.localId,
            email: email
        }).then(res => {
            let data = res.data.response
            this.setState({
                mentor: data.mentor,
                mentees: data.mentor.mentees,
                availability: data.mentor.availability,
                comments: data.mentor.comments === undefined ? [] : data.mentor.comments,
            })
        })
            .catch(err => console.log(err))
    }


    onChangeComment = e => {
        this.setState({
            comment: e.target.value
        });
    }

    addComment = e => {
        var comment = ({
            date: Date.now(),
            comment: this.state.comment
        })
        this.setState({
            comments: [...this.state.comments, comment],
            comment: ""
        })
    }

    onChangeSearchEmail = e => {
        this.setState({
            search_email: e.target.value
        });
    }

    onChangeMentorObj = e => {
        let mentor_obj = this.state.mentor
        mentor_obj[e.target.id] = e.target.value
        this.setState({
            mentor: mentor_obj
        });
    }

    handleToastClose = e => {
        this.setState({
            success_toast: false,
            error_toast: false
        })
    }

    handleSubmit = e => {
        let mentor = this.state.mentor;
        mentor.comments = this.state.comments;
        let data = {
            mentor: mentor
        }
        updateMentorDetails(data).then(res => {
            if (res.data.success) {
                this.setState({ success_toast: true });
            } else {
                this.setState({ error_toast: true });
            }
        })
    }

    render() {
        return (
            <div>
                <Snackbar
                    open={this.state.success_toast}
                    autoHideDuration={3000}
                    onClose={this.handleToastClose}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Mentor Information Saved Successfully!
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={this.state.error_toast}
                    autoHideDuration={3000}
                    onClose={this.handleToastClose}
                >
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Some Error Occured!
                    </Alert>
                </Snackbar>

                <TextField
                    name="search_email"
                    label="Search Student by Email"
                    value={this.state.search_email}
                    onChange={this.onChangeSearchEmail}
                    style={{ marginTop: "10px", width: "50%" }}
                    variant="outlined"
                />
                <a href={`/admin/mentor-profile/edit/${this.state.search_email}`}>
                    <button
                        style={{
                            borderColor: "white",
                            padding: "16px",
                            backgroundColor: "#1D99BE",
                            color: "white",
                            margin: "10px",
                            width: "20%"
                        }}>
                        Search
                    </button>
                </a>

                <h3 style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}>
                    Basic user Profile
                </h3>
                <TextField
                    disabled
                    id="email"
                    label="Email"
                    type="email"
                    value={this.state.mentor.email}
                    style={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                    }}
                />
                <TextField
                    id="bandwidth"
                    label="bandwidth"
                    type="number"
                    value={this.state.mentor.bandwidth}
                    onChange={this.onChangeMentorObj}
                    style={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                    }}
                />
                <TextField
                    id="name"
                    label="Name"
                    type="text"
                    value={this.state.mentor.name}
                    onChange={this.onChangeMentorObj}
                    style={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                    }}
                />
                <TextField
                    id="phone"
                    label="Phone"
                    type="text"
                    value={this.state.mentor.phone}
                    onChange={this.onChangeMentorObj}
                    style={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                    }}
                />
                <TextField
                    id="current_company"
                    label="Current Company"
                    type="text"
                    value={this.state.mentor.current_company}
                    onChange={this.onChangeMentorObj}
                    style={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                    }}
                />
                <TextField
                    id="experience"
                    label="Experience"
                    type="text"
                    value={this.state.mentor.experience}
                    onChange={this.onChangeMentorObj}
                    style={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                    }}
                />
                <TextField
                    id="linkedin_profile"
                    label="Linkedin"
                    type="text"
                    value={this.state.mentor.linkedin_profile}
                    onChange={this.onChangeMentorObj}
                    style={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                    }}
                />
                <TextField
                    id="photo"
                    label="Photo Url"
                    type="text"
                    value={this.state.mentor.photo}
                    onChange={this.onChangeMentorObj}
                    style={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                    }}
                />
                <h3 style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}>
                    Mentees
                </h3>
                <List>
                    {this.state.mentees?.map((row) => (
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar> {row.charAt(0).toUpperCase()} </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={row} />
                        </ListItem>
                    ))}
                </List>
                <h3 style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}>
                    Availability
                </h3>
                <TableContainer component={Paper} style={{
                    borderStyle: "inset", borderColor: '#ADD8E6',
                }}>
                    <Table aria-label="simple table">
                        <TableHead>

                            <TableRow>
                                <TableCell scope="row">
                                    Day
                                </TableCell>
                                <TableCell scope="row">
                                    Start
                                </TableCell>
                                <TableCell scope="row">
                                    End
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(this.state.availability?.sunday) && 
                            this.state.availability?.sunday?.map((row) => (
                                <TableRow>
                                <TableCell scope="row">
                                    Sunday
                                </TableCell>
                                <TableCell scope="row">
                                    {row.start}:00
                                </TableCell>
                                <TableCell scope="row">
                                    {row.end}:00
                                </TableCell>
                            </TableRow>
                            ))}
                            {Array.isArray(this.state.availability?.monday) && 
                            this.state.availability?.monday?.map((row) => (
                                <TableRow>
                                <TableCell scope="row">
                                    Monday
                                </TableCell>
                                <TableCell scope="row">
                                    {row.start}:00
                                </TableCell>
                                <TableCell scope="row">
                                    {row.end}:00
                                </TableCell>
                            </TableRow>
                            ))}
                            {Array.isArray(this.state.availability?.tuesday) && 
                            this.state.availability?.tuesday?.map((row) => (
                                <TableRow>
                                <TableCell scope="row">
                                    Tuesday
                                </TableCell>
                                <TableCell scope="row">
                                    {row.start}:00
                                </TableCell>
                                <TableCell scope="row">
                                    {row.end}:00
                                </TableCell>
                            </TableRow>
                            ))}
                            {Array.isArray(this.state.availability?.wednesday) && 
                            this.state.availability?.wednesday?.map((row) => (
                                <TableRow>
                                <TableCell scope="row">
                                    Wednesday
                                </TableCell>
                                <TableCell scope="row">
                                    {row.start}:00
                                </TableCell>
                                <TableCell scope="row">
                                    {row.end}:00
                                </TableCell>
                            </TableRow>
                            ))}
                            {Array.isArray(this.state.availability?.tuesday) && 
                            this.state.availability?.tuesday?.map((row) => (
                                <TableRow>
                                <TableCell scope="row">
                                    Tuesday
                                </TableCell>
                                <TableCell scope="row">
                                    {row.start}:00
                                </TableCell>
                                <TableCell scope="row">
                                    {row.end}:00
                                </TableCell>
                            </TableRow>
                            ))}
                            {Array.isArray(this.state.availability?.friday) && 
                            this.state.availability?.friday?.map((row) => (
                                <TableRow>
                                <TableCell scope="row">
                                    Friday
                                </TableCell>
                                <TableCell scope="row">
                                    {row.start}:00
                                </TableCell>
                                <TableCell scope="row">
                                    {row.end}:00
                                </TableCell>
                            </TableRow>
                            ))}
                            {Array.isArray(this.state.availability?.saturday) && 
                            this.state.availability?.saturday?.map((row) => (
                                <TableRow>
                                <TableCell scope="row">
                                    Saturday
                                </TableCell>
                                <TableCell scope="row">
                                    {row.start}:00
                                </TableCell>
                                <TableCell scope="row">
                                    {row.end}:00
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <h3 style={{ backgroundColor: "#1D99BE", color: "white", padding: "7px" }}>
                    Comments
                </h3>
                {this.state.comments?.map((comment) => {
                    return (
                        <div>
                            <Moment format="MMM D, YYYY @ HH.mm" date={comment.date} style={{ fontFamily: "Lato" }} />
                            <p> {comment.comment} </p>
                        </div>
                    )
                })}

                <TextField
                    id="comment"
                    label="Comment"
                    type="text"
                    value={this.state.comment}
                    style={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                    onChange={this.onChangeComment}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                    }}
                />

                <button
                    size="large"
                    onClick={this.addComment}
                    style={{
                        margin: "auto",
                        borderColor: "white",
                        padding: "13px",
                        backgroundColor: "#1D99BE",
                        color: "white",
                        width: "20%",
                        marginTop: "10px",
                        fontSize: "16px"
                    }}>
                    Add comment
                </button>

                <button
                    onClick={() => this.handleSubmit()}
                    style={{
                        borderColor: "white",
                        padding: "10px",
                        backgroundColor: "#1D99BE",
                        color: "white",
                        margin: "20px 45%"
                    }}>
                    Submit
                </button>

            </div>
        );
    }
}

export default ProfilePage