
import React from "react";

import { Container, Divider, Paper, Typography } from "@mui/material";
import MentorSchedule from "./components/MentorSchedule";
const Availability = () => {
  return(
  <>
    <MentorSchedule/>
  </>
)
}; 

export default Availability;
