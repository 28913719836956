import React, { Component } from "react";
import SplitPane from "split-pane-react";
import MenuItem from "@mui/material/MenuItem";
import "split-pane-react/esm/themes/default.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button, InputLabel, Toolbar } from "@mui/material";
import { addSolutions } from "../../../api/adminApi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import axios from "../../../api/axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Options = [
  "Heading",
  "Subheading",
  "Intuition",
  "Code for C++",
  "Code for Java",
  "Code for Python",
  "Time Complexity",
];
class CreateQuestionForm extends Component {
  state = {
    constraints: "",
    inputOutput: "",
    panelsizes: ["50%", "50%"],
    topic: "",
    difficulty_level: "easy",
    tags_input: "",
    tags: [],
    isDisplay: false,
    success_toast: false,
    submitLoading : false,
    error_toast: false,
    question_number: 1,
    problem_name: "",
    leetcode_link: "",
    search_loading: false,
    showForm: [],
    formData: [],
    inputs: [{ value: "" }],
    question_details:
      "<p> Hello </p> \n <br /> \n <b> <i style='color: green; background-color: white;'>  Write Anything  </i> </b> ",
  };

  componentDidMount() {}
  onPanelSizeChange = (e) => {
    this.setState({ panelsizes: e });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleAddClick = () => {
    this.setState({
      showForm: [...this.state.showForm, true],
      formData: [...this.state.formData, { heading: "", description: "" }],
    });
  };
  removeEmptyObjects = () => {
    const updatedFormData = this.state.formData.filter(
      (formDatum) =>
        formDatum.heading.trim() !== "" || formDatum.description.trim() !== ""
    );
    this.setState({ formData: updatedFormData });
    return this.state.formData;
  };

  handleSelectChange = (index) => (event) => {
    const updatedFormData = [...this.state.formData];
    updatedFormData[index].heading = event.target.value;
    this.setState({ formData: updatedFormData });
  };

  handleTextChange = (index) => (event) => {
    const updatedFormData = [...this.state.formData];
    updatedFormData[index].description = event.target.value;
    this.setState({ formData: updatedFormData });
  };


  handleSearch = (e) => {
    this.setState({ search_loading: true, });
    axios
      .post("/admin/get_question_by_number", {
        question_number: this.state.question_number,
      })
      .then((res) => {
        let data = res.data?.response;
        console.log(data,"Yahi se aa raha hai");
          this.setState({
            isDisplay: true,
            search_loading: false,
            problem_name: data[0].problem_name,
            question_details: data[0].question_details,
            inputs: data[0].input ? data[0]?.input : this.state?.inputs,
            constraints: data[0].constraints,
            question_number: data[0].question_number,
            topic: data[0].topic,
            formData: data[0].solution ?  data[0].solution : [],
            showForm : data[0].solution ? data[0].solution?.map(() => true) : []
          })
      })
      .catch((err) => {
        this.setState({ search_loading: false, error_toast: true });
      });
    }
  handleSubmit = (e) => {
    this.setState({submitLoading : true})
    let data = {
      topic: this.state.topic,
      solution: this.removeEmptyObjects(),
    };
    addSolutions(data).then((res) => {
      if (res.data.success) {
        this.setState({ success_toast: true, submitLoading : false });
      } else {
        this.setState({ error_toast: true,submitLoading : false  });
      }
    });
  };

  render() {
    const ColorButton = styled(LoadingButton)(({ theme }) => ({
      backgroundColor: "#1d99be",
      "&:hover": {
        backgroundColor: "#1d8cbe",
      },
    }));
    return (
      <div style={{ height: "150vh" }}>
        <Snackbar
          open={this.state.success_toast}
          autoHideDuration={3000}
          onClose={this.handleToastClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Question Created Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.error_toast}
          autoHideDuration={3000}
          onClose={this.handleToastClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
        <Box style={{ backgroundColor: "#333333" }}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <img
              src="https://uploads-ssl.webflow.com/60e1490057a19b506bd4ebb3/60fd54c519be8bccfd2922de_bosscoder_header_logo_2.svg"
              alt="logo"
            />
          </Toolbar>
        </Box>
        <SplitPane
          split="vertical"
          sizes={this.state.panelsizes}
          onChange={this.onPanelSizeChange}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              background: "#333333",
              borderStyle: "solid",
              overflow: "auto",
            }}
          >
            <div>
              <h2 style={{ margin: "50px 0px 0px 50px", color: "white" }}>
                {" "}
                {this.state.question_number}. {this.state.problem_name}
              </h2>
              <hr style={{ marginLeft: "50px", width: "600px" }} />
              {this.state.formData?.map((form, index) => (
                <div style={{}}>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{ __html: form.heading }}
                    style={{
                      margin: "0px 50px",
                      color: "black",
                      backgroundColor: "white",
                      paddingLeft: "20px",
                      paddingTop: "20px",
                      paddingBottom : "10px",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: form.description,
                    }}
                    style={{
                      margin: "0px 50px",
                      color: "black",
                      backgroundColor: "white",
                      paddingLeft: "20px",
                      paddingBottom: "20px",
                      paddingRight : "20px",
                      borderEndStartRadius: "8px",
                      borderEndEndRadius: "8px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div style={{ height: "100%", display: "flex", overflow: "auto" }}>
            <div>
              <div style={{ marginTop: "10px" }}>
                <TextField
                  id="question_number"
                  label="Question Number"
                  onChange={this.handleChange}
                  defaultValue={this.state.question_number}
                  placeholder="1"
                  style={{
                    margin: "10px 10px 10px 10px",
                    width: "30%",
                    color: "white",
                  }}
                />
                <ColorButton
                  type="submit"
                  // fullWidth
                  variant="contained"
                  loadingIndicator="searching"
                  loading={this.state.search_loading}
                  sx={{ paddingLeft: "30px", paddingRight: "30px", mt: "20px" }}
                  onClick={this.handleSearch}
                >
                  Search
                </ColorButton>
              </div>

              {this.state.isDisplay == true ? (
                <div>
                  <TextField
                    id="topic"
                    label="Question Topic [Write the topic separated by -]"
                    onChange={this.handleChange}
                    value={this.state.topic}
                    placeholder="two_sum_problem"
                    style={{
                      margin: "30px 10px 10px 10px",
                      width: "30%",
                      color: "white",
                    }}
                  />
                  <TextField
                    id="problem_name"
                    label="Problem Name"
                    onChange={this.handleChange}
                    value={this.state.problem_name}
                    placeholder="Two Sum Problem"
                    style={{
                      margin: "30px 10px 10px 10px",
                      width: "30%",
                      color: "white",
                    }}
                  />
                  <Button
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={this.handleAddClick}
                    style={{ marginTop: "40px" }}
                    variant="outlined"
                  >
                    Add the Fileds <AddCircleOutlineIcon sx={{ ml: 2 }} />
                  </Button>
                </div>
              ) : (
                <div></div>
              )}
              {this.state.formData?.length > 0 && (
                <ColorButton
                  onClick={() => this.handleSubmit()}
                  style={{
                    borderColor: "white",
                    float: "right",
                    color: "white",
                    width: "20%",
                    marginBottom: "15px",
                  }}
                  variant="contained"
                >
                  Submit
                </ColorButton>
              )}
              <div></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {this.state.showForm?.map((form, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      select
                      label="Select"
                      variant="outlined"
                      value={this.state?.formData[index]?.heading}
                      onChange={this.handleSelectChange(index)}
                      style={{ margin: "8px 10px 10px", width: "100%" }}
                    >
                      {Options?.map((value, index) => (
                        <MenuItem value={value}>{value}</MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label={this.state.formData[index]?.heading}
                      multiline
                      rows={4}
                      variant="outlined"
                      onChange={this.handleTextChange(index)}
                      value={this.state.formData[index]?.description}
                      style={{
                        margin: "8px 10px 10px 10px",
                        width: "100%",
                        color: "white",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </SplitPane>
      </div>
    );
  }
}

export default CreateQuestionForm;
