import React, { Component } from "react";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import Question from "./question";
import Editor from "./editor";
import Submissions from "./submissions";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Loading from "../notificationPages/Loading";
import axios from "../../api/axios";
import "./editor.css";
import { isAuthenticated } from "../../api/authApi";
import Solutions from "./Solutions";

class CodeEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelsizes: ["40%", "60%"],
      value: "1",
      tags: [],
      topic: "",
      difficulty_level: "easy",
      likes: 0,
      dislikes: 0,
      liked: false,
      disliked: false,
      question_number: 2,
      problem_name: "",
      question_details: "",
      result: [],
      loading: false,
      is_question_liked: false,
      inputs: [{ value: "" }],
      constraints: "",
      submisson: [],
      isOpen: false,
      token: "",
      count: 0,
      drawerValue: "1",
      statusMessage: "Running...",
      isAccepted: false,
      runCodeSubmission: [],
      errorMessage: [],
      runCodeLoading: false,
      code: {
        python: 'print("Hello, world!")',
        java: 'import java.util.Scanner; \n public class HelloWorld { \n public static void main(String[] args) { \n Scanner reader = new Scanner(System.in); \n System.out.print("Enter a number: "); \n int number = reader.nextInt(); \n System.out.println("You entered: " + number); \n} \n }',
        cpp: "#include <iostream> \n\n int main() { \n return 0; \n} \n",
      },
      language: "cpp",
      languageMenuOpen: false,
      anchorEl: null,
      intervalId: null,
      selectedTestCase: "",
      submitLoading: false,
      solutions : [],
    };
    this.updateResult = this.updateResult.bind(this);
    this.changeLikeButtonToTrue = this.changeLikeButtonToTrue.bind(this);
    this.changeLikeButtonToFalse = this.changeLikeButtonToFalse.bind(this);
    this.changeDislikeButtonToTrue = this.changeDislikeButtonToTrue.bind(this);
    this.changeDislikeButtonToFalse =
      this.changeDislikeButtonToFalse.bind(this);
  }

  componentDidMount() {
    clearInterval(this.state.intervalId);
    const topic = window.location.pathname.split("/")[3];
    const auth = isAuthenticated()?.data;
    axios
      .post("/student/import_template_code", {
        folder_name: topic,
      })
      .then((res) => {
        this.setState({
          code: res.data.template,
        });
      });
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          let data = {
            topic: topic,
            id_token: res?.data?.id_token,
          };
          axios.post("/student/get_question", data).then((res) => {
            let question = res.data.question;
            this.setState({
              tags: question.tags,
              topic: question.topic,
              difficulty_level: question.difficulty_level,
              likes: question.likes,
              dislikes: question.dislikes,
              question_details: question.question_details,
              liked: question.liked,
              disliked: question.disliked,
              leetcode_link: question.leetcode_link || "",
              problem_name: question.problem_name || question.topic,
              question_number: question.question_number || 2,
              inputs: question.input ? question?.input : this.state?.inputs,
              constraints: question.constraints
                ? question.constraints
                : this.state?.constraints,
              solutions : question.solution
            });
          });
          axios
            .post("student/get_student_submission_by_question", data)
            .then((res) => {
              this.setState({
                result: res.data.submissions,
              });
              this.props.updateResult(res.data.submissions);
            });
        }
      });
  }
  changeCode = (newcode) => {
    let codes = this.state.code;
    codes[this.state.language] = newcode;
    this.setState({ code: codes });
  };
  handleClose = (e, language) => {
    let current_lang = this.state.language;
    if (language == "java" || language == "python" || language == "cpp") {
      this.setState({
        languageMenuOpen: false,
        anchorEl: null,
        language: language,
      });
    } else {
      this.setState({
        languageMenuOpen: false,
        anchorEl: null,
        language: current_lang,
      });
    }
  };
  handleClick = (e) => {
    this.setState({ languageMenuOpen: true, anchorEl: e.currentTarget });
  };

  handleChipClick = (testCase) => {
    if (testCase.result == 0) this.setState({ selectedTestCase: testCase });
    else this.setState({ selectedTestCase: "" });
  };

  handleAddTestCase = () => {
    this.setState((state) => ({
      ...state,
      testCases: [
        ...this.state.testCases,
        {
          id: this.state.testCases?.length + 1,
          name: "Test Case " + (this.state.testCases?.length + 1),
        },
      ],
    }));
  };

  handleRemoveTestCase = (testCaseToRemove) => {
    this.setState({
      testCases: this.state.testCases.filter(
        (testCase) => testCase.id !== testCaseToRemove.id
      ),
    });
    this.setState({ selectedTestCase: null });
  };

  handleCodeSubmit = (e) => {
    this.setState({ submitLoading: true });
    const auth = isAuthenticated()?.data;
    const topic = window.location.pathname.split("/")[3];
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          {
            let data = {
              id_token: res?.data?.id_token,
              language: this.state.language,
              code_folder: topic,
              code: this.state.code[this.state.language],
            };
            axios
              .post("student/create_submission_v2", data)
              .then((res) => {
                this.updateResult();
                setTimeout(() => {
                  this.setState({ submitLoading : false });
                }, 10000);
              })
              .catch(() => {
                this.setState({ submitLoading : false });
              });
          }
        }
      });
  };

  handleRunCode = (e) => {
    const topic = window.location.pathname.split("/")[3];
    let data = {
      language: this.state.language,
      code_folder: topic,
      code: this.state.code[this.state.language],
    };
    this.setState({ runCodeLoading: true, statusMessage: "Running...", selectedTestCase : "", value : '1' });
    const auth = isAuthenticated()?.data;

    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }

    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          {
            let data = {
              language: this.state.language,
              code_folder: topic,
              code: this.state.code[this.state.language],
            };
            axios
              .post("student/run_code", data)
              .then((res) => {
                this.setState({ token: res.data?.token });
              })
              .catch(() => {});
          }
        }

        setTimeout(() => {
          const intervalId = setInterval(() => {
            axios
              .post("/student/get_submission", {
                token: this.state.token,
              })
              .then((res) => {
                this.setState({
                  runCodeSubmission: res.data.stdout,
                  statusMessage: res.data.description,
                  count: this.state.count + 1,
                  errorMessage: res.data.stderr,
                });

                if (
                  this.state.statusMessage === "Accepted" ||
                  this.state.count === 7
                ) {
                  this.setState({ isAccepted: true, runCodeLoading: false });
                  clearInterval(this.state.intervalId);
                }
              })
              .catch(() => {});

            // store the new interval ID in the state
            this.setState({ intervalId });
          }, 2000);

          // reset the count variable
          this.setState({ count: 0 });
        }, 500);
        this.setState({ count: 0 });
      });
  };
  changeLikeButtonToTrue = (e) => {
    const auth = isAuthenticated()?.data;
    const topic = window.location.pathname.split("/")[3];
    if (this.state.disliked) {
      this.setState({
        dislikes: this.state.dislikes - 1,
      });
    }
    this.setState({
      liked: true,
      disliked: false,
      likes: this.state.likes + 1,
    });
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          {
            let data = {
              topic: topic,
              id_token: res?.data?.id_token,
              like: true,
            };
            axios.post("/student/like_question", data).then((res) => {});
          }
        }
      });
  };

  changeLikeButtonToFalse = (e) => {
    this.setState({
      liked: false,
      likes: this.state.likes - 1,
    });
    const auth = isAuthenticated()?.data;
    const topic = window.location.pathname.split("/")[3];
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          {
            let data = {
              topic: topic,
              id_token: res?.data?.id_token,
              like: false,
            };
            axios.post("/student/like_question", data).then((res) => {});
          }
        }
      });
  };

  changeDislikeButtonToTrue = (e) => {
    if (this.state.liked) {
      this.setState({
        likes: this.state.likes - 1,
      });
    }
    const auth = isAuthenticated()?.data;
    const topic = window.location.pathname.split("/")[3];
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          {
            let data = {
              topic: topic,
              id_token: res?.data?.id_token,
              dislike: true,
            };
            axios.post("/student/dislike_question", data).then((res) => {});
          }
        }
      });
    this.setState({
      liked: false,
      disliked: true,
      dislikes: this.state.dislikes + 1,
    });
  };

  changeDislikeButtonToFalse = (e) => {
    this.setState({
      disliked: false,
      dislikes: this.state.dislikes - 1,
    });
    const auth = isAuthenticated()?.data;
    const topic = window.location.pathname.split("/")[3];
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          {
            let data = {
              topic: topic,
              id_token: res?.data?.id_token,
              dislike: false,
            };
            axios.post("/student/dislike_question", data).then((res) => {});
          }
        }
      });
  };

  onPanelSizeChange = (e) => {
    this.setState({ panelsizes: e });
  };

  handleChange = (e, newValue) => {
    this.setState({ value: newValue });
  };
  handleOpenDrawer = (e) => {
    this.setState({ isOpen: true });
  };
  handleChangeDrawerTab = (e, newValue) => {
    this.setState({ drawerValue: newValue });
  };

  handleCloseDrawer = () => {
    this.setState({ isOpen: false, runCodeLoading: false });
  };

  updateResult = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.updateResult_();
    }, 7000);
  };

  updateResult_ = () => {
    const auth = isAuthenticated()?.data;
    const topic = window.location.pathname.split("/")[3];

    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          {
            let data = {
              topic: topic,
              id_token: res?.data?.id_token,
            };
            this.setState({ value: "1" });
            axios
              .post("student/get_student_submission_by_question", data)
              .then((res) => {
                this.setState({
                  loading: false,
                  result: res.data.submissions,
                  value: "2",
                });
                this.props.updateResult(res.data.submissions);
              });
          }
        }
      });
  };

  render() {
    const { tags } = this.state;
    const { difficulty_level } = this.state;
    const { dislikes } = this.state;
    const { likes } = this.state;
    const { topic } = this.state;
    const { question_number } = this.state;
    const { problem_name } = this.state;
    const { liked } = this.state;
    const { disliked } = this.state;
    const { loading } = this.state;
    return this.state.tags?.length > 0 ? (
      <div style={{ height: "100vh" }}>
        <SplitPane
          split="vertical"
          sizes={this.state.panelsizes}
          onChange={this.onPanelSizeChange}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
            }}
          >
            <Box sx={{ width: "100%", typography: "body1", overflowY: "auto" }}>
              <TabContext value={this.state.value}>
                <Box sx={{ borderBottom: 1, borderColor: "#EEF2FF" }}>
                  <TabList
                    onChange={this.handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      "& button.Mui-selected": {
                        backgroundColor: "#EEF2FF",
                        color: "#312E81",
                      },
                      ".MuiTabs-indicator": {
                        backgroundColor: "#EEF2FF",
                      },
                    }}
                  >
                    <Tab
                      label="Description"
                      value="1"
                      style={{
                        color: "#312E81",
                        borderRadius: "8px 8px 0px 0",
                        textTransform: "none",
                        fontWeight: 700,
                      }}
                      indicatorColor="white"
                    />
                    <Tab
                      label="Submissions"
                      value="2"
                      style={{
                        color: "#312E81",
                        borderRadius: "8px 8px 0px 0",
                        textTransform: "none",
                        fontWeight: 700,
                      }}
                    />
                 {  this.state.solutions?.length > 0 && 
                  <Tab
                      label="Solutions"
                      value="3"
                      style={{
                        color: "#312E81",
                        borderRadius: "8px 8px 0px 0",
                        textTransform: "none",
                        fontWeight: 700,
                      }}
                    />}
                  </TabList>
                </Box>
                <TabPanel
                  value="1"
                  sx={{
                    backgroundColor: "#EEF2FF",
                    borderRadius: "8px 8px 8px 0",
                    minHeight: "100%",
                  }}
                >
                  {" "}
                  <Question
                    {...this.state}
                    changeLikeButtonToTrue={this.changeLikeButtonToTrue}
                    changeLikeButtonToFalse={this.changeLikeButtonToFalse}
                    changeDislikeButtonToTrue={this.changeDislikeButtonToTrue}
                    changeDislikeButtonToFalse={this.changeDislikeButtonToFalse}
                    width={this.state.panelsizes[0]}
                    submisson={this.state.submisson}
                    handleCloseDrawer={this.handleCloseDrawer}
                    handleChangeDrawerTab={this.handleChangeDrawerTab}
                    value={this.state.value}
                    isOpen={this.state.isOpen}
                    runCodeSubmission={this.state.runCodeSubmission}
                    errorMessage={this.state.errorMessage}
                    status={this.state.statusMessage}
                    runCodeLoading={this.state.runCodeLoading}
                    handleOpenDrawer={this.handleOpenDrawer}
                    handleAddTestCase={this.handleAddTestCase}
                    handleChipClick={this.handleChipClick}
                    handleRemoveTestCase={this.handleRemoveTestCase}
                  />{" "}
                </TabPanel>
                <TabPanel
                  value="2"
                  sx={{ backgroundColor: "#EEF2FF", minHeight: "100%" }}
                >
                  <Submissions
                    {...this.state}
                    changeLikeButtonToTrue={this.changeLikeButtonToTrue}
                    changeLikeButtonToFalse={this.changeLikeButtonToFalse}
                    changeDislikeButtonToTrue={this.changeDislikeButtonToTrue}
                    changeDislikeButtonToFalse={this.changeDislikeButtonToFalse}

                    handleCloseDrawer={this.handleCloseDrawer}
                    handleChangeDrawerTab={this.handleChangeDrawerTab}
                    value={this.state.value}
                    isOpen={this.state.isOpen}
                    runCodeSubmission={this.state.runCodeSubmission}
                    errorMessage={this.state.errorMessage}
                    status={this.state.statusMessage}
                    runCodeLoading={this.state.runCodeLoading}
                    handleOpenDrawer={this.handleOpenDrawer}
                    handleAddTestCase={this.handleAddTestCase}
                    handleChipClick={this.handleChipClick}
                    handleRemoveTestCase={this.handleRemoveTestCase}
                  />
                </TabPanel>
                <TabPanel
                  value="3"
                  sx={{ backgroundColor: "#EEF2FF", minHeight: "100%" }}
                >
                  <Solutions
                    solutions = {this.state.solutions}
                    tags = {this.state.tags}
                    changeLikeButtonToTrue={this.changeLikeButtonToTrue}
                    changeLikeButtonToFalse={this.changeLikeButtonToFalse}
                    changeDislikeButtonToTrue={this.changeDislikeButtonToTrue}
                    changeDislikeButtonToFalse={this.changeDislikeButtonToFalse}
                    question_number = {this.state.question_number}
                    problem_name = {this.state.problem_name}
                    liked = {this.state.liked}
                    likes = {this.state.likes}
                    disliked = {this.state.disliked}
                    dislike = {this.state.dislikes}
                    question_details = {this.state.question_details}
                    difficulty_level = {this.state.difficulty_level}
                    handleCloseDrawer={this.handleCloseDrawer}
                    handleChangeDrawerTab={this.handleChangeDrawerTab}
                    value={this.state.value}
                    isOpen={this.state.isOpen}
                    runCodeSubmission={this.state.runCodeSubmission}
                    errorMessage={this.state.errorMessage}
                    status={this.state.statusMessage}
                    runCodeLoading={this.state.runCodeLoading}
                    handleOpenDrawer={this.handleOpenDrawer}
                    handleAddTestCase={this.handleAddTestCase}
                    handleChipClick={this.handleChipClick}
                    handleRemoveTestCase={this.handleRemoveTestCase}
                    selectedTestCase={this.selectedTestCase}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
          <div
            style={{
              height: "180%",
              display: "flex",
              background: "#1E1E1E",
              borderStyle: "solid",
              border: "2px",
            }}
          >
            <Editor
              updateResult={this.updateResult}
              handleRunCode={this.handleRunCode}
              handleOpenDrawer={this.handleOpenDrawer}
              code={this.state.code}
              language={this.state.language}
              runCodeLoading={this.state.runCodeLoading}
              languageMenuOpen={this.state.languageMenuOpen}
              anchorEl={this.state.anchorEl}
              handleClose={this.handleClose}
              handleClick={this.handleClick}
              handleCodeSubmit={this.handleCodeSubmit}
              changeCode={this.changeCode}
              submitLoading = {this.state.submitLoading}
            />
          </div>
        </SplitPane>
      </div>
    ) : (
      <Loading />
    );
  }
}

export default CodeEditor;
