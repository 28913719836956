import React from "react";
import { Paper, Typography, Grid, styled, List, ListItem } from "@mui/material";
import {Colors} from "../../../../assets/Colors"
const Img = styled("img")({
  marginTop: 40,
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const ProcessPage3 = () => {
  return (
    <>
      <Grid container spacing={4} sx={{mt : "10px"}}>
        <Grid item xs={12} md={6}>
          <Item sx={{ backgroundColor: Colors.primaryLite, pt: 2, height: "400px" }}>
            <Typography sx={{ fontSize : "26px !important" }}>Step - 2</Typography>
            <div style={{ paddingLeft: "60px"}}>
              <Img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FProcess3.svg?alt=media&token=d15c5365-e3ba-439c-8b59-ef2d53c37505" alt="" sx={{ mb: 1 }} />
            </div>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ backgroundColor: Colors.primaryLite, pt: 2, height: "400px !important" }}>
            <Typography sx={{ fontSize : "26px !important" }}>
              Our banking partners
            </Typography>
            <List sx={{  }}>
              <ListItem>
                <ul>
                  <li>
                    <span style={{fontWeight : 700}}>Zoho</span> :- Direct payment will be collected through zoho invoices. 
                  </li>

                </ul>
              </ListItem>
              <ListItem sx={{mt : "-16px !important", ml : "80px !important"}}>
              <div style={{marginTop : "-20px" }}>
                     <Img src= "https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FzohoFavi.svg?alt=media&token=d0db9e0d-4982-4673-88e4-85d570147f6c" alt="" sx={{ mb: 1 }} />
              </div>
              </ListItem>
              <ListItem>
              <ul>
                  <li>
                    <span style={{fontWeight : 700}}>NBFC Partner</span> :- Student-friendly private study loans for learners pursuing education from partner institutions with customized loan products through a fully digital loan process.
                  </li>

                </ul>
              </ListItem>
              <ListItem sx={{mt : "-56px !important", ml : "56px !important"}}>
                <div style={{}}>
                      <Img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FPropelldFavi.svg?alt=media&token=b879d375-c59c-4037-8b2d-c9dfd65e2bc7" alt="" sx={{ mb: 1, mr : 1 }} />
                </div>
                <div style={{}}>
                      <Img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FLiquiloans%20Logo.png?alt=media&token=5530827e-37d6-4cb6-8535-5053319d5281" alt="" sx={{ mb: 1 , mr : 1, width : "120px"}} />
                </div>
                <div style={{}}>
                      <Img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2Ffibe%20logo.png?alt=media&token=85404c17-d0f7-4cb3-973b-6e261600c700" alt="" sx={{ mb: 1 ,width : "40px"}} />
                </div>
              </ListItem>
              
            </List>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default ProcessPage3;
