import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Container from "@mui/material/Container";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../assets/Colors";
import { Link, makeStyles, useTheme } from "@material-ui/core";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const NameCard = (props) => {
  const theme = useTheme();
  const {name , linkedin_profile} = props;
  let navigate = useNavigate();

  const linkedIn_id = "https://www.linkedin.com/in/" + linkedin_profile
  const useStyles = makeStyles((theme) => ({
    root: {
        float : "right",
        paddingTop : "16px !important",
        '@media (min-width:600px)': {
          marginTop : "24px !important",
          paddingRight : 4,
          height : 170,
        },
        // [theme.breakpoints.down('md')]: {
        //     height : 170,
        //     paddingRight : 4,
       
        //     marginTop : "24px !important"
        //   },
        
    },
   
  }));

  const classes = useStyles(theme);
  return (
    <>
      <div>
        <Paper
          sx={{
            height: "210px",
            bgcolor: Colors.secoundaryLite,
            border: 1,
            borderColor: Colors.secoundaryBackgorund,
          }}
          variant="outlined"
        >
          <Container sx={{ padding: 0 }}>
            {/* <Box sx={{ float: "right", pt: 2, pb: 2, pr: "47px" , backgroundColor : "red"}}> */}
              <img className={classes.root}
                src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FProfile_page%2FProfilePage.svg?alt=media&token=fe0287e0-1d3a-45b6-8a20-237abd06b0b7"
                alt=""
              />
            {/* </Box> */}

            <Typography 
              sx={{ paddingTop: 4, paddingLeft: 4, color: "#084652" }}
            >
              Welcome Back,
            </Typography>
            <Typography
              sx={{
                paddingTop: 2,
                paddingLeft: 4,
                color: "#084652",

              }}
              variant="h4"
            >
              {name}
            </Typography>
            {/* <Link href={linkedIn_id} target="_blank" sx={{textDecoration : "none"}}>
               <LinkedInIcon sx={{ fontSize: "26px !important" , mt : 1, ml : 4, color : "white"}} />{" "}
            </Link> */}
            {/* <Box sx={{width : "250px"}}>
            <Typography sx={{paddingTop : "36px", paddingLeft : 4, color : "#084652"}}>
            Get back to the course
            </Typography>
            <ArrowRightAltIcon  sx={{fontSize : "35px",float : "right", mt : -3.5 , mr : 2, cursor : "pointer"   }}/>
          </Box>  */}
          </Container>
        </Paper>
      </div>
    </>
  );
};

export default NameCard;
