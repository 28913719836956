import React from "react";
import Paper from "@mui/material/Paper";
import { Typography, Card } from "@material-ui/core";
import Box from "@mui/material/Box";
import Moment from "react-moment";
import { Link } from "@mui/material";
import { Colors } from "../../../../assets/Colors";
const ClassTimetable = (props) => {
  const {batchStarts, orientation_link} = props;

  
  return (
    <>
      <Typography align="center" variant="h5">
        The batch starts at  <Moment format="MMMM Do YYYY">{batchStarts}</Moment>
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          mt: 2,
        }}
      >
        <Paper
          align="center"
          sx={{
            maxWidth: "25%",
            maxHeight: "10%",
            p: 2,
            backgroundColor: Colors.primaryLite,
          }}
          variant="outlined"
        >
          Orientation Session on <Moment format="MMMM Do YYYY">{batchStarts}</Moment>
          <Link
            target="_blank"
            href={orientation_link}
            underline="hover"
          >
            <Typography> Meeting Link</Typography>
          </Link>
        </Paper>
      </Box>
    </>
  );
};

export default ClassTimetable;
