import React, { Component } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { getNextStudents, updateStudent, getAllMentors, sendEmail, updateDisableState, getTopStudents,paymentStatus } from "../../../api/adminApi";
import { isAuthenticated } from "../../../api/authApi";
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from '@mui/material/DialogContentText';
import Switch from '@mui/material/Switch';
import { previousSaturday } from "date-fns/esm";

function searchingfor(searchemail) {
    return function (x) {
        return x.email.toLowerCase().includes(searchemail.toLowerCase()) || !searchemail;
    }
}

function searchingbyname(searchName) {
    return function (x) {
        return x.name.toLowerCase().includes(searchName.toLowerCase()) || !searchName;
    }
}


function filterbybatch(searchbatch) {
    return function (x) {
        return x.batch.toLowerCase().includes(searchbatch.toLowerCase()) || !searchbatch;
    }
}

function filterbypaymentstatus(searchpaymentstatus) {
    return function (x) {
        return x.payment_status.toLowerCase().includes(searchpaymentstatus.toLowerCase()) || !searchpaymentstatus;
    }
}

class Dashboard extends Component {
    state = {
        data: [],
        searchemail: "",
        searchpaymentstatus: "",
        searchbatch: "",
        open: false,
        open2: false,
        email: "",
        name: "",
        phone: "",
        fees: 0,
        linkedin_profile: "",
        leetcode: "",
        college: "",
        current_company: "",
        experience: "",
        batch: "",
        course_type: "",
        payment_status: "",
        is_onboarding_done: false,
        payment_method: "",
        remarks: "",
        mentor_id: "",
        fields_changes: [],
        mentors: [],
        checked: true,
        paidChecked : false,
        last_student: "",
        next_data_loaded: true,
        searchName: "",
    };

    componentDidMount() {
        getTopStudents({
            admin_uid: isAuthenticated()?.data?.localId
        }).then(res => {
            this.setState({ data: res.data.response.students, last_student: res.data.response.students[res.data.response.students.length - 1].email })
        })
            .catch(err => console.log(err))
    }

    onSearchInputChange = (event) => {
        if (event.target.value) {
            this.setState({ searchemail: event.target.value })
        } else {
            this.setState({ searchemail: '' })
        }
    }

    onSearchNameInputChange = (event) => {
        if (event.target.value) {
            this.setState({ searchName: event.target.value })
        } else {
            this.setState({ searchName: '' })
        }
    }

    onSearchBatchInputChange = (event) => {
        if (event.target.value) {
            this.setState({ searchbatch: event.target.value })
        } else {
            this.setState({ searchbatch: '' })
        }
    }

    onSearchPaymentStatusInputChange = (event) => {
        if (event.target.value) {
            this.setState({ searchpaymentstatus: event.target.value })
        } else {
            this.setState({ searchpaymentstatus: '' })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true })
    };

    openSecond = () => {
        this.setState({ open2: true })
    };

    handleClose = () => {
        this.setState({ open: false })
    };
    handleClose2 = () => {
        this.setState({ open2: false })
    };
    change_field(field_name) {
        let fields = this.state.fields_changes
        let found = false
        for (var i = 0; i < fields.length; i++) {
            if (fields[i] == field_name)
                found = true
        }
        if (!found) {
            fields.push(field_name)
            this.setState({
                fields_changes: fields
            })
        }
    }
    onChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
        this.change_field(e.target.id)
    }
    onChangeCourse = e => {
        this.setState({
            course_type: e.target.value
        });
        this.change_field('course type')
    }
    onChangePaymentStatus = e => {
        this.setState({
            payment_status: e.target.value
        });
        this.change_field('payment status')
    }
    onChangeOnboardingStatus = e => {
        this.setState({
            is_onboarding_done: e.target.value
        });
        this.change_field('Onboarding done')
    }
    onChangePaymentMethod = e => {
        this.setState({
            payment_method: e.target.value
        });
        this.change_field('Payment method')
    }

    onChangeMentor = e => {
        this.setState({
            mentor_id: e.target.value
        });
        this.change_field('Mentor id')
    }

    edit_user(id) {
        let arr = this.state.data
        let student
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].email == id) {
                student = arr[i];
                break;
            }
        }
        this.setState({
            open: true,
            email: student.email,
            name: student.name,
            phone: student.phone,
            fees: student.fees,
            linkedin_profile: student.linkedin_profile,
            leetcode: student.leetcode,
            college: student.college,
            current_company: student.current_company,
            experience: student.experience,
            batch: student.batch,
            course_type: student.course_type,
            payment_status: student.payment_status,
            is_onboarding_done: student.is_onboarding_done,
            payment_method: student.payment_method,
            remarks: student.remarks,
            mentor_id: student.mentor_id,
            mail_sent: student.mail_sent
        })
    }

    send_email(id) {
        let req = {
            email: id,
            admin_uid: isAuthenticated()?.data?.localId,
        }
        sendEmail(req).then(res => {
            let ind = 0
            for (var i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].email == id) {
                    ind = i;
                    break;
                }
            }
            let data = [...this.state.data];
            data[ind].mail_sent = true
            this.setState({ data });
        });
    }
    handleToggle(id) {
        let ind = 0;
        let togglestate = false
        for (var i = 0; i < this.state.data.length; i++) {
            if (this.state.data[i].email == id) {
                ind = i;
                togglestate = !this.state.data[i].disabled
                break;
            }
        }
        let req = {
            email: id,
            admin_uid: isAuthenticated()?.data?.localId,
            disable_user: togglestate
        }
        updateDisableState(req).then(res => {
            let data = [...this.state.data];
            data[ind].disabled = togglestate
            this.setState({ data });
        })
    }

    paidToUnpaid (id){
        let ind = 0;
        let togglestate = "NOT_PAID"
        let toggle = false;
        for (var i = 0; i < this.state.data.length; i++) {
            if (this.state.data[i].email == id) {
                ind = i;
                if (this.state.data[i].payment_status == "NOT_PAID"){
                    togglestate = "PAID"
                }
                break;
            }
        }
        toggle = togglestate === "PAID" ? true : false
        let req = {
            email: id,
            admin_uid: isAuthenticated()?.data?.localId,
            status: toggle
        }
        paymentStatus(req).then(res =>{
            let data = [...this.state.data];
            data[ind].payment_status = togglestate;
            this.setState({data});
        })
    } 
    
    loadNextData = e => {
        this.setState({ next_data_loaded: false })
        getNextStudents({
            admin_uid: isAuthenticated()?.data?.localId,
            email: this.state.last_student
        }).then(res => {
            let student_data = res.data.response.students
            let previous_data = this.state.data
            let new_data = previous_data.concat(student_data);
            this.setState({ data: new_data, last_student: student_data[student_data.length - 1].email, next_data_loaded: true })
        })
            .catch(err => console.log(err))

    }
    handleSubmit = e => {
        let req = {
            email: this.state.email,
            name: this.state.name,
            phone: this.state.phone,
            fees: this.state.fees,
            linkedin_profile: this.state.linkedin_profile,
            leetcode: this.state.leetcode,
            college: this.state.college,
            current_company: this.state.current_company,
            experience: this.state.experience,
            batch: this.state.batch,
            course_type: this.state.course_type,
            payment_status: this.state.payment_status,
            is_onboarding_done: this.state.is_onboarding_done,
            payment_method: this.state.payment_method,
            remarks: this.state.remarks,
            admin_uid: isAuthenticated()?.data?.localId,
            mentor_id: this.state.mentor_id,
            mail_sent: this.state.mail_sent
        }
        updateStudent(req).then(res => {
            this.setState({ open: false, open2: false })
            let id = 0
            for (var i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].email == this.state.email) {
                    id = i;
                    break;
                }
            }
            let data = [...this.state.data];
            data[id] = req;
            this.setState({ data });
        })
            .catch(err => console.log(err))
    }


    render() {
        return (
            <div>
                <TableContainer component={Paper} style={{
                    borderStyle: "inset", borderColor: '#ADD8E6',
                }}>
                    <TextField
                        label="Search by Name"
                        onChange={this.onSearchNameInputChange}
                        value={this.state.searchName}
                        id="searchName"
                        type="text"
                        style={{ margin: "20px" }}
                    />
                    <TextField
                        label="Search by Email"
                        onChange={this.onSearchInputChange}
                        value={this.state.searchemail}
                        id="searchEmail"
                        type="text"
                        style={{ margin: "20px" }}
                    />
                    <TextField
                        label="Search by Batch"
                        onChange={this.onSearchBatchInputChange}
                        value={this.state.searchbatch}
                        id="searchBatch"
                        type="text"
                        style={{ margin: "20px" }}
                    />
                    <TextField
                        label="Search by Payment Status"
                        onChange={this.onSearchPaymentStatusInputChange}
                        value={this.state.searchpaymentstatus}
                        id="searchPaymentStatus"
                        type="text"
                        style={{ margin: "20px" }}
                    />
                    <Table aria-label="simple table">
                        <colgroup>
                            <col width="10%" />
                            <col width="5%" />
                            <col width="10%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="10%" />
                            <col width="20%" />
                            <col width="40%" />
                        </colgroup>
                        <TableHead>

                            <TableRow>
                                <TableCell scope="row">
                                    General Info
                                </TableCell>
                                <TableCell scope="row">
                                    Batch
                                </TableCell>
                                <TableCell scope="row">
                                    Leetcode Details
                                </TableCell>
                                <TableCell scope="row">
                                    Onboarding Done
                                </TableCell>
                                <TableCell scope="row">
                                    Fees
                                </TableCell>
                                <TableCell scope="row">
                                    Fee Status
                                </TableCell>
                                <TableCell scope="row">
                                 Unpaid To Paid
                                </TableCell>
                                <TableCell scope="row">
                                    Disable user
                                </TableCell>
                                <TableCell scope="row">
                                    Confirmation mail
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.data.filter(searchingfor(this.state.searchemail))
                                .filter(searchingbyname(this.state.searchName))
                                .filter(filterbybatch(this.state.searchbatch))
                                .filter(filterbypaymentstatus(this.state.searchpaymentstatus)).map((row) => (
                                    <TableRow key={row.email}

                                    >
                                        <TableCell scope="row" >
                                            <p style={{ margin: 0 }}> {row.name} </p>
                                            <b style={{ margin: 0 }}> {row.email} </b>
                                            <p style={{ margin: 0 }}> {row.phone} </p>
                                            <p style={{ margin: 0 }}> {row.course_type} </p>
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.batch}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.leetcode_count} {"/"} {row.last_week_count}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.is_onboarding_done ?
                                                <Chip label="Yes" color="success" variant="outlined" /> :
                                                <Chip label="No" color="error" variant="outlined" />
                                            }
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.fees}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {(() => {
                                                if (row.payment_status == 'payment done') {
                                                    return (
                                                        <Chip label="Payment Done" color="success" variant="outlined" />
                                                    )
                                                } else if (row.payment_status == 'dropped') {
                                                    return (
                                                        <Chip label="Dropped" color="error" variant="outlined" />
                                                    )
                                                } else {
                                                    return (
                                                        <Chip label="Payment not done" color="warning" variant="outlined" />
                                                    )
                                                }
                                            })()}

                                        </TableCell>
                                        <TableCell scope="row" >
                                        <Switch
                                                checked={row.payment_status === "PAID" ? true : false}
                                                onChange={() => this.paidToUnpaid(row.email)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                color="success"
                                            />
                                        </TableCell>
                                        <TableCell scope="row" >
                                            <Switch
                                                checked={row.disabled}
                                                onChange={() => this.handleToggle(row.email)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                color="error"
                                            />
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.mail_sent ?
                                                <button
                                                    disabled
                                                    // onClick={() => this.send_email(row.email)}
                                                    style={{
                                                        margin: "auto",
                                                        borderColor: "white",
                                                        backgroundColor: "grey",
                                                        color: "white",
                                                        padding: "10px"
                                                    }}>
                                                    Email sent
                                                </button>
                                                : <button
                                                    onClick={() => this.send_email(row.email)}
                                                    style={{
                                                        margin: "auto",
                                                        borderColor: "white",
                                                        backgroundColor: "green",
                                                        color: "white",
                                                        padding: "10px"
                                                    }}>
                                                    Send Email
                                                </button>}
                                        </TableCell>
                                        <TableCell scope="row">
                                            <a href={`/admin/profile/edit/${row.email}`}>
                                                <button
                                                    onClick={() => this.edit_user(row.email)}
                                                    style={{
                                                        margin: "auto",
                                                        borderColor: "white",
                                                        backgroundColor: "white",
                                                    }}>
                                                    <EditIcon />
                                                </button>
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    { }
                    {this.state.next_data_loaded ?
                        <button
                            onClick={() => this.loadNextData()}
                            style={{
                                margin: "auto",
                                borderColor: "white",
                                padding: "10px",
                                backgroundColor: "#1D99BE",
                                color: "white",
                                margin: "20px 45%"
                            }}>
                            Load More
                        </button>
                        :
                        <button
                            style={{
                                margin: "auto",
                                borderColor: "white",
                                padding: "10px",
                                backgroundColor: "grey",
                                color: "white",
                                margin: "20px 45%"
                            }}>
                            Loading...
                        </button>
                    }
                </TableContainer>
                {/* <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle> Edit User Details </DialogTitle>
                    <DialogContent>
                        <TextField
                            disabled
                            id="email"
                            label="Email"
                            type="email"
                            onChange={this.onChange}
                            value={this.state.email}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />
                        <TextField
                            autoFocus
                            id="name"
                            label="Name"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.name}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />
                        <TextField
                            autoFocus
                            id="phone"
                            label="Phone"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.phone}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />
                        <TextField
                            autoFocus
                            id="fees"
                            label="Fees"
                            type="number"
                            onChange={this.onChange}
                            value={this.state.fees}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />
                        <TextField
                            autoFocus
                            id="linkedin_profile"
                            label="Linkedin"
                            value={this.state.linkedin_profile}
                            type="text"
                            onChange={this.onChange}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />
                        <TextField
                            autoFocus
                            id="leetcode"
                            label="Leetcode"
                            value={this.state.leetcode}
                            type="text"
                            onChange={this.onChange}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />
                        <TextField
                            autoFocus
                            id="college"
                            label="College"
                            value={this.state.college}
                            type="text"
                            onChange={this.onChange}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />
                        <TextField
                            autoFocus
                            id="current_company"
                            label="Current Company"
                            value={this.state.current_company}
                            type="text"
                            onChange={this.onChange}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />
                        <TextField
                            autoFocus
                            id="experience"
                            label="Experience"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.experience}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />
                        <TextField
                            autoFocus
                            id="batch"
                            label="Batch"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.batch}
                            halfWidth
                            style={{ margin: "10px 15px" }}
                        />

                        <TextField
                            id="course_type"
                            select
                            label="Course Type"
                            value={this.state.course_type}
                            onChange={this.onChangeCourse}
                            style={{ margin: "10px 15px" }}
                            helperText="Please select the user's course type"
                        >
                            <MenuItem id="course_type" value={"student"}>student</MenuItem>
                            <MenuItem id="course_type" value={"working"}> working</MenuItem>
                        </TextField>

                        <TextField
                            id="payment_status"
                            select
                            label="Payment Status"
                            value={this.state.payment_status}
                            onChange={this.onChangePaymentStatus}
                            style={{ margin: "10px 15px" }}
                            helperText="Please select the payemnt status"
                        >
                            <MenuItem value={"payment done"}> payment done </MenuItem>
                            <MenuItem value={"payment not done"}> payment not done </MenuItem>
                            <MenuItem value={"dropped"}> dropped </MenuItem>
                        </TextField>

                        <TextField
                            id="is_onboarding_done"
                            select
                            label="Onboarding Done?"
                            value={this.state.is_onboarding_done}
                            onChange={this.onChangeOnboardingStatus}
                            style={{ margin: "10px 15px" }}
                            helperText="Please select the onboarding status"
                        >
                            <MenuItem value={"true"}> Yes </MenuItem>
                            <MenuItem value={"false"}> No</MenuItem>
                        </TextField>

                        <TextField
                            id="payment_method"
                            select
                            label="Payment method"
                            helperText="Please select the payment method"
                            value={this.state.payment_method}
                            style={{ margin: "10px 15px" }}
                            onChange={this.onChangePaymentMethod}
                        >
                            <MenuItem value={"BCA Direct"}> BCA Direct </MenuItem>
                            <MenuItem value={"3 Month EMI"}> 3 Month EMI </MenuItem>
                            <MenuItem value={"6 Month EMI"}> 6 Month EMI </MenuItem>
                            <MenuItem value={"9 Month EMI"}> 9 Month EMI </MenuItem>
                        </TextField>

                        <TextField
                            id="mentor_id"
                            select
                            label="Mentor"
                            helperText="Please select the Mentor"
                            value={this.state.mentor_id}
                            style={{ margin: "10px 15px" }}
                            onChange={this.onChangeMentor}
                        >
                            {this.state.mentors.map((row) =>
                                <MenuItem value={row.email}> {row.name} <br /> {row.email} </MenuItem>
                            )}
                        </TextField>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="remarks"
                            label="Remarks for the user"
                            value={this.state.remarks}
                            onChange={this.onChange}
                            type="text"
                            fullWidth
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>Cancel</Button>
                        <Button onClick={this.openSecond}>Submit</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.open2}
                    onClose={this.handleClose2}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are you Sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            you have changed the following fields :
                            {this.state.fields_changes.map((data) =>
                                <p style={{ color: "red" }}>  {data},  </p>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose2}> Cancel </Button>
                        <Button onClick={this.handleSubmit} autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog> */}
            </div>
        );
    }
}

export default Dashboard