import { Paper, Typography } from "@mui/material";
import { Box} from "@mui/system";
import Container from "@mui/material/Container";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../assets/Colors";
const NameDesk = (props) => {
  const name = props.name;
  let navigate = useNavigate();
  //   const handleClick = () => {
  //     navigate("/user/curriculum");
  // }
  return (
    <>
      <div>
        <Paper
          sx={{
            height: "210px",
            bgcolor: Colors.secoundaryLite,
            border: 1,
            borderColor: Colors.secoundaryBackgorund,
          }}
          variant="outlined"
        >
          <Container sx={{ padding: 0 }}>
            <Box sx={{ float: "right", pt: 2, pb: 2, pr: "47px" }}>
              <img src= "https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FProfile_page%2FProfilePage.svg?alt=media&token=fe0287e0-1d3a-45b6-8a20-237abd06b0b7" alt="" />
            </Box>

            <Typography
              sx={{ paddingTop: 4, paddingLeft: 4, color: "#084652" }}
            >
              Welcome Back,
            </Typography>
            <Typography
              sx={{
                paddingTop: 2,
                paddingLeft: 4,
                color: "#084652",
                fontSize: "32px",
              }}
            >
              {name}
            </Typography>
            {/* <Box sx={{width : "250px"}}>
            <Typography sx={{paddingTop : "36px", paddingLeft : 4, color : "#084652"}}>
            Get back to the course
            </Typography>
            <ArrowRightAltIcon  sx={{fontSize : "35px",float : "right", mt : -3.5 , mr : 2, cursor : "pointer"   }}/>
          </Box>  */}
          </Container>
        </Paper>
      </div>
    </>
  );
};

export default NameDesk;
