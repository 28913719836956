import React, { Component } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { isAuthenticated } from "../../api/authApi";
import axios from "../../api/axios";
import { Divider, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import Tooltip from "@mui/material/Tooltip";
import Loading from "../notificationPages/Loading";

let maxScore = 0;
let totalTestcase = 0;

class Submissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: [],
      chosenIndex: -1,
      liked: false,
      disliked: false,
      testCases: this.props.runCodeSubmission,
    };
  }

  changeIndex = (e, key) => {
    this.setState({ chosenIndex: key });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.runCodeSubmission !== this.props.runCodeSubmission) {
      this.setState({
        testCases: this.props.runCodeSubmission,
      });
    }
  }

  componentDidMount() {
    this.setState({
      result: this.props.result,
      liked: this.props.liked,
      disliked: this.props.disliked,
    });
    const auth = isAuthenticated()?.data;
    const topic = window.location.pathname.split("/")[3];
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          {
            let data = {
              topic: topic,
              id_token: res?.data?.id_token,
            };
            axios
              .post("student/get_student_submission_by_question", data)
              .then((res) => {
                this.setState({
                  result: res.data.submissions,
                });
              });
          }
        }
      });
  }

  render() {
    const BootstrapTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        paddingLeft: "32px",
        paddingRight: "32px",
        borderRadius: "8px",
      },
    }));

    return this.props.loading == false ? (
      <>
        <span>
          <Chip
            label={
              this.props.difficulty_level[0].toUpperCase() +
              this.props.difficulty_level.substring(1)
            }
            sx={{
              backgroundColor: "#4ADE80",
              color: "white",
              padding: "4px",
              height: "24px",
              mb: 2,
              mt: 6,
            }}
          />
        </span>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent="end"
          alignItems="end"
          sx={{ flexWrap: "wrap", marginTop: 6, width: "50%", float: "right" }}
        >
          <span style={{}}>
            <BootstrapTooltip title={this.props.likes} placement="top-start">
              {this.props.liked ? (
                <ThumbUpAltIcon
                  style={{ marginTop: "4px", color: "#A3A3A3" }}
                  onClick={this.props.changeLikeButtonToFalse}
                />
              ) : (
                <ThumbUpOffAltIcon
                  style={{ marginTop: "4px", color: "#A3A3A3" }}
                  onClick={this.props.changeLikeButtonToTrue}
                />
              )}
            </BootstrapTooltip>
          </span>

          <span>
            <BootstrapTooltip title={this.props.dislikes} placement="top-start">
              {this.props.disliked ? (
                <ThumbDownAltIcon
                  style={{
                    marginTop: "4px",
                    marginLeft: "10px",
                    color: "#A3A3A3",
                  }}
                  onClick={this.props.changeDislikeButtonToFalse}
                />
              ) : (
                <ThumbDownOffAltIcon
                  style={{
                    marginTop: "4px",
                    marginLeft: "10px",
                    color: "#A3A3A3",
                  }}
                  onClick={this.props.changeDislikeButtonToTrue}
                />
              )}
            </BootstrapTooltip>
          </span>
          {/* <span style={{}}>
            <ShareOutlinedIcon
              sx={{ marginTop: "4px", color: "#A3A3A3", ml: "10px" }}
            />
          </span>
          <span style={{}}>
            <StarOutlineOutlinedIcon
              sx={{ marginTop: "4px", color: "#A3A3A3", ml: "10px" }}
            />
          </span> */}
        </Stack>

        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 700,
            mt: 1,
            mb: "14px",
            lineHeight: "21.94px",
            color: "#312E81",
          }}
        >
          {this.props.question_number}. {this.props.problem_name}
        </Typography>
        {/* <Stack sx={{ mt: 4, mb: 4 }}> */}

        <Stack
          direction={"row"}
          spacing={1}
          sx={{ flexWrap: "wrap", mt: 4, mb: 4 }}
        >
          <span>
            {this.props.tags.map((tag) => {
              return (
                <span style={{}}>
                  <Chip
                    label={tag[0]?.toUpperCase() + tag.substring(1)}
                    sx={{
                      height: "24px",
                      color: "#312E81",
                      borderColor: "#312E81",
                      pt: "4px",
                      pb: "4px",
                      pr: "21px",
                      pl: "21px",
                      marginRight: 2,
                      borderRadius: 1,
                    }}
                    variant="outlined"
                  />
                </span>
              );
            })}
          </span>
        </Stack>

        <div style={{ color: "#000000", marginLeft: 4, marginRight: 4 }}>
          {this.state.chosenIndex > -1 ? (
            <div>
              {this.state.result[this.state.chosenIndex].compile_output ? (
                <div style={{ margin: "10px" }}>
                  <h4> Compile Error : </h4>
                  {this.state.result[this.state.chosenIndex].compile_output}
                </div>
              ) : (
                <div>
                  {this.state.result[this.state.chosenIndex].stderr ? (
                    <div style={{ margin: "10px" }}>
                      <h4> Runtime Error : </h4>
                      {this.state.result[this.state.chosenIndex].stderr}
                    </div>
                  ) : (
                    <Stack
                      direction={"row"}
                      spacing={1}
                      sx={{ flexWrap: "wrap" }}
                    >
                      {this.state.result[this.state.chosenIndex].stdout.map(
                        (value, index) => {
                          let label = `Testcase ${index + 1}`;
                          return (
                            <span style={{ margin: "10px" }}>
                              {value === "0" ? (
                                <Chip
                                  label={label}
                                  color="error"
                                  deleteIcon={<CloseIcon />}
                                  onDelete={() => {}}
                                />
                              ) : (
                                <Chip
                                  label={label}
                                  color="success"
                                  deleteIcon={<DoneIcon />}
                                  onDelete={() => {}}
                                />
                              )}
                            </span>
                          );
                        }
                      )}
                    </Stack>
                  )}
                </div>
              )}
            </div>
          ) : null}
          <div
            // key={ind}
            // onClick={(e) => this.changeIndex(e, ind)}
            style={{
              padding: "15px",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              marginBottom: "32px",
              marginLeft: "-3px",
              paddingRight: "100px",
              boxShadow: "0px 4px 16px rgba(32, 76, 188, 0.2)",
            }}
            align="center"
          >
            <Typography sx={{ fontSize: "16px", color: "#312E81", mt: 1 }}>
              {this.state.result.map((arr, ind) => {
                maxScore = Math.max(maxScore, arr.score);
                totalTestcase = Math.max(totalTestcase, arr.total_testcase);
              })}
              {maxScore} / {totalTestcase}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineheight: "17px",
                color: "#A3A3A3",
              }}
            >
              Your Best score
            </Typography>
          </div>
          {this.state.result.length > 0 ? (
            <Typography
              sx={{
                color: "#312E81",
                fontWeight: 700,
                marginBottom: "32px",
              }}
            >
              Your submissions :
            </Typography>
          ) : (
            <div>
              <Typography
                sx={{
                  color: "#312E81",
                  fontWeight: 700,
                  marginBottom: "32px",
                }}
              >
                Your submissions :
              </Typography>
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  // cursor: "pointer",
                  marginBottom: "32px",
                  boxShadow: "0px 4px 16px rgba(32, 76, 188, 0.2)",
                  height: "100%",
                }}
                align="center"
              >
                <Typography
                  sx={{
                    display: "block",
                    color: "#A3A3A3",
                    fontWeight: 500,
                    lineHeight: "20px",
                    paddingBottom: "205px",
                    paddingTop: "206px",
                  }}
                >
                  You have not performed any assignment yet!
                </Typography>
              </div>
            </div>
          )}
          {this.state.result.reverse().map((arr, ind) => {
            return (
              <>
                {arr.compile_output !== "" ? (
                  <Accordion
                    sx={{
                      backgroundColor: "#FFFFFF",
                      marginBottom: 4,
                      marginTop: 4,
                      marginLeft: "-3px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      boxShadow: "0px 4px 16px rgba(32, 76, 188, 0.2)",
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ pr: "24px" }} />}
                    >
                      <Typography
                        sx={{
                          color: "#DC2626",
                          fontWeight: 700,
                          pt: 2,
                          pl: 1,
                          pb: 2,
                          display: "block",
                        }}
                      >
                        Error
                        <Typography
                          sx={{
                            display: "block",
                            color: "#A3A3A3",
                            fontWeight: 500,
                            lineHeight: "20px",
                            paddingBottom: 1,
                            // paddingLeft: 1,
                            paddingTop: 1,
                          }}
                        >
                          {arr.compile_output.substring(0, 66)}
                        </Typography>
                      </Typography>
                    </AccordionSummary>
                    <Divider sx={{ marginLeft: 3, mr: "26px" }} />
                    <AccordionDetails sx={{ pl: 3, pr: "26px", mt: 3, mb: 2 }}>
                      {arr.compile_output}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <div
                    key={ind}
                    // onClick={(e) => this.changeIndex(e, ind)}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                      // cursor: "pointer",
                      marginBottom: "32px",
                      marginLeft: "-3px",
                      boxShadow: "0px 4px 16px rgba(32, 76, 188, 0.2)",
                    }}
                  >
                    {arr.score === arr.total_testcase ? (
                      <Typography
                        sx={{
                          color: "#22C55E",
                          fontWeight: 700,
                          paddingTop: 3,
                          paddingLeft: 3,
                        }}
                      >
                        Accepted
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#FBBF24",
                          fontWeight: 700,
                          paddingTop: 3,
                          paddingLeft: 3,
                        }}
                      >
                        Wrong Answer
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        color: "#A3A3A3",
                        fontWeight: 500,
                        lineHeight: "20px",
                        paddingBottom: 3,
                        paddingLeft: 3,
                        mt: 1,
                      }}
                    >
                      Test Passed {arr.score} /{" "}
                      {arr.total_testcase == -1 ? 0 : arr.total_testcase}
                    </Typography>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </>
    ) : (
      <Loading />
    );
  }
}

export default Submissions;
