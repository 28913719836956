import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Moment from "react-moment";

const DetailsTable = (props) => {
  const { allSessions } = props;
  return allSessions?.length ? (
    <>
      <TableContainer
        component={Paper}
        style={{
          borderStyle: "inset",
          borderColor: "#ADD8E6",
          marginTop: "2%",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell scope="row">Date</TableCell>
              <TableCell scope="row">Start</TableCell>
              <TableCell scope="row">End</TableCell>
              <TableCell scope="row">Student Id</TableCell>
              <TableCell scope="row">Mentor Id</TableCell>
              <TableCell scope="row">Room Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allSessions?.map((row) => (
              <TableRow>
                <TableCell scope="row">
                  <Moment format="MMMM Do YYYY">{row?.date}</Moment>
                </TableCell>
                <TableCell scope="row">{row?.start} : 00</TableCell>
                <TableCell scope="row">{row?.end} : 00</TableCell>
                <TableCell scope="row">{row?.student_id}</TableCell>
                <TableCell scope="row">{row?.mentor_id}</TableCell>
                <TableCell scope="row">{row?.room_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <div align="center">
      <img
        style={{ width: "50%", height: "20%" }}
        src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fdata%20not%20found.jpg?alt=media&token=3191a7eb-db5f-4780-b6c2-96e48456afb2"
        alt=""
      />
    </div>
  );
};

export default DetailsTable;
