import React from "react";
import { Container, Divider, Paper, Typography } from "@mui/material";
import Moment from "react-moment";
import * as moment from "moment";
const SessionDetails = (props) => {
  const {
    additional_comments,
    batch,
    confidence,
    date,
    details,
    explanation_clarity,
    how_course_work_is_ongoing,
    implementation_speed,
    leetcode_counter,
    mentor_email,
    mentor_name,
    naming_convention,
    progress,
    reacting_to_hints,
    session_no,
    student_email,
    student_name,
    thinking_process,
    time_complexity_analysis,
    unique_id,
  } = props;

  return (
    <>
      

      <Paper sx={{ p: 2, marginBottom: 2, flexGrow: 1 }} variant="outlined">
        <Container maxWidth="sm" />
        <Typography sx={{ fontWeight: 600, fontSize: 16, mb: 2, mt: 2, ml: 4 }}>
          <Moment format="MMMM Do YYYY">{date}</Moment>
        </Typography>

        <Divider
          variant="middle"
          sx={{ background: "#0588ed", borderBottomWidth: 0.5, ml: 4 }}
        />

        {/* <Typography sx={{ fontWeight: 600, fontSize: 14, mb: 2, ml: 4 }}>
          {student_name}
        </Typography> */}

        <Typography sx={{ fontWeight: 600, fontSize: 14,  mt: 2, ml: 4 }}>
          Session No
        </Typography>
        <Typography sx={{ ml: 4, fontSize: 14 }}>
          {session_no}
        </Typography>
        {/* <Typography sx={{ fontWeight: 600, fontSize: 14, mt: 2, ml: 4 }}>
          Leetcode Profile
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {leetcode_counter}
        </Typography> */}

        <Typography sx={{ fontWeight: 600, fontSize: 14, mt: 2, ml: 4 }}>
          Leetcode Counter
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {leetcode_counter}
        </Typography>

        <Divider
          variant="middle"
          sx={{ background: "#0590ed", borderBottomWidth: 0.5, ml: 4 }}
        />

        <Typography sx={{ fontWeight: 600, fontSize: 14, mt: 2, ml: 4 }}>
        Feedback
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {how_course_work_is_ongoing}
        </Typography>

        <Divider variant="middle" sx={{ borderBottomWidth: 1, ml: 4 }} />

       

        <Typography sx={{ fontWeight: 600, fontSize: 14,  mt: 2, ml: 4 }}>
        Milestones for mentee
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {confidence}
        </Typography>

        {/* <Divider variant="middle" sx={{ borderBottomWidth: 1, ml: 4 }} />

        

        <Divider variant="middle" sx={{ borderBottomWidth: 1, ml: 4 }} />

        <Typography sx={{ fontWeight: 600, fontSize: 14, mt: 2, ml: 4 }}>
          Naming convenstion
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {naming_convention}
        </Typography>

        <Divider variant="middle" sx={{ borderBottomWidth: 1, ml: 4 }} />

        <Typography sx={{ fontWeight: 600, fontSize: 14,  mt: 2, ml: 4 }}>
          Reaction to hints
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {reacting_to_hints}
        </Typography>

        <Divider variant="middle" sx={{ borderBottomWidth: 1, ml: 4 }} />

        <Typography sx={{ fontWeight: 600, fontSize: 14, mt: 2, ml: 4 }}>
          Time complexity analysis
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {time_complexity_analysis}
        </Typography>

        <Divider variant="middle" sx={{ borderBottomWidth: 1, ml: 4 }} />

        <Typography sx={{ fontWeight: 600, fontSize: 14, mt: 2, ml: 4 }}>
          Thinking process
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {thinking_process}
        </Typography>

        <Divider variant="middle" sx={{ borderBottomWidth: 1, ml: 4 }} />

        <Typography sx={{ fontWeight: 600, fontSize: 14, mt: 2, ml: 4 }}>
          Implementation speed
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {implementation_speed}
        </Typography>

        <Divider variant="middle" sx={{ borderBottomWidth: 1, ml: 4 }} />

        <Typography sx={{ fontWeight: 600, fontSize: 14, mt: 2, ml: 4 }}>
          Explanation clarity
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {explanation_clarity}
        </Typography>

        <Divider variant="middle" sx={{ borderBottomWidth: 1, ml: 4 }} />

        <Typography sx={{ fontWeight: 600, fontSize: 14,  mt: 2, ml: 4 }}>
          Additional comments
        </Typography>
        <Typography sx={{ ml: 4, mb: 2, fontSize: 14 }}>
          {additional_comments}
        </Typography> */}

        
      </Paper>
    </>
  );
};

export default SessionDetails;
