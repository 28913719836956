import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, TextField, Button, Divider } from "@mui/material";
import { Colors } from "../../../../assets/Colors";
const ReferralCode = (props) => {
  const { obj } = props;
  const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: Colors.primaryMain,
    "&:hover": {
      backgroundColor: Colors.primaryButtonHover,
    },
  }));

  const copy = () => {
    navigator.clipboard.writeText(obj?.referralCode);
  };

  return (
    <>
      <Typography sx={{ ml: 4, mt: 6, mb: 2, fontSize: 16 }}>
        Referral code :
      </Typography>

      <TextField
        id="outlined-size-small"
        size="small"
        value={obj?.referralCode}
        disabled={true}
        sx={{ ml: 4 }}
      />

      <ColorButton
        onClick={copy}
        variant="contained"
        sx={{ ml: 1, height: "39px" }}
      >
        Copy
      </ColorButton>

      <Typography sx={{ ml: 4, mt: 4, mb: 4, fontSize: 18 }}>
        Ask your friend to share this with the counselor at <br />
        the time of admission.
      </Typography>
      <Divider variant="middle" sx={{}} />
    </>
  );
};

export default ReferralCode;
