import axios from "./axios";
export const getBatch = async () => {
  return await axios.get("/common/get_dropdown_info");
};

export const getCourseTopic = async () => {
  return await axios.get("/common/get_course_topics");
};

export const createMeeting = async (data) => {
  return await axios.post("/mentors/create_meeting", data);
};

export const isInstructor = async (data) => {
  return await axios.post("/mentors/is_instructor", data);
};
