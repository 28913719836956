import React from "react";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add,
  Logout,
  Menu,
  Person,
  AddCard,
  Update,
  LibraryBooks,
} from "@mui/icons-material";
import GroupsIcon from '@mui/icons-material/Groups';
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { withRouter } from "../../helpers/withRouter";
import { isAuthenticated, signout } from "../../api/authApi";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const drawerWidth = 240;

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "80%",
  maxHeight: "80%",
});

const Sidebar = (props) => {
  const { window } = props;
  let navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const itemsList = [
    {
      text: "Profile",
      icon: <Person />,
      onClick: () => navigate("/mentor/profile"),
    },
    // {
    //   text: "Add Session",
    //   icon: <AddCard />,
    //   onClick: () => navigate("/mentor/add-sessions"),
    // },
    {
      text: "All Mentor Sessions",
      icon: <Update />,
      onClick: () => navigate("/mentor/all-mentor-sessions"),
    },
    // {
    //   text: "Update Sessions",
    //   icon: <Update />,
    //   onClick: () => navigate("/mentor/update-sessions"),
    // },
    {
      text: "Start Live Class",
      icon: <Add />,
      onClick: () => navigate("/mentor/start-meeting"),
    },
    {
      text: "Curriculum",
      icon: < LibraryBooks/>,
      onClick: () => navigate("/mentor/mentor-curriculum"),
    },
    {
      text: "My Mentees",
      icon: < GroupsIcon/>,
      onClick: () => navigate("/mentor/my-mentee"),
    },
    {
      text: "Availability ",
      icon: < EventAvailableIcon/>,
      onClick: () => navigate("/mentor/availability"),
    },
    {
      text: "Monthly Sessions",
      icon: < EventAvailableIcon/>,
      onClick: () => navigate("/mentor/monthly-sessions"),
    },
    {
      text: "Logout",
      icon: <Logout />,
      onClick: () => {
        signout(isAuthenticated()?.data?.localId, () => {
          navigate("/");
        });
      },
    },
  ];

  const drawer = (
    <div>
      <Toolbar>
        <Img
          src="https://uploads-ssl.webflow.com/60e1490057a19b506bd4ebb3/60fd54c519be8bccfd2922de_bosscoder_header_logo_2.svg"
          alt="logo"
        />
      </Toolbar>
      <Divider />
      <List>
        {itemsList.map((item) => {
          const { text, icon, onClick } = item;
          return (
            <ListItem button key={text} onClick={onClick}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box sz={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          color="inherit"
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <Menu />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, mr: 2, display: { sm: "none" } }}
            >
              Bosscoder Academy
            </Typography>
           
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
            // className = {{width: "500px"}}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default withRouter(Sidebar);
