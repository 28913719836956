import React from "react";
import { Typography, ListItem, List } from "@mui/material";
import WatchOutlinedIcon from "@mui/icons-material/WatchOutlined";
import TvOutlinedIcon from "@mui/icons-material/TvOutlined";
import TabletMacOutlinedIcon from "@mui/icons-material/TabletMacOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import SettingsInputSvideoOutlinedIcon from "@mui/icons-material/SettingsInputSvideoOutlined";
const RewardList = () => {
  return (
    <>
      <Typography variant="h6" sx={{ ml: 4, mt : 4, fontWeight: 600 }}>
        Ohh wait...! there are more, Invite your <br />
        friends and get amazing...
        <Typography
          varient="span"
          display="inline"
          sx={{ fontSize: 22, fontWeight: 600, color: "#1D99BE" }}
        >
          REWARDS!
        </Typography>
      </Typography>

      <List sx={{ listStyleType: "disc", }}>
        <ListItem sx={{ fontSize: 18, ml: 2, mt: 2 }}>
          <SettingsInputSvideoOutlinedIcon sx={{ mr: 2 }} /> Amazon echo dot
        </ListItem>
        <ListItem sx={{ fontSize: 18, ml: 2 }}>
          <WatchOutlinedIcon sx={{ mr: 2 }} /> Samsung galaxy watch 3
        </ListItem>
        <ListItem sx={{ fontSize: 18, ml: 2 }}>
          <TvOutlinedIcon sx={{ mr: 2 }} /> Oneplus TV
        </ListItem>
        <ListItem sx={{ fontSize: 18, ml: 2 }}>
          <TabletMacOutlinedIcon sx={{ mr: 2 }} /> Apple ipad air
        </ListItem>
        <ListItem sx={{ fontSize: 18, ml: 2 }}>
          <CameraAltOutlinedIcon sx={{ mr: 2 }} /> Go pro hero
        </ListItem>
      </List>
    </>
  );
};

export default RewardList;
