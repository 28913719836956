import React, { useState, useEffect } from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { isAuthenticated, signout } from "../../../api/authApi";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button, Link, Typography, Chip, Divider, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TextField from '@mui/material/TextField';
import { LoadingButton } from "@mui/lab";
import Loading from '../../notificationPages/Loading'



const QuestionsDashboard = () => {
    const [questions, setQuestions] = useState();
    const [searchQuestion, setSearchQuestion] = useState();
    const [loadNextData, setLoadNextData] = useState()
    const [lastQuestion, setLastQuestion] = useState();
    const [loading, setLoading] = useState(false);
    const auth = isAuthenticated()?.data;
    const navigate = useNavigate();
    const globalLink = "https://student.bosscoderacademy.com/user/codeEditor/"
    useEffect(() => {
        setLoading(true);
        axios
            .post("/common/refresh_token", {
                refresh_token: auth?.refreshToken,
            })
            .then((res) => {
                if (res?.data?.access_token) {
                    var existing = JSON.parse(localStorage.getItem("jwt"));
                    existing.data.idToken = res?.data?.access_token;
                    localStorage.setItem("jwt", JSON.stringify(existing));

                    axios.post("/student/get_top_50_questions_v3", {
                        id_token: res?.data?.access_token,
                    }

                    ).then((res) => {
                        if (res?.data?.success) {
                            setLoading(false)
                            setQuestions(res?.data?.response?.questions)
                            setLastQuestion(res.data.response.questions)
                        }

                    }).catch((err) => {
                        setLoading(false);
                        console.log(err)
                    })
                } else {
                    signout(auth?.localId, () => {
                        navigate("/");
                    });
                }
            });


    }, []);
    const handleClick = () => {
        const randomIndex = Math.floor(Math.random() * questions?.length);
        window.open(globalLink + questions[randomIndex]?.topic, '_blank');
    }
    function searchingfor(searchQuestion) {
        return function (x) {
            return (
                x.problem_name?.toLowerCase().includes(searchQuestion?.toLowerCase()) ||
                !searchQuestion
            );
        };
    }
    let onSearchInputChange = (event) => {
        if (event.target.value) {
            setSearchQuestion(event.target.value);
        } else {
            setSearchQuestion("");
        }
    };

    const loadNext100Questions = (e) => {
        setLoadNextData(false)
        axios
            .post("/common/refresh_token", {
                refresh_token: auth?.refreshToken,
            })
            .then((res) => {
                if (res?.data?.access_token) {
                    var existing = JSON.parse(localStorage.getItem("jwt"));
                    existing.data.idToken = res?.data?.access_token;
                    localStorage.setItem("jwt", JSON.stringify(existing));

                    let obj = {
                        id_token: res?.data?.access_token,
                        problem_name: questions[questions.length - 1].topic
                    }

                    axios.post("/student/get_next_100_questions_v3", obj)
                        .then((res) => {
                            if (res?.data?.success) {
                                const questionsData = res?.data?.response?.questions;
                                const prevData = questions
                                const newData = prevData.concat(questionsData)
                                setQuestions(newData)
                                setLoadNextData(true)
                            }
                        });
                } else {
                    signout(auth?.localId, () => {
                        navigate("/");
                    });
                }
            });

    }
    const FilterTextfield = styled(MuiTextField)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            height: "40px",
            width: "147px",
            background: '#FAFAFA',
            borderRadius: "8px",
            marginLeft: "16px",
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: ' 0.5px solid #A3A3A3',
        },
        [theme.breakpoints.down('md')]: {
            marginRight: "16px",
        },
    }));
    const ColorButton = styled(LoadingButton)(({ theme }) => ({
        backgroundImage: "linear-gradient(95.79deg, #202658 -5.72%, #202658 -5.72%, #06A1F9 -5.71%, #4032EB 106.18%, #4032EB 106.18%)",
        padding: "8px 32px",
        borderRadius: "8px",
    }));

    return loading === false ? (

        <>
            <Box >
                <Typography align='left' sx={{ fontSize: "24px", color: "#312E81", fontWeight: 700 }}>
                    Problems
                </Typography>
                <Typography align='left' sx={{ color: "#737373" }}>
                    Practice challenging question at one place and sharpen your problem solving abilities.
                </Typography>
            </Box>
            <Box sx={{ mt: 3, mb: 3, display: "flex", maxWidth: "100%" }}>

                <Grid container>
                    {/* <Grid item xs={2} sx={{}}>
                        <Button variant='contained' sx={{
                            maxHeight: "40px", width: "137px",
                            background: 'linear-gradient(95.79deg, #202658 -5.72%, #202658 -5.72%, #06A1F9 -5.71%, #4032EB 106.18%, #4032EB 106.18%)',
                            borderRadius: "8px",
                            textTransform: "none",
                        }}
                            onClick={handleClick}
                        >
                            Pick Any One
                        </Button>

                    </Grid> */}
                    <Grid item xs={12}>

                        <div align="right" style={{}}>
                            <TextField
                                id="outlined-basic"
                                type="text"
                                autoComplete='off'
                                placeholder="Search Here !"
                                value={searchQuestion}
                                sx={{

                                    '& .MuiOutlinedInput-root': {
                                        height: "40px",
                                        width: "294px",
                                        background: '#FAFAFA',

                                        borderRadius: "8px"
                                    },

                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: ' 0.5px solid #A3A3A3',
                                    },
                                    // [theme.breakpoints.down('md')]: {
                                    //     display: "block",
                                    //     marginBottom: "12px"
                                    // },
                                    WebkitBoxShadow: "0 0 0 1000px white inset"

                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        '-webkit-text-fill-color': 'currentColor',
                                        '-webkit-box-shadow': '0 0 0px 1000px white inset',
                                        // transition: 'background-color 5000s ease-in-out 0s'
                                    }
                                }}
                                onChange={onSearchInputChange}
                            />

                            {/* <Chip label="All Topics" color="primary" sx={{
                                width: "108px", height: "40px", background: '#171717',
                                borderRadius: "8px",
                            }} />
                            <FilterTextfield
                                // select
                                placeholder="Filter !"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <FilterAltOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            /> */}
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <div style={{}}>

                <TableContainer component={Paper} elevation={0} sx={{ overflowX: "auto" }}>
                    <Table sx={{ border: "none", position: 'relative' }} aria-label="simple table">
                        <TableHead
                            sx={{
                                background:
                                    "linear-gradient(95.79deg, #202658 -5.72%, #202658 -5.72%, #06A1F9 -5.71%, #4032EB 106.18%, #4032EB 106.18%)",
                                borderRadius: 2,
                                whiteSpace : "normal"

                            }}
                        >

                            <TableRow >
                                <TableCell align="left" sx={{ color: "white", fontSize: 16 }}>No</TableCell>
                                <TableCell align="left" sx={{ color: "white", fontSize: 16 }}>Name</TableCell>
                                <TableCell align="left" sx={{ color: "white", fontSize: 16 }}>Likes</TableCell>
                                <TableCell align="left" sx={{ color: "white", fontSize: 16 }}>Difficulty</TableCell>
                                <TableCell align="left" sx={{ color: "white", fontSize: 16 }}>Tags</TableCell>
                                <TableCell align="left" colSpan={2} sx={{ color: "white", fontSize: 16 }}>Edit Questions</TableCell>
                                {/* <TableCell align="left" colSpan={2} sx={{ color: "white", fontSize: 16 }}></TableCell> */}

                            </TableRow>

                        </TableHead>
                        <TableBody sx={{ border: "none" }}>
                            {questions
                                ?.filter(searchingfor(searchQuestion))
                                ?.map((row, i) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{

                                            "&:nth-of-type(odd)": {
                                                backgroundColor: "#F5F5F5",
                                                border: 0
                                            },
                                            "&:last-child td, &:last-child th ,&:nth-of-type(odd) th,&:nth-of-type(even) th,&:nth-of-type(odd) td,&:nth-of-type(even) td": {
                                                border: 0
                                            },
                                            border: "none",
                                        }}
                                    >
                                        {/* <TableCell align="left">{row.status == true ? <TaskAltIcon sx={{ color: "#22C55E" }} /> : null}</TableCell> */}
                                        <TableCell align="left">{i + 1}</TableCell>
                                        <TableCell align="left" sx={{ color: "#312E81", fontSize: 16 }}>{row.question_number} . {row.problem_name}</TableCell>
                                        <TableCell align="left" sx={{ color: "#312E81", fontSize: 16 }}>{row.likes}</TableCell>
                                        <TableCell align="left" sx={{ color: "#312E81", fontSize: 16 }}>
                                            <Typography style={{ color: row.difficulty_level === 'easy' ? '#22C55E' : row.difficulty_level === 'medium' ? '#F59E0B' : '#EF4444', fontSize: "16px" }}>
                                                {row.difficulty_level[0]?.toUpperCase() + row.difficulty_level.substring(1)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ color: "#312E81", fontSize: 16 }}>{row.tags.length ? row.tags.join(" ")[0].toUpperCase() + row.tags.join(" ").substring(1) : ''}</TableCell>
                                        <TableCell align="left" sx={{ color: "#312E81", fontSize: 16 }}>{row.protein}</TableCell>
                                        <Link href={`update-question-form/edit/${row.topic}`} target="_blank">
                                            <TableCell align="left" sx={{ color: "#312E81", fontSize: 16 }}> Edit</TableCell>
                                        </Link>
                                        {/* <Link href="#" target="_blank">
                                            <TableCell align="left" sx={{ color: "#312E81", fontSize: 16 }}> Start </TableCell>
                                        </Link> */}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </div>
            {loadNextData == false ?
                <div>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </div>
                :
                <Grid container>
                    <Grid item xs={5} sx={{}}>
                        <Divider orientation="horizantal" sx={{ paddingTop: 4 }} />
                    </Grid>
                    <Grid item xs={1} sx={{ marginTop : "4px"   }}>
                        <Button variant="text" color="primary"
                            onClick={loadNext100Questions}
                            sx={{
                                '&:active': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none'
                                },
                               
                            }}>Load More</Button>
                    </Grid>
                    <Grid item xs={5} sx={{ml : -2}}>
                        <Divider orientation="horizantal" sx={{ paddingTop: 4 }} />
                    </Grid>
                </Grid>
            }
        </>
    ) :
        <Loading />
}

export default QuestionsDashboard