import React from "react";
import {
  Grid,
} from "@mui/material";
import PlacementCard from "./components/PlacementCard";
import PlacedStudentsList from "./components/PlacedStudentsList";
import Alert from '@mui/material/Alert';
import { Typography } from "@mui/material";
const Placement = () => {
  const companyInfo = [
    {
      companyName: "Amazon",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FAmazon.svg?alt=media&token=db7fed39-969b-4ae4-b719-f86a7cb055d0`,
      experienceRequired: "0 to 2 + years",
      jobTitle: "Software Developer Enginner",
    },
    {
      companyName: "Microsoft",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fmicrosoft.svg?alt=media&token=810fffa3-e6c2-48ce-8860-21e0c48f5b67`,
      experienceRequired: "0 to 2 + years",
      jobTitle: "Software Developer Enginner",
    },
    {
      companyName: "Arcesium",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FArcesium.png?alt=media&token=3713e615-03f2-425c-83b9-2847f8e372b4`,
      experienceRequired: "0 to 2 + years",
      jobTitle: "Software Developer Enginner",
    },
    {
      companyName: "Browserstack",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fbrowserstack.svg?alt=media&token=1bf5822a-73d9-4c08-a9fa-1a0471097bf1`,
      experienceRequired: "2 to 6+ years",
      jobTitle: "Senior Software Developer Enginner",
    },
    {
      companyName: "Flipkart",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fflipkart.svg?alt=media&token=b1e02dbb-5e6c-454e-84d8-8c51997ad4d5`,
      experienceRequired: "2+ years",
      jobTitle: "Sr. Business Analyst",
    },
    {
      companyName: "Vmware",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fvmware.svg?alt=media&token=31c2929a-d960-4fda-8006-36ef18472054`,
      experienceRequired: "Fresher",
      jobTitle: "Member of Technical Staff, Software Engineering",
    },
    {
      companyName: "Walmart",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fwalmart.svg?alt=media&token=1bff3ad0-6caf-453a-8187-7b975f3ae7b0`,
      experienceRequired: "0 to 7+",
      jobTitle: "Software Engineer - 3",
    },
    {
      companyName: "Dataweave",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FDataWeave.svg?alt=media&token=fdee6199-4b97-4787-8e01-85e47fab6b63`,
      experienceRequired: "4 to 7 years",
      jobTitle: "Senior Data Engineer",
    },
    {
      companyName: "Goldman Sachs",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fgoldmansachs.svg?alt=media&token=e9944c12-7de7-4376-9017-dfcfdcd97a74`,
      experienceRequired: "0 to 2+ years",
      jobTitle: "Software Engineer",
    },
    {
      companyName: "Salesforce",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fsalesforce.svg?alt=media&token=d954e417-2acf-4cfe-9ff1-f8d17c5d76c2`,
      experienceRequired: "0 to 10+",
      jobTitle: "Software Developer Enginner",
    },
    {
      companyName: "Unacademy",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FUnacademy.svg?alt=media&token=f65781c8-fa58-4871-9db4-4e1c342e6946`,
      experienceRequired: "2+ years",
      jobTitle: "Lead Software Engineer, SRE",
    },
    {
      companyName: "Darwinbox",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fdarwinbox.png?alt=media&token=b53498e5-e465-429c-b304-30b0bc842b21`,
      experienceRequired: "1 to 5+ years",
      jobTitle: "Senior UI Engineer",
    },
    {
      companyName: "Lenskart",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Flenskart.svg?alt=media&token=ef6f5a80-7b2e-40c9-bc44-d9940b71d247`,
      experienceRequired: "2+ years",
      jobTitle: "Product Designer (UI/UX)",
    },
    {
      companyName: "Google",
      img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fgoogle.svg?alt=media&token=48d1f053-96f5-4b89-949d-5e71b090449c`,
      experienceRequired: "Students",
      jobTitle: "Summer 2023 Internship",
    },
  ];
  return (
    <>
      <Typography
        style={{ marginBottom: "30px", textAlign: `center`, fontSize: 24 }}
      >
        Placement
      </Typography>
      {/* <Alert severity="info">This is an info alert — check it out!</Alert> */}
      <Grid container spacing={2}>
          <Grid item xs = {6} md = {6} lg = {9}>
        {companyInfo.map((c, i) => (
            <PlacementCard
                companyName = {c.companyName}
                img = {c.img}
                experienceRequired = {c.experienceRequired}
                jobTitle = {c.jobTitle}
            />
            ))} 
          </Grid>
            <Grid item xs = {6} md = {6} lg= {3}>
              <PlacedStudentsList/>
            </Grid>
      </Grid>
    </>
  );
};

export default Placement;

