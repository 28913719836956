import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createBatch } from "../../../api/adminApi";
import { isAuthenticated, signout } from "../../../api/authApi";
import { toast, ToastContainer } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import axios from "../../../api/axios";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../notificationPages/Loading";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(modules, selectCourse, theme) {
  return {
    fontWeight:
      selectCourse.indexOf(modules) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const CreateBatch = () => {
  const theme = useTheme();
  const [value, setValue] = useState(new Date());
  const [batch, setBatch] = useState();
  const [start_date, setStartDate] = useState();
  const [meentingId, setMeetingId] = useState();
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState();
  const [selectCourse, setSelectCourse] = useState([]);
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("");
  };

  const handleChangeModule = (event) => {
    const {
      target: { value },
    } = event;
    setSelectCourse(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios.get("/admin/get_module_list", {}).then((res) => {
            if (res?.data?.success) setModules(res?.data?.response);
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  }, []);

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
  }));
  let startDate = formatDate(start_date);
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const date = formatDate(value);

    const response = createBatch({
      admin_uid: isAuthenticated()?.data?.localId,
      batch_name: batch,
      start_date: formatDate(start_date),
      meeting_id: meentingId,
      modules: selectCourse,
    })
      .then((res) => {
        if (res?.data?.success) {
          setLoading(false);
          toast.success("Batch added successfully", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setLoading(false);
          setTimeout(() => {
            setLoading(false);
          }, 200);
          toast.error(res?.data?.error, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => console.log("err"));
  };

  const today = new Date();
  return modules ? (
    <Container component="main" maxWidth="xs">
      <ToastContainer />
      <CssBaseline />
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Add New Batch
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="batch_name"
                label="Batch Name"
                name="batch_name"
                autoComplete="batch_name"
                value={batch}
                sx={{ mb: 2 }}
                onChange={(e) => setBatch(e.target.value)}
              />
              <TextField
                required
                fullWidth
                type="date"
                id="start_date"
                name="start_date"
                autoComplete="start_date"
                defaultValue={today}
                value={start_date}
                sx={{ mb: 2 }}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                required
                fullWidth
                id="meeting_id"
                label="Meeting Id"
                name="meeting_id"
                autoComplete="meeting_id"
                value={meentingId}
                onChange={(e) => setMeetingId(e.target.value)}
              />
              <div>
                <FormControl sx={{ width: "100%", mt: 2 }} required>
                  <InputLabel id="demo-multiple-chip-label">Course</InputLabel>
                  <Select
                    required
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectCourse}
                    onChange={handleChangeModule}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {modules.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, selectCourse, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <ColorButton
            type="submit"
            fullWidth
            variant="contained"
            loadingIndicator="Creating"
            loading={loading}
          >
            Create Batch
          </ColorButton>
        </Box>
      </Box>
    </Container>
  ) : (
    <Loading />
  );
};

export default CreateBatch;
