import React, { useEffect, useState } from "react";
import { Paper, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReferInfo from "./components/ReferInfo";
import ReferralCode from "./components/ReferralCode";
import RewardList from "./components/RewardList";
import axios from "../../../api/axios";
import Loading from "../../notificationPages/Loading";
import { isAuthenticated, signout } from "../../../api/authApi";
import ReactGa from "react-ga";

const ReferAndEarn = () => {
  const auth = isAuthenticated()?.data;
  const [referralCode, setReferralCode] = useState();
  const navigate = useNavigate();
  let isMounted = true;

  const earnRewards = () => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/get_user_details", {
              id_token: res?.data?.access_token,
            })
            .then((res) => {
              if (isMounted) {
                setReferralCode(res?.data?.response?.referral_code);
                localStorage.setItem(
                  "referral_code",
                  JSON.stringify(res?.data?.response?.referral_code)
                );
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
    try {
      if (
        localStorage.getItem("referral_code") &&
        localStorage.getItem("referral_code") !== undefined
      )
        setReferralCode(JSON.parse(localStorage.getItem("referral_code")));
      earnRewards();
    } catch {
      earnRewards();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  let obj = {
    referralCode: referralCode,
  };

  return referralCode ? (
    <>
      <Typography
        variant="h5"
        component="h5"
        sx={{ textAlign: "center", mb: 4, fontWeight: 600 }}
      >
        Refer & Earn
      </Typography>

      <Paper sx={{ p: 2, margin: "auto", flexGrow: 1 }} variant="outlined">
        <Grid container spacing={2}>
          <Grid item>
            <ReferInfo />
            <ReferralCode obj={obj} />

            <RewardList />
          </Grid>
          <Grid sx={{ mt: 20 }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Frefer%20and%20earn%2Frefer.svg?alt=media&token=3049e372-c5a2-463b-b072-e50680b0c1f1" />
          </Grid>
        </Grid>
      </Paper>
    </>
  ) : (
    <Loading />
  );
};

export default ReferAndEarn;
