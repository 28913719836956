import React from 'react'
import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
const InformationPanel = () => {
  return (
    <>
    <Box
    sx={{ 
      width: 341,
      minHeight : "100%",
      backgroundColor: '#E8F4F8',
      border: 1.5,
      borderColor :"#CDCDCD",
      mt : -8,
      pl : 2,
      pt : 2,
    }}

    >  
    <List>
        <ListItem>
            <HelpOutlineIcon sx= {{mr : "2px" }}/>
            <Typography sx = {{fontSize : "24px", color : "#6B7B8A"}}>Information Panel</Typography>
         </ListItem>
         <Divider sx = {{color : "#CDCDCD", ml : -1}}/>
    </List>

        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
           Target for mentees:
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2, fontSize : "16px"}}>
          2-3 problems per day is a good enough target.
        </Typography>

        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
        Motivation:
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2, fontSize : "16px"}}>
        Motivate the mentees that it is possible to achieve the target with consistency.
        </Typography>

        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
          Mentor session format:
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 3, fontSize : "16px"}}>
            a. First 10-15 min: Ask about mentee's performance and live classes.
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 3, fontSize : "16px"}}>
            b. Next 30-40 min: Mock interview (2 coding problems)
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 3, fontSize : "16px"}}>
            c. Next 10-15 min: Detailed feedback to mentees.
        </Typography>

        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
            Set action item:
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2, fontSize : "16px"}}>
        Set action item for mentees to be achieved before the next mentor sessions.
        </Typography>
        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
        Pointers on feedback:
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2, fontSize : "16px", mb : 4}}>
        Confidence, Time complexity analysis, Thinking process, Coding, Naming convention, Communication
        </Typography>
        

    </Box>
   
    </>
  )
}

export default InformationPanel