import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "../../../../api/axios";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axiosRetry from "axios-retry";
import { RETRIES } from "../../../../api/axios";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const VideoLink = () => {
  const [batch, setBatch] = useState();
  const [topic, setTopic] = useState();
  const [chapter, setChapter] = useState([]);
  const [videoLink, setVideoLink] = useState("https://player.vimeo.com/video/");
  const [successToast, setsuccessToast] = useState(false);
  const [errorToast, seterrorToast] = useState(false);
  const [allBatches, setAllBatches] = useState();
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();

  const handleToastClose = (e) => {
    setsuccessToast(false);
    seterrorToast(false);
  };
  useEffect(() => {

    axiosRetry(axios, {
      retries: RETRIES,
      shouldResetTimeout: true,
      retryCondition: (_error) => true, // retry no matter what
    });

    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios.post("/admin/get_all_chapters", {}).then((res) => {
            if (res?.data?.success) {
              setChapter(res?.data?.response?.topic);
              setAllBatches(res?.data?.response?.all_batches);
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  }, []);

  let obj = {
    batch: batch,
    chapter_name: topic,
    vimeo_link: ("https://player.vimeo.com/video/" + videoLink).trim(),
  };

  const sendResponse = (e) => {
    e.preventDefault();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios.post("/admin/add_vimeo_link", obj).then((res) => {
            if (res?.data?.success) {
              setsuccessToast(true);
            } else {
              seterrorToast(true);
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  return (
    <>
      <div>
        <Snackbar
          open={successToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Video Uploaded Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
            Add Video To Portal
          </Typography>

          <TextField
            select
            id="BATCH"
            label="Batch"
            type="text"
            onChange={(e) => setBatch(e.target.value)}
            value={batch}
            style={{ marginTop: "10px", width: "100%" }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> </InputAdornment>
              ),
            }}
          >
            {allBatches?.map((batch) => (
              <MenuItem value={batch} id="topic">
                {batch}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            id="Topic"
            type="text"
            label="Topic"
            value={topic}
            variant="outlined"
            onChange={(e) => setTopic(e.target.value)}
            style={{ marginTop: "10px", width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> </InputAdornment>
              ),
            }}
          >
            {chapter?.map((chapter) => (
              <MenuItem value={chapter} id="topic">
                {chapter}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="video_link"
            label="Video Link"
            type="number"
            onChange={(e) => setVideoLink(e.target.value)}
            value={videoLink}
            style={{ marginTop: "10px", width: "100%" }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={sendResponse}
          >
            Submit
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default VideoLink;
