import React, { Component, useState } from "react";
import { getBatch, getCourseTopic, isInstructor } from "../../../api/mentorApi";
import {
  TextField,
  MenuItem,
  Card,
  Container,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

class StartMeeting extends Component {
  state = {
    meetingCreated: false,
    batch: [],
    course: [],
    selected_batch: "",
    selected_topic: "",
    enable_textfield: false,
    name: "",
    meeting_id: "",
    error: false,
    error_msg: "",
    is_instructor: false,
    loading: false,
    modules: [],
    modules_name: "",
  };
  componentDidMount() {
    if (
      localStorage.getItem("is_instructor") &&
      localStorage.getItem("is_instructor") !== undefined
    ) {
      this.setState({
        is_instructor: JSON.parse(localStorage.getItem("is_instructor")),
      });
    }
    getBatch()
      .then((res) => this.setState({ batch: res.data.batches }))
      .catch((err) => console.log(err));
    getCourseTopic()
      .then((res) =>
      {
        this.setState({
          course : res?.data?.response
        })
  })

      .catch((err) => console.log(err));

    const auth = isAuthenticated()?.data;
    let data = {};
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          data = { id_token: res?.data?.id_token };
          axios.post("/mentors/is_instructor", data).then((res) => {
            if (res.data.success == true) {
              this.setState({
                is_instructor: res.data.is_instructor,
              });
              localStorage.setItem(
                "is_instructor",
                JSON.stringify(res.data.is_instructor)
              );
            } else {
              this.setState({
                error: true,
                error_msg: res.data.error,
              });
            }
          });
        } else {
          signout(auth?.localId, () => {});
        }
      });
  }

  onChangeBatch = (e) => {
    this.setState({ selected_batch: e.target.value });
  };
  onChangeTopic = (e) => {
    this.setState({ selected_topic: e.target.value });
    if (e.target.value == "others") {
      this.setState({ enable_textfield: true });
    } else {
      this.setState({ enable_textfield: false });
    }
  };
  onChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({ loading: true });
    const auth = isAuthenticated()?.data;
    let data = {
      batch: this.state.selected_batch,
      topic: this.state.selected_topic,
      name: this.state.name,
      module: this.state.modules_name,
    };
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));
          data = { ...data, id_token: res?.data?.id_token };
          axios.post("/mentors/create_meeting_v3", data).then((res) => {
            if (res.data.success == true) {
              this.setState({
                meeting_id: res.data.meeting_id,
                meetingCreated: true,
                error: false,
                loading: false,
              });
            } else {
              this.setState({
                error: true,
                error_msg: res.data.error,
              });
            }
          });
        } else {
          signout(auth?.localId, () => {});
        }
      });
    // this.setState({loading: false})
  };
  render() {
    const ColorButton = styled(LoadingButton)(({ theme }) => ({
      backgroundColor: "#1d99be",
      "&:hover": {
        backgroundColor: "#1d8cbe",
      },
      display: "flex",
      justifyContent: "center",
    }));
    return this.state.is_instructor ? (
      <div>
        <Container
          style={{ alignItems: "center" }}
          onSubmit={this.handleSubmit}
          maxWidth="sm"
          sx={{ mt: 5 }}
        >
          {this.state.error ? (
            <div style={{ color: "red", margin: "10px 15px" }}>
              {this.state.error_msg}
            </div>
          ) : null}
          <Typography variant="h5" align="center">
            Start Live Class
          </Typography>
          <TextField
            id="selected_batch"
            select
            label="Select Batch"
            value={this.state.selected_batch}
            style={{ margin: "10px 15px" }}
            fullWidth
            onChange={this.onChangeBatch}
          >
            {this.state.batch.map((row) => (
              <MenuItem value={row}> {row} </MenuItem>
            ))}
          </TextField>

          <TextField
            id="selected_batch"
            select
            label="Select Course"
            value={this.state.modules_name}
            style={{ margin: "10px 15px" }}
            fullWidth
            onChange={(e) => this.setState({ modules_name: e.target.value })}
          >
            {this.state.course?.map((row) => (
              <MenuItem value={row.course}> {row.course} </MenuItem>
            ))}
          </TextField>
          <TextField
            id="selected_topic"
            select
            label="Select Topic"
            // helperText="Please select the topic for Zoom meeting"
            value={this.state.selected_topic}
            style={{ margin: "10px 15px" }}
            fullWidth
            onChange={this.onChangeTopic}
          >
            {this.state?.course?.filter((module)=>this.state.modules_name=== module.course)[0]?.topic
            .map((row) => {
              return (
                <MenuItem value={row}> {row}</MenuItem>
                 
              )
            })}
            <MenuItem value={"others"}> others </MenuItem>
          </TextField>
          {this.state.enable_textfield ? (
            <TextField
              required
              id="name"
              label="Specify the Topic"
              type="name"
              onChange={this.onChange}
              value={this.state.name}
              fullWidth
              style={{ margin: "10px 15px" }}
            />
          ) : null}

          <ColorButton
            onClick={this.handleSubmit}
            variant="contained"
            loadingIndicator="submitting…"
            loading={this.state.loading}
            sx={{ marginLeft: "30%", width: "50%", mt: 2 }}
          >
            Submit
          </ColorButton>
        </Container>
        {this.state.meetingCreated ? (
          <Card
            style={{
              backgroundColor: "rgb(144,238,144)",
              margin: "100px 10px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <h2> Your meeting has been created </h2>
            Join the link to start the meeting :{" "}
            <a href={this.state.meeting_id} target="_blank">
              {" "}
              {this.state.meeting_id}{" "}
            </a>
          </Card>
        ) : null}
      </div>
    ) : (
      <Box sx={{ mt: 10 }} align="center">
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          Locked !
        </Typography>{" "}
        This page is only for instructors.
      </Box>
    );
  }
}

export default StartMeeting;
