import React, { useEffect, useState } from "react";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import MenteeInfo from "./component/MenteeInfo";
import { Typography, TextField, Grid } from "@mui/material";
import Loading from "../../notificationPages/Loading";
import { Divider } from "@mui/material";
const MyMentee = () => {
  const [mentees, setMentees] = useState();
  const [searchBatch, setSearchBatch] = useState();
  const auth = isAuthenticated()?.data;

  const navigate = useNavigate();

  function searchingfor(searchBatch) {
    return function (x) {
      return (
        x.batch?.toLowerCase().includes(searchBatch?.toLowerCase()) ||
        !searchBatch
      );
    };
  }

  let onSearchInputChange = (event) => {
    if (event.target.value) {
      setSearchBatch(event.target.value);
    } else {
      setSearchBatch("");
    }
  };

  useEffect(() => {
    let isMounted = true;
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/mentors/get_mentee_details", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (isMounted) {
                setMentees(res?.data?.response?.mentees);
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return mentees ? (
    <>
      <Typography
        variant="h5"
        component="h5"
        sx={{ textAlign: "center", mb: 2, fontWeight: 600 }}
      >
        My Mentee
      </Typography>
      <TextField
        sx={{ float: "right", mb : 2, mr : 2 }}
        id="outlined-search"
        label="Search Batch"
        type="text"
        value={searchBatch}
        onChange={onSearchInputChange}
      />
      {/* <Divider sx={{}}></Divider>      */}
      <Grid container spacing={2}>
        {mentees?.filter(searchingfor(searchBatch))?.map((c, i) => (
          <Grid key={c.email} xs={12} md={6} lg={4} spacing={4}>
            {" "}
            <MenteeInfo
              action_items={c.action_items}
              avg_leetcode_count={c.avg_leetcode_count}
              date={c.date}
              email={c.email}
              last_leetcode_count={c.last_leetcode_count}
              name={c.name}
              batch={c.batch}
              phone={c.phone}
            />
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <Loading />
  );
};

export default MyMentee;
