import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  Grid,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";
import Chip from "@mui/material/Chip";
import { Colors } from "../../../../assets/Colors";
import Moment from "react-moment";
import Link from '@mui/material/Link';
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const ImgLocked = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  opacity: "50%",
});

const UpcomingClassCard = (props) => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const { date, zoom_link, id, is_completed, is_locked, topic, module_name } = props;
  const text = "";

  const [isCompleted, setIsCompleted] = useState(is_completed);
  const [disableButton, setDisableButton] = useState(true);

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
    right: 70,
  }));

  let todays_date = new Date().toISOString().slice(0, 10);
  let d = new Date();
  let day = d.getDay();
  let hours = d.getUTCHours();
  let minutes = d.getUTCMinutes() / 100;
  let time = hours + minutes;

  const unlockChapter = (e) => {
    e.preventDefault();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          let obj = {
            id_token: res?.data?.id_token,
            module_name: module_name,
            chapter_id: id,
          };
          console.log(obj)
          axios.post("/student/mark_student_attendance_v3", obj).then((res) => { });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };
  // console.log(todays_date, "Todays date")
  // console.log(date, "lecture date")
  useEffect(() => {
    if ((time > 15.0 && time < 15.4) && todays_date === date) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  });

  return (
    <div className={!is_locked ? "course-card-container" : "course"}>

      <Paper
        sx={{
          p: 2,
          margin: "auto",
          flexGrow: 1,
          mb: 4,
          bgcolor: Colors.secoundaryLite,
          border: 1,
          borderColor: Colors.secoundaryBackgorund,
        }}
        variant="outlined"
      >
        <Grid container spacing={2}>
          <Grid item>
            {/* <ButtonBase sx={{ width: 303, height: 193 }} disabled={true}>
              {!is_locked && <Img alt="complex" src={image_link} />}
              {is_locked && <ImgLocked alt="complex" src={image_link} />}
            </ButtonBase> */}
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ fontSize: 20, fontWeight: 500 }}
                >
                  {/* Upcoming Class */}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ fontSize: 20, fontWeight: 500 }}
                >
                  Upcoming Class : {topic}
                </Typography>

                <Typography
                  paragraph={true}
                  gutterBottom
                  sx={{ fontSize: 16, mt: 1 }}
                >
                  {date ? (
                    <Chip
                      label={<Moment format="MMMM Do YYYY">{date}</Moment>}
                      color="primary"
                    />
                  ) : (
                    <div></div>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <ColorButton
              variant="contained"
              sx={{ boxShadow: 5, pl: 4, pr: 4, mt: 2, borderRadius: "20px" }}
              disabled={disableButton}
              onClick={() => unlockChapter()}
            >
              <a
                href={zoom_link}
                target="_blank"
                style={{ color: "white", textDecoration: "none" }}
              >
                Join Class
              </a>
            </ColorButton>
          </Grid>
        </Grid>
      </Paper>
      {/* </Link> */}
    </div>
  );
};

export default UpcomingClassCard;
