import React, { useEffect, useState } from "react";
import { ScheduleMeeting } from "react-schedule-meeting";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";
import { useNavigate } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import InformationPanel from "./InformationPanel";
import MentorAssignAlert from "./MentorAssignAlert";
import { LoadingButton } from "@mui/lab";
import { toast, ToastContainer } from "react-toastify";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ReactGa from "react-ga";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const TimeSlot = () => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const [response, setResponse] = useState();

  const [open, setOpen] = useState(false);
  const [permission, setPermission] = useState(true);
  const [date, setDate] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [loading, setLoading] = useState(false);
  const [successToast, setsuccessToast] = useState(false);
  const [errorToast, seterrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
  }));

  const get_mentor_availability = () => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/get_mentor_availability", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (res?.data.code == 8) {
                setPermission(false);
              } else {
                setPermission(true);
                setResponse(res?.data.response);
                setErrorMessage(res?.data.errors);
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
    let isMounted = true;
    get_mentor_availability();
    return () => {
      isMounted = false;
    };
  }, []);
  const availableTimeslots = [];

  for (let i in response) {
    availableTimeslots.push({
      startTime: new Date(response[i].date).setHours(
        parseInt(response[i].start),
        0,
        0,
        0
      ),
      endTime: new Date(response[i].date).setHours(
        parseInt(response[i].end),
        0,
        0,
        0
      ),
    });
  }
  const _onStartTimeSelect = (startTimeEvent: StartTimeEvent) => {
    setOpen(true);
    let date = moment
      .unix(startTimeEvent.availableTimeslot.startTime / 1000)
      .format("YYYY-MM-DD");
    let start = parseInt(
      moment
        .unix(startTimeEvent.availableTimeslot.startTime / 1000)
        .format("HH")
    ).toString();
    let numEnd = parseInt(start) + 1;
    let end = numEnd.toString();
    setDate(date);
    setStart(start);
    setEnd(end);
  };

  const handleToastClose = (e) => {
    setsuccessToast(false);
    seterrorToast(false);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleConfirm = (e) => {
    setLoading(true);
    let sessionBooking = {
      id_token: isAuthenticated()?.data?.idToken,
      date: date,
      start: start,
      end: end,
    };
    let isMounted = true;
    e.preventDefault();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/session_booking", sessionBooking, {
              setTimeout: 5000,
            })

            .then((res) => {
              if (res?.data?.response === true) {
                setsuccessToast(true);
              } else {
                seterrorToast(true);
              }
              setOpen(false);
              get_mentor_availability();
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
    return () => {
      isMounted = false;
    };
  };

  return permission ? (
    <>
      {errorMessage ? (
        <div align="left">
          <Alert
            sx={{
              width: "max-content",
              mb: 2,
              textAlign: "center",
            }}
            severity="info"
          >
            {errorMessage}
          </Alert>
        </div>
      ) : (
        <div></div>
      )}

      <div>
        <ToastContainer />
        <div>
          <Snackbar
            open={successToast}
            autoHideDuration={3000}
            onClose={handleToastClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
                Session  Booked Successfully !
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorToast}
            autoHideDuration={3000}
            onClose={handleToastClose}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              Some Error Occured!
            </Alert>
          </Snackbar>
        </div>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              Are you sure ?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please Confirm the timeslot for your upcoming mentor session.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={handleClose}
                sx={{ mr: 2, mb: 1, height: 36 }}
              >
                Cancel
              </Button>
              <ColorButton
                onClick={handleConfirm}
                variant="contained"
                loadingPosition="center"
                loading={loading}
                sx={{ mr: 4, mb: 1 }}
              >
                Confirm
              </ColorButton>
            </DialogActions>
          </Dialog>
        </div>

        <Typography
          variant="h5"
          component="h5"
          sx={{ textAlign: "center", mb: 4, fontWeight: 600, mr: 40 }}
        >
          Book Mentor Session
        </Typography>

        <Grid container columns={16} sx={{ alignItems: "stretch" }} spacing={4}>
          <Grid item xs={12} sx={{ ml: -4 }}>
            <div className="App">
              <ScheduleMeeting
                borderRadius={10}
                primaryColor="#1D99BE"
                eventDurationInMinutes={60}
                availableTimeslots={availableTimeslots}
                onStartTimeSelect={_onStartTimeSelect}
              />
            </div>
          </Grid>
          <Grid item xs={4} sx={{ minHeight: "100%" }}>
            <InformationPanel />
          </Grid>
        </Grid>
      </div>
    </>
  ) : (
    <MentorAssignAlert />
  );
};

export default TimeSlot;
