import React from "react";
import {
  Paper,
  Typography,
  Grid,
  styled,
  Link,
} from "@mui/material";
import Container from "@mui/material/Container";
import {Colors} from "../../../../assets/Colors"
import ReactGA from "react-ga"
const Img = styled("img")({
  marginTop: 40,
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


const eventTrack = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })
}


const ProcessPage5 = () => {
  return (
    <>
      <Grid container spacing={4} sx={{ mt: "10px !important" }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ backgroundColor: Colors.primaryLite , pt: 2, height: "400px !important" }}>
            <Typography sx={{ fontSize : "26px !important" }}>Final Step</Typography>
            <div style={{ paddingLeft: "90px" }}>
              <Img src= "https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FPaymentProcess5.svg?alt=media&token=e656b891-66e6-4b88-9c2f-1c01ade42e46" alt="" sx={{ mb: 1 }} />
            </div>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item
            sx={{
              backgroundColor: Colors.primaryLite,
              pt: "16px !important",
              height: "400px !important",
              
            }}
          >
            <Typography sx={{ fontSize : "26px !important" }}>You are all Set</Typography>
            <Container sx={{}}>
              <div style={{ justifyContent : "center", alignItems : "center", display : "flex" }}>
                <Img src= "https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2Fcheckicon.svg?alt=media&token=34b81426-065e-433b-b62b-97cea88ffffa" alt="checkicon" sx={{ mb: 1 }} />
              </div>
              <Typography sx={{ mt: "20px !important", fontWeight: 700 }}>
                Thanks a lot.
              </Typography>
              <Typography sx={{ mt: "8px !important"  }}>
                At this stage you have successfully enrolled and can
              </Typography>
              <Typography>now attend classes </Typography>
              <Typography sx={{ mt: "8px !important" }}>
                Questions? Suggestions? Queries?
              </Typography>

              <Link href="mailto:onbording@bosscoderacademy.com" target = "_blank" sx={{ fontWeight: 700 }} onClick={eventTrack.bind(this, "Link", "Onbording mail Link", "Final Step Payment")}>
                Reach out here
              </Link>
            </Container>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default ProcessPage5;
