import {
  Typography,
  List,
  ListItem,
  styled,
  ListItemText,
  Paper,
  Grid,
} from "@mui/material";
import Container from "@mui/material/Container";
import React from "react";
import {Colors} from "../../../../assets/Colors"
const placedStudents = [
  {
    name: "Rahul Verma",
    company: "Google",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fgoogle.svg?alt=media&token=48d1f053-96f5-4b89-949d-5e71b090449c`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5103AQGr78GoBheJ5g/profile-displayphoto-shrink_800_800/0/1532104823987?e=1666224000&v=beta&t=cGlJS0fWxwpP3khtTiC9wENAqyA-Qw5QY9x42Y9h578`
  },
  {
    name: "Lavanya",
    company: "Meta",
    img: `https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Meta_Platforms_Inc._logo.svg/320px-Meta_Platforms_Inc._logo.svg.png`,
    avatar_img : `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com.svg?alt=media&token=053a4992-ec66-4d3b-a842-1c2387f98000`
  },
  {
    name: "Irshad",
    company: "ByteDance",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fbytedance.svg?alt=media&token=2b19c536-be82-4d0a-86b6-0b98eb5005a8 `,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQE3ebZCw9Zn8A/profile-displayphoto-shrink_800_800/0/1638525061250?e=1666224000&v=beta&t=s8IgLuBuzE00GPw38i7ZraHsX3r8-AE8mt1x2_rV5xw`
  },
  {
    name: "Arun Kumar",
    company: "Myntra",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FMyntra.svg?alt=media&token=049f7578-e423-40a8-aa7a-344acc654062`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4E03AQFqStP3YozvcA/profile-displayphoto-shrink_400_400/0/1599419425531?e=1666224000&v=beta&t=Ehv5qKfeNzQKRNDV3U573ZxEqwtDC7-1dATPWpxTnII`
  },
  {
    name: "Ishika Raj",
    company: "American Express",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FAmerican_Express.svg?alt=media&token=b215b8c5-ca35-4eca-9027-691621cdb720`,
    avatar_img : `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com.svg?alt=media&token=053a4992-ec66-4d3b-a842-1c2387f98000`
  },
  {
    name: "Arushi Jain",
    company: "Atlassian",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FAltassian.svg?alt=media&token=cb307eeb-5285-4350-9188-57de81fb07d7`,
    avatar_img  : `https://media-exp1.licdn.com/dms/image/C5603AQEqJGoIsl_fYw/profile-displayphoto-shrink_800_800/0/1649830458811?e=1666224000&v=beta&t=mmL0BvBGQreg3fpcarPVMcABmaSYtErN_twiuP98sK0`
  },
  {
    name: "Jenard",
    company: "Microsoft",
    img: `https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQEf1rDmqrzSUQ/profile-displayphoto-shrink_800_800/0/1599747255985?e=1666224000&v=beta&t=345X4rIkGehFgroMgSGkc61fGqWj_6deZpPgzRmzQmE`
  },
  {
    name: "Ankur Raj",
    company: "Amazon",
    img: `https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4E03AQEQEvNNQdD0QQ/profile-displayphoto-shrink_800_800/0/1638181213020?e=1666224000&v=beta&t=4jmztQzXy2hAQtYOhyPvOPbrtEbujxl5Y2JMtWdNFsY`
  },
  {
    name: "Yash Kadtan",
    company: "Arcesium",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FArcesium.png?alt=media&token=3713e615-03f2-425c-83b9-2847f8e372b4`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQEoWcQphZ6oDA/profile-displayphoto-shrink_400_400/0/1645764011151?e=1666224000&v=beta&t=RZqrm6er-lsl3Y040jln8wCQOnK_STXBevf9RTunoBI`
  },
  {
    name: "Subharoop",
    company: "Hyland",
    img: `https://upload.wikimedia.org/wikipedia/commons/4/41/Hyland%27s_Naturals_Logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQENYsupKRfbww/profile-displayphoto-shrink_800_800/0/1657307610429?e=1666224000&v=beta&t=t6SnUKN6cOaCFumnHecjTEPr2wlIh7eS77AFVrD1FKE`
  },
  {
    name: "Arya Shekhar",
    company: "Arcesium",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FArcesium.png?alt=media&token=3713e615-03f2-425c-83b9-2847f8e372b4`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/D4D35AQEkNFNhcZhXHA/profile-framedphoto-shrink_400_400/0/1654018108737?e=1662462000&v=beta&t=_weEYTi1rNq1k_7j8sSyYpoTpoGm0L6jH0bLKYj3WFQ`
  },
  {
    name: "Anu",
    company: "Cleartrip",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fcleartrip.svg?alt=media&token=6c2d74dc-1c89-450d-a40e-e9c13df6a20d`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQFs6Se8Y-WNwg/profile-displayphoto-shrink_800_800/0/1646297491667?e=1666224000&v=beta&t=FbdB2gVGZL3hbI-zb4zRrnTtVeLThcdrZOlZ8ivLbi0`
  },
  {
    name: "Niraj",
    company: "IQVIA",
    img: `https://upload.wikimedia.org/wikipedia/commons/3/34/Iqvia-logo-color.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQFmoc2SvVPWIw/profile-displayphoto-shrink_400_400/0/1601319159693?e=1666224000&v=beta&t=6IBnH1QUnDxm21-eIOcHK98c5zhzLp_A3Wp3jxyKwpY`
  },
  {
      name: "Vathsav Adapa",
    company: "App Dynamics",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fappdynamics-ar21.svg?alt=media&token=db3c3361-fbf6-465f-9a01-0ce4fd2ebbb3`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQF4qBAYpRjwlA/profile-displayphoto-shrink_400_400/0/1608924737631?e=1667433600&v=beta&t=1dTVyrNSjm86qqwWZKTnHxzJrdfGA4wkyjma7_91tiE`
  },
  {
    name: "Manas Punj",
    company: "Oracle",
    img: `//upload.wikimedia.org/wikipedia/commons/5/50/Oracle_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQEjr6A7M-Sm0A/profile-displayphoto-shrink_800_800/0/1658158706421?e=1666224000&v=beta&t=_RkR0-BZLURejH2GkeYYtBHY7_03esn1nye0TKtap10`
  },
  {
    name: "ASP Manideep",
    company: "smartcoin",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fsmart%20coin.png?alt=media&token=00b3a9dc-c835-4e23-bf9e-1e3dfea7be73`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQGG4WLRovP2RQ/profile-displayphoto-shrink_800_800/0/1655447927418?e=1666224000&v=beta&t=64OFeHDTLGJwEcaUwV8FsuWanRd7EOCoraNXqcJfduw`
  },
  {
    name: "Dheeraj Barik",
    company: "Amazon",
    img: `https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQGp_U-lCA6WqA/profile-displayphoto-shrink_800_800/0/1653504806713?e=1666224000&v=beta&t=esjstBQffOd8A6pBaxjZ8qVX3F2shAtwN6BkAGreSxg`
  },
  {
    name: "Rohal Kurup",
    company: "Dassult System",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fdassault-systemes-logo.svg?alt=media&token=8a673c63-7045-4379-9d0c-ee2619e35635`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQER1gXVHakXUg/profile-displayphoto-shrink_400_400/0/1660705343788?e=1666224000&v=beta&t=E87iSWSRMi8c6Y99i8ffi_LUrhPzib-uLNpWEdehIcs`
  },
  {
    name: "Pranab Sarkar",
    company: "Amazon",
    img: `https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQFYY8BQXD9QfQ/profile-displayphoto-shrink_400_400/0/1622445557623?e=1666224000&v=beta&t=WS6VbCn5wFGboT2mda7ovtFpz9pX57I_KO0ssb3IQSI`
  },
  {
    name: "Ashish Johnson",
    company: "Blackstraw",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fblackstraw-logo.png?alt=media&token=b93bb424-69aa-413d-bf5c-547441339fff`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4E03AQHDrXvzGLbBFA/profile-displayphoto-shrink_800_800/0/1627318186824?e=1666224000&v=beta&t=jCwEVwBmuzeLJGi7exSrfu627Ph2JIK9njDAloS3RAQ`
  },
  {
    name: "Naman Jain",
    company: "Philips",
    img: `https://upload.wikimedia.org/wikipedia/commons/5/52/Philips_logo_new.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQE0Dps_ZHEScQ/profile-displayphoto-shrink_800_800/0/1517341302961?e=1666224000&v=beta&t=0NMXBkYk06L610DuSR5mM-kaWxqCQUy-jDJut_QV8Kk`
  },
  {
    name: "Siva Kumar",
    company: "Walmart",
    img: `https://upload.wikimedia.org/wikipedia/commons/c/ca/Walmart_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQEJWSow5JMF4g/profile-displayphoto-shrink_800_800/0/1632811401834?e=1666224000&v=beta&t=4bZfzQ0s7LqhNJPy4QKp93GWv7BwPIXXBLo3C4nqed8`
  },
  {
    name: "Vamshi Keshav",
    company: "BlogVault",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fblog-valut.png?alt=media&token=e55d3cff-35b6-4ba7-b67d-67fffa325e49`,
    avatar_img : `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com%20(1).svg?alt=media&token=78d21828-cf37-40f6-b7e2-82d52e60e45f`
  },
  {
    name: "Vishwajeet",
    company: "Akridata",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fakridata.png?alt=media&token=bde4fec1-eac4-429d-ab4d-5184a00fb8bc`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4E03AQGnYLpH1Yt-iw/profile-displayphoto-shrink_800_800/0/1630563396148?e=1666224000&v=beta&t=qD3Yb1EIiZDF2CGtSpQOhyq4FuQ9JbqjOlpjyu3nGmU`
  },
  {
    name: "Sowmiya",
    company: "Swiggy",
    img: `https://upload.wikimedia.org/wikipedia/en/thumb/1/12/Swiggy_logo.svg/204px-Swiggy_logo.svg.png`,
    avatar_img : `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com.svg?alt=media&token=053a4992-ec66-4d3b-a842-1c2387f98000`
  },
  {
    name: "Mohit Sharma",
    company: "Adobe",
    img: `https://upload.wikimedia.org/wikipedia/commons/6/6e/Adobe_Corporate_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQGuKMzx0xf-wQ/profile-displayphoto-shrink_800_800/0/1517061437703?e=1666224000&v=beta&t=5DANQwFKm68ZbwGFZ8y2L9R6esIa76NE8EozzIATUi8`
  },
  {
    name: "Tripti Kumari",
    company: "Amazon",
    img: `https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQEzvpi6u3dReg/profile-displayphoto-shrink_400_400/0/1641393313139?e=1666224000&v=beta&t=mAQ3c4ZggW1YPmE9drK4xKvswxruHCEMkT7c4Y1FNRU`
  },
  {
    name: "Ramanathan",
    company: "Flipkart",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fflipkart.svg?alt=media&token=b1e02dbb-5e6c-454e-84d8-8c51997ad4d5`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4E03AQFDZk6Y8sHnKQ/profile-displayphoto-shrink_800_800/0/1638373202547?e=1666224000&v=beta&t=HfvW3raKWe_kI8vV1Ku6e4W6iQH1RPWHzlob1lRXJ8Q`
  },
  {
    name: "Preveen",
    company: "Lenskart",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Flenskart.svg?alt=media&token=ef6f5a80-7b2e-40c9-bc44-d9940b71d247`,
    avatar_img : `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com%20(1).svg?alt=media&token=78d21828-cf37-40f6-b7e2-82d52e60e45f`
  },
  {
    name: "Ujesh",
    company: "Google",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fgoogle.svg?alt=media&token=48d1f053-96f5-4b89-949d-5e71b090449c`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQG1-qZ64vtIPQ/profile-displayphoto-shrink_400_400/0/1659457179811?e=1666224000&v=beta&t=k54CDP6lzLUT0P0QhZ_C1CAFMDK6mlrRORYvlY4eIq8`
  },
  {
    name: "Agniva",
    company: "Cisco Appdynamics",
    img: `https://upload.wikimedia.org/wikipedia/commons/6/64/Cisco_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQHKhp2jZcMjsQ/profile-displayphoto-shrink_800_800/0/1650704122027?e=1666224000&v=beta&t=f0rjJ6nGebwfp1emEtsIdA4C7XCg7r4KeiglAViLQTk`
  },
  {
    name: "Saihemanth",
    company: "Microsoft",
    img: `https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg`,
    avatar_img : `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com%20(1).svg?alt=media&token=78d21828-cf37-40f6-b7e2-82d52e60e45f`
  },
  {
    name: "Chaitanya Naik",
    company: "Mavenir",
    img: `https://upload.wikimedia.org/wikipedia/commons/2/22/Mavenir_New_Logo.png`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQHPWkpfnBCw_w/profile-displayphoto-shrink_400_400/0/1618551394079?e=1666224000&v=beta&t=3QERbzK7uYU9dwkbVwN4VfUMOOV_a2YuM-OPgj6nOeM`
  },
  {
    name: "Vritika",
    company: "ServiceNow",
    img: `https://upload.wikimedia.org/wikipedia/commons/5/57/ServiceNow_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4E03AQECMxE7vZH6qA/profile-displayphoto-shrink_400_400/0/1648571594108?e=1666224000&v=beta&t=ehofAThxCS6GS23lyn3hVTDsqaaGq9-pMkhqh9V2eoc`
  },
  {
    name: "Deepak ",
    company: "Paysafe",
    img: `https://upload.wikimedia.org/wikipedia/commons/9/9b/Paysafecard_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQHcVO5NZBYK3g/profile-displayphoto-shrink_800_800/0/1591013006213?e=1666224000&v=beta&t=J0McT-8rVTHYkxAsGttsHLtxEPId0W5xgfIgp0QV3tA`
  },
  {
    name: "Lakshmi Susmitha",
    company: "JP Morgan",
    img: `https://upload.wikimedia.org/wikipedia/commons/a/af/J_P_Morgan_Logo_2008_1.svg`,
    avatar_img : `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com.svg?alt=media&token=053a4992-ec66-4d3b-a842-1c2387f98000`
  },

  {
    name: "Harsh Namdev ",
    company: "Livspace",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Flivspace.png?alt=media&token=a9a12fb9-83a2-4f32-9468-e78622c9dcea`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQEchgWrWl_u8A/profile-displayphoto-shrink_400_400/0/1653058680121?e=1666224000&v=beta&t=QopaQFuKmuRj7FyGK1e-qbjCF4xClqgXZ4kW3KapAS0`
  },
  {
    name: "Rakesh Satapathy",
    company: "Livspace",
    img: `https://upload.wikimedia.org/wikipedia/commons/5/56/Deloitte.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQGP_u9miJ6bDQ/profile-displayphoto-shrink_400_400/0/1647931079374?e=1666224000&v=beta&t=q0vpJjCUjeZrFp74v5I8zBud50a0PTyfu3FYB5UJSko`
  },
  {
    name: "Ritesh",
    company: "Salesforce",
    img: `https://upload.wikimedia.org/wikipedia/commons/f/f9/Salesforce.com_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4E03AQHQprpQTPN5Lw/profile-displayphoto-shrink_400_400/0/1592565188421?e=1666224000&v=beta&t=4pLYPKG3l-tZQcDBQ--Sg7IAFI2S6B03Udp9z5du6O4`
  },
  {
    name: "Vivek Kumar",
    company: "Hyland",
    img: `https://upload.wikimedia.org/wikipedia/commons/4/41/Hyland%27s_Naturals_Logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQEvZlINy86BZQ/profile-displayphoto-shrink_400_400/0/1644345351381?e=1666224000&v=beta&t=Z-JFKYPOgQ2x843ZiFsKx0zK3h_WwgLVkKKCNz5uc8g`
  },
  {
    name: "Shivan",
    company: "Hyland",
    img: `https://upload.wikimedia.org/wikipedia/commons/4/41/Hyland%27s_Naturals_Logo.svg`,
    avatar_img : `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com%20(1).svg?alt=media&token=78d21828-cf37-40f6-b7e2-82d52e60e45f`
  },
  {
    name: "Swammy",
    company: "Muvi.com",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fmuvi.png?alt=media&token=4aa46876-eab7-4fb3-925d-f653b5ea2b27`,
    avatar_img : `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com%20(1).svg?alt=media&token=78d21828-cf37-40f6-b7e2-82d52e60e45f`
  },
  {
    name: "Kapil Nagar",
    company: "KreditBee",
    img: `https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fkreditbee.png?alt=media&token=bfce6ee8-d79e-495b-b568-5cac2d88ba07`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQEdN90NgC1vAA/profile-displayphoto-shrink_400_400/0/1660220051916?e=1666224000&v=beta&t=an0pgYXJdlzzTleTwL2FPvnju1t7P1ZI7iKf6oGb8ds`
  },
  {
    name: "Shaik Avais",
    company: "Amazon",
    img: `https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQHPhb9_Xase-A/profile-displayphoto-shrink_800_800/0/1641627240295?e=1666224000&v=beta&t=0KWpcDEeCn56Ug6QqLEVgljpL95h6QEUtcZp-EaJ1D8`
  },
  {
    name: "Shanu garg",
    company: "Analog Devices",
    img: `https://upload.wikimedia.org/wikipedia/commons/8/86/Analog_Devices_Logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQHeJHrpR1At1A/profile-displayphoto-shrink_400_400/0/1654667508862?e=1666224000&v=beta&t=PGQAgt6ZVPRW1QVY6ikqYp4TXpPq6mDl54NVRbD0qNA`
  },
  {
    name: "Sarveshwar Neogi",
    company: "Sprinklr",
    img: `https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Sprinklr_Brand_Logo.png/800px-Sprinklr_Brand_Logo.png`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQHBapV1lzCiUQ/profile-displayphoto-shrink_800_800/0/1646660885890?e=1666224000&v=beta&t=9w-w1adPNyCnpi49P_C6Yl-r1S-NcKbv5KiexO4dFBs`
  },
  {
    name: "Ela Bhattacharya",
    company: "Oracle",
    img: `//upload.wikimedia.org/wikipedia/commons/5/50/Oracle_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C5603AQG0eIi4hh-o0g/profile-displayphoto-shrink_400_400/0/1643608245073?e=1666224000&v=beta&t=IbifJAK9C2qlshGV_4HOUfjMkm7GXZubd0swTFHHoRg`
  },
  {
    name: "Naitik Doshi",
    company: "Amazon",
    img: `https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg`,
    avatar_img : `https://media-exp1.licdn.com/dms/image/C4D03AQHZp2qdtBb_lw/profile-displayphoto-shrink_400_400/0/1641912477200?e=1666224000&v=beta&t=pKaFUwmY8V2bNrEBDR2BGHpOl9T6MWyF-AXn8FYIIf4`
  },
 
 
];
const PlacedStudentsList = () => {
  const Img = styled("img")({
    // margin: "auto"
    display: "block",
    maxWidth: "30%",
    maxHeight: "50%",
  });

  const Avatar = styled("img")({
    display: "block",
    maxWidth: "80%",
    maxHeight: "100%",
    borderRadius: "50%",
    marginright : 2
  });
  const shuffled = placedStudents.sort(() => 0.5 - Math.random());
  let selected = shuffled.slice(0, 50);
  return (
    <>
        <Container sx={{ backgroundColor : Colors.primaryLite }}>
          <Typography sx={{ fontSize: 30, pt : 2 }}>Recent Top 50 Offers(live)</Typography>
          <List>
            {selected.map((c, i) => (
              <Paper sx={{ mb: 2, height: 80 }}>
                <Grid container>
              <Grid xs = {9} md = {9} lg = {9}>
                <ListItem>
                  <ListItemText>{c.name}</ListItemText>
                </ListItem>
                <ListItem sx={{ mt: -2 }}>
                  <Img src={c.img} alt="" />
                </ListItem>
              </Grid>

                <Grid  xs = {3} md = {3} lg = {3}>
                 <Avatar style = {{marginTop : "16px"}} src={c.avatar_img} alt="" />
                </Grid>
            </Grid>
              </Paper>
            ))}
          </List>
        </Container>
    </>
  );
};

export default PlacedStudentsList;
