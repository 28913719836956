import React from "react";
import { Box,} from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";
function PrerecordedSession({ prerecorded_video_link }) {
  return (
    <>
      <Box sx={{ p: 2 }}>
      {prerecorded_video_link?.map((c, i) => (
        <Vimeo video={c} 
        speed
        autopause
        responsive />
      ))}
      </Box>   
    </>
  );
}

export default PrerecordedSession;
