import React, { useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  styled,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {Colors} from "../../../../assets/Colors"
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ReactGA from "react-ga"
const Img = styled("img")({
  marginTop: 40,
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const ProcessPage4 = () => {
  // For Radio button
  const [radio, setRadio] = useState();
  const controlProps = (item) => ({
    checked: radio === item,
    onChange: handleRadioChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const handleRadioChange = (event) => {
    setRadio(event.target.value);
  };
  
  //For accordian
  const [expanded, setExpanded] = React.useState();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary {...props} />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125) !important",
  }));

  const eventTrack = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  return (
    <>
      <Grid container spacing={4} sx={{ mt: "10px !important" }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ backgroundColor: Colors.primaryLite, pt: 2, height: "445px !important" }}>
            <Typography sx={{ fontSize : "26px !important" }}>Step - 3</Typography>
            <div style={{ paddingLeft: "50px" }}>
              <Img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FProcess4.svg?alt=media&token=2c023ab0-95d1-40c1-ae2a-90c1eefcbb0b" alt="" sx={{ mb: 1 }} />
            </div>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ backgroundColor: Colors.primaryLite, pt: "16px !important", width : "500px !important"}}>
            <Typography sx={{ fontSize : "26px !important" }}>
              Select Payment Option
            </Typography>
            <List sx={{ pl: "24px !important" ,mb :"16px !important"}}>
              
              <ListItem sx={{mt : "35px !important"}}>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                  onClick={eventTrack.bind(this, "engagement", "One-shot Payment", "Payment Option Page")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={
                      // <Radio
                      //   {...controlProps("one_shot")}
                      //   color="success"
                      //   size="small"
                      // />
                      <ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />
                    }
                  >
                    <ListItemText
                      primary="One-shot Payment "
                      secondary="Pay the full amount in one go."
                    />
                    <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FzohoFavi.svg?alt=media&token=d0db9e0d-4982-4673-88e4-85d570147f6c" alt="zoho" sx={{ mb: 1 }} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{mr : "160px !important"}}>
                      Pay the full amount in one time.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </ListItem>
              <ListItem>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  onClick={eventTrack.bind(this, "engagement", "Direct Payment", "Payment Option Page")}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    expandIcon={
                      // <Radio
                      //   {...controlProps("Direct")}
                      //   color="success"
                      //   size="small"
                      // />
                      <ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />

                    }
                  >
                    <ListItemText
                      primary="Direct Payment "
                      secondary="Send your payment directly to us via bank transfer."
                    />
                    <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FzohoFavi.svg?alt=media&token=d0db9e0d-4982-4673-88e4-85d570147f6c" alt="bosscoder" sx={{ mb: 1 }} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      This can be done in 2 instalments.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </ListItem>
              <ListItem >
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                  onClick={eventTrack.bind(this, "engagement", "Monthly Installments - EMI", "Payment Option Page")}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                    expandIcon={
                      // <Radio
                      //   {...controlProps("propelld")}
                      //   color="success"
                      //   size="small"
                      // />
                      <ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />
                    }
                  >
                    <ListItemText
                      primary="Monthly Installments - EMI"
                      secondary="Get your preferred EMI plan."
                    />
                    <img src= "https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FPropelldFavi.svg?alt=media&token=b879d375-c59c-4037-8b2d-c9dfd65e2bc7" alt="propelld" sx={{ mb: 1 }} />
                  </AccordionSummary>
                  <AccordionDetails >
                    
                    <Typography>
                    If you want to pay in longer instalments, we can help you with easy financing options.
                    </Typography>
                    <ListItem >
                      <img src= "https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2Ffibe%20logo.png?alt=media&token=85404c17-d0f7-4cb3-973b-6e261600c700" alt="propelld" style={{ mb: 1, width : "40px"}} />
                      <ul>
                        <li>6 months installments</li>
                        <li>9 months installments</li>
                        <li>12 months installments</li>
                      </ul>
                      <img src= "https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FLiquiloans%20Logo.png?alt=media&token=5530827e-37d6-4cb6-8535-5053319d5281" alt="propelld"style={{ mb: 1, width : "120px"}} />
                    </ListItem>
                  </AccordionDetails>
                </Accordion>
              </ListItem>
              
            </List>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default ProcessPage4;
