import React from "react";
import { Paper, Typography, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import {Colors} from "../../../../assets/Colors"
const ProgressDash = (props) => {
    const {currentUserObj} = props;
  return (
    <>
      <Paper sx={{ flexFlow: 1, bgcolor: Colors.primaryLite }} variant="outlined">
        <Grid container align = "center" justify = "center" alignItems = "center" paddingTop={1}>
          <Grid xs={12} sm={6} lg={6} align = "center" justify = "center" alignItems = "center" >
            <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FLeaderboard%2Fprogress.svg?alt=media&token=b026126c-ca45-41d2-8342-8ef18217d2be" alt="" style={{}} />
            <Typography sx={{color: Colors.primaryMain }}>This Week</Typography>
            <Typography>{currentUserObj.last_week_count}</Typography>
          </Grid>

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
        />
          <Grid xs={12} sm={6} lg={5} align = "center" justify = "center" alignItems = "center" >
            <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FLeaderboard%2FAward.svg?alt=media&token=8e7048cf-2a1d-41be-b7da-b1076a83dc88" alt="" style={{ marginLeft : "2px"}} />
            <Typography sx={{color: Colors.primaryMain}}>Total Count</Typography>
            <Typography>{currentUserObj.leetcode_count}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ProgressDash;
