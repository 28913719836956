import React from "react";
import { ListItem, List, ListSubheader, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IconButton } from "@mui/material";
import { isAuthenticated, signout } from "../../../../api/authApi";
import { useNavigate } from "react-router-dom";
import axios from "../../../../api/axios";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Input = styled("input")({
  display: "none",
});

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },

  height: "30px",
}));
let curr_file = null;
export default class App extends React.Component {
  state = {
    checkbox: "",
    checkboxValid: false,
    errorMsg: {},
    selectedCheckBox: 0,
    file: null,
  };

  uploadState = {
    file: null,
  };

  handleFilechange = (event) => {
    this.setState({
      loaded: 0,
      message: event.target.files[0]
        ? event.target.files[0].name
        : this.state.defaultmessage,
      file: event.target.files,
    });
    this.uploadState.file = event.target.files[0];
  };

  handleSubmission = (event) => {
    event.preventDefault();
    if (this.state.uploading) return;
    if (!this.state.selectedFile) {
      this.setState({ message: "Select a file first" });
      return;
    }
    this.setState({ uploading: true });
    var formdata = new FormData();
    formdata.append("resume", this.state.file[0]);
    formdata.append("id_token", isAuthenticated()?.data?.idToken);

    axios
      .post("/student/upload_resume", formdata, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: Math.round(
              (ProgressEvent.loaded / ProgressEvent.total) * 100
            ),
          });
        },
      })
      .then((res) => {
        this.setState({
          selectedFile: null,
          message: "Uploaded successfully",
          uploading: false,
        }).catch((err) => {
          // for error handling
          this.setState({
            uploading: false,
            message: "Failed to upload",
          });
        });
      });
  };

  validateForm = () => {
    const { checkboxValid } = this.state;
    this.setState({
      formValid: checkboxValid,
    });
  };

  updateCheckbox = ({ name, checked }) => {
    this.setState(
      (prev) => ({
        checkbox: checked,
        selectedCheckBox: checked
          ? prev.selectedCheckBox + 1
          : prev.selectedCheckBox - 1,
      }),
      this.validateCheckbox
    );
  };

  validateCheckbox = () => {
    const { checkbox } = this.state;
    let checkboxValid = true;
    let errorMsg = { ...this.state.errorMsg };
    if (this.state.selectedCheckBox < 3) {
      checkboxValid = false;
      errorMsg.checkbox = "Please select atleast 3 checkbox";
    }
    this.setState({ checkboxValid, errorMsg }, this.validateForm);
  };

  render() {
    return (
      <>
        <List sx={{ listStyleType: "disc" }}>
          <ListSubheader
            sx={{
              mt: 4,
              ml: 1,
              fontWeight: 600,
              lineHeight: "40px",
              fontSize: "22px",
              color: "black",
            }}
          >
            How to Create a Good Resume
          </ListSubheader>
          <ListItem sx={{ fontSize: 17 }}>
            <Checkbox
              {...label}
              onChange={(e) => this.updateCheckbox(e.target)}
              sx={{ transform: "scale(0.9)" }}
            />{" "}
            Keep it on One Page. This is a biggie!
          </ListItem>
          <ListItem sx={{ fontSize: 17 }}>
            <Checkbox
              {...label}
              onChange={(e) => this.updateCheckbox(e.target)}
              sx={{ transform: "scale(0.9)" }}
            />{" "}
            Avoid Seplling or Grammer Errors
          </ListItem>
          <ListItem sx={{ fontSize: 17 }}>
            <Checkbox
              {...label}
              onChange={(e) => this.updateCheckbox(e.target)}
              sx={{ transform: "scale(0.9)" }}
            />{" "}
            Follow the guidelines from the document...
            <a
              href="https://docs.google.com/document/d/1og1Ew53YbyfB0wJjtRc5GUPrp4xh1fYL7cNokrxg27Q/edit"
              target="_blank"
              rel="noreferrer"
            >
              [Link..]
            </a>
          </ListItem>
        </List>

        <Typography sx={{ fontWeight: 700, mb: 3, mt: 4, ml: 3, fontSize: 22 }}>
          Choose a File
        </Typography>

        <Stack direction="row" alignItems="center" spacing={2}>
          <label htmlFor="contained-button-file">
            <Input
              accept="pdf/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={this.handleFilechange}
              disabled={!this.state.formValid}
            />

            <ColorButton
              disabled={!this.state.formValid}
              variant="contained"
              component="span"
              button
              sx={{ ml: 4, pl: 7, pr: 7, color: "black" }}
            >
              Upload File
            </ColorButton>
          </label>
          <label htmlFor="icon-button-file">
            <Input accept="pdf/*" id="icon-button-file" type="submit" />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              sx={{}}
              onClick={this.handleSubmission}
            >
              <UploadFileIcon />
            </IconButton>
          </label>
          <span>
            {this.state.uploading
              ? this.state.loaded + "%"
              : this.state.message}
          </span>
        </Stack>
      </>
    );
  }
}
