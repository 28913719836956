import { Box, CircularProgress } from "@material-ui/core";
import "../../index.css";
import loading from "../../assets/loading.svg";
import Backdrop from '@mui/material/Backdrop';
const Loading = () => {
  return (
   
    // <div className="loading">
    //   <CircularProgress/>
    //   </div>
    // <div className="loading">
    //   <img src={loading} style={{height : "100px", width : "100px"}} />
    // </div>

    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open
  >
    <CircularProgress color="inherit" />
  </Backdrop>

  );
};

export default Loading;
