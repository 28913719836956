import React, { useState, useEffect } from "react";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { Button} from "@mui/material";
import { Stepper, Step, StepLabel, makeStyles } from "@material-ui/core";
import Container from "@mui/material/Container";
import Enrollment from "./components/Enrollment";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import { styled } from "@mui/material/styles";
import ProcessPage1 from "./components/ProcessPage1";
import ProcessPage3 from "./components/ProcessPage3";
import ProcessPage4 from "./components/ProcessPage4";
import ProcessPage5 from "./components/ProcessPage5";
import MentorAssignDate from "./components/MentorAssignDate";
import ClassSchedule from "./components/ClassSchedule";
import ImportantLinks from "./components/ImportantLinks";
import BatchStarts from "./components/BatchStarts";
import Loading from "../../notificationPages/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import MentorAssign from "./components/MentorAssign";
import ClassTimetable from "./components/ClassTimetable";
import Alert from "@mui/material/Alert";

const Onbording = () => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const [onbording, setOnbording] = useState();
  const [activeStep, setActiveStep] = useState();
  const [stepperStep, setStpperStep] = useState();
  const [contentStep, setContentStep] = useState();
  const [loading, setLoading] = React.useState(false);

  const map1 = new Map();
  map1.set(0, 0);
  map1.set(1, 1);
  map1.set(2, 1);
  map1.set(3, 1);
  map1.set(4, 1);
  // map1.set(5, 1);
  map1.set(5, 2);
  map1.set(6, 3);
  map1.set(7, 4);
  map1.set(8, 5);
  map1.set(9, 6);
  let isMounted = true;
  const getOnbordingState = () => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/get_onboarding_state", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (isMounted) {
                let onbording = res?.data.onboarding_state;
                setOnbording(res?.data.onboarding_state);
                localStorage.setItem(
                  "onbordingState",
                  JSON.stringify(res?.data.onboarding_state)
                );
                setActiveStep(parseInt(onbording.current_page) - 1);

                setContentStep(parseInt(onbording.current_page) - 1);
                setStpperStep(map1.get(parseInt(onbording.current_page) - 1));
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };
  useEffect(() => {
    try {
      if (
        localStorage.getItem("onbordingState") &&
        localStorage.getItem("onbordingState") !== undefined
      )
        setOnbording(JSON.parse(localStorage.getItem("onbordingState")));
      getOnbordingState();
    } catch {
      getOnbordingState();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "#1D99BE !important" },
      "& .MuiStepIcon-completed": { color: "#A2F07B !important" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "#D3D3D3 !important" },
    },
  }));
  const NextButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: " #1D99BE",
    "&:hover": {
      backgroundColor: "#33A3C4",
    },
    // float: "right",
  }));
  const BackButton = styled(Button)(({ theme }) => ({
    color: "black",
    backgroundColor: " #ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    // float: "right",
  }));

  const c = useStyles();

  const steps = [
    `Enrollment`,
    `Payment Process`,
    `Mentor Allotment`,
    `Class Structure`,
    `Class Schedule`,
    `Important Links`,
    `Batch Starts`,
  ];

  const nextStep = () => {
    setLoading(true);
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          let current_page = (2 + activeStep).toString();

          let obj = {
            id_token: isAuthenticated()?.data?.idToken,
            current_page,
          };

          axios.post("/student/update_onboarding_state", obj).then((res) => {
            if (res?.data?.success) {
              if (activeStep < 9)
                setActiveStep((curruntStep) => curruntStep + 1);
              setContentStep(activeStep + 1);
              setStpperStep(map1.get(activeStep + 1));
              setLoading(false);
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };
  const prevStep = () => {
    if (activeStep > 0) setActiveStep((curruntStep) => curruntStep - 1);
    setContentStep(activeStep - 1);
    setStpperStep(map1.get(activeStep - 1));
  };
  let onbording_keys = Object.values(onbording || {})[1];
  let lock = Object.values(onbording_keys || {})[0];

  let mentor_field = Object.values(onbording || {})[1];
  let assignedMentor = Object.values(mentor_field || {})[0];

  let orientation_link = Object.values(onbording || {})[5]
  let batchStarts = Object.values(onbording || {})[6] 
  

 
  const allSteps = [
    {
      content: <Enrollment />,
    },
    {
      content: <ProcessPage1 />,
    },
    // {
    //   content: <ProcessPage2 />,
    // },
    {
      content: <ProcessPage3 />,
    },
    {
      content: <ProcessPage4 />,
    },
    {
      content: <ProcessPage5 />,
    },

    {
      content:  <MentorAssignDate />,
      // lock === false ?  :
    },
    {
      content: <ClassSchedule />,
    },
    {
      content: <ClassTimetable batchStarts = {batchStarts}
      orientation_link = {orientation_link} />,
    },
    {
      content: <ImportantLinks />,
    },
    {
      content: <BatchStarts />,
    },
  ];

  function navToProfile() {
    navigate("/user/profile");
  }

  function refreshPage() {
    window.location.reload();
  }
  return onbording ? (
    <>
      <div align="center">
        <Alert
          sx={{
            width: "max-content",
            mb: 2,
            textAlign: "center",
            backgroundColor : "#e5f6fd !important"
          }}
          severity="info"
        >
          Onbording process for {auth?.batch} .
        </Alert>
      </div>
      <Container maxWidth="md" sx={{ widht: "800px", mt: -2 }}>
        <Stepper className={c.root} activeStep={stepperStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>
      <Container maxWidth="md" sx={{ maxWidht: "800px" }}>
        {allSteps.map(
          (step, index) =>
            index === contentStep && (
              <div>
                {step.content}
                <Button>{step.buttons}</Button>
               </div>
            )
        )}

        {activeStep > 0 ? (
          <BackButton
            onClick={() => prevStep()}
            sx={{ boxShadow: 5, marginLeft : "30%", mb : -7 }}
            startIcon={<ArrowCircleLeftRoundedIcon />}
            variant="contained"
          >
            {activeStep < 0 ? "" : "Back"}
          </BackButton>
        ) : (
          <div></div>
        )}
        {contentStep < 9 ? (
          <NextButton
            onClick={() => nextStep()}
            sx={{ boxShadow: 5, marginBottom : 2, marginLeft : "50%" }}
            endIcon={<ArrowCircleRightRoundedIcon />}
            variant="contained"
            loadingPosition="end"
            loading={loading}
            // disabled ={activeStep === 5 && lock === false ? true : false}
          >
            {activeStep === 4 ? "Finish" : "Next"}
          </NextButton>
        ) : (
          <NextButton
            sx={{ boxShadow: 5,marginBottom : 2 ,marginLeft : "50%"}}
            endIcon={<ArrowCircleRightRoundedIcon />}
            variant="contained"
            loading={loading}
            loadingPosition="end"
            // onClick={navToProfile}
            // How two functions called in one onclick function
            onClick={() => {
              navToProfile();
              refreshPage();
            }}
          >
            Get Started
          </NextButton>
        )}
      </Container>
    </>
  ) : (
    <Loading />
  );
};

export default Onbording;
