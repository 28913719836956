import React from 'react'
import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Colors} from "../../../../assets/Colors"
const InformationPanel = () => {
  return (
    <>
    <Box
    sx={{ 
      width: 360,
      minHeight : "100%",
      backgroundColor: Colors.primaryLite,
      border: 1.5,
      borderColor :"#CDCDCD",
      mt : -16,
   
    }}

    >  
    <List>
        <ListItem>
            <HelpOutlineIcon sx= {{mr : "2px" }}/>
            <Typography sx = {{fontSize : "24px", color : "#6B7B8A"}}>About Mentor Session</Typography>
         </ListItem>
         <Divider sx = {{color : "#CDCDCD",}}/>
    </List>

        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
          When is my mentor going to be assigned? 
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2}}>
        Mentors will be assigned in first 2 weeks after the course start.
        </Typography>
        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
        How mentor sessions work? 
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2}}>
        Mentors will meet you once a month for a 1-1 mock interview.
        </Typography>
        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
          When is my mentor going to be assigned? 
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2}}>
        Mentors will be assigned in first 2 weeks after the course start.
        </Typography>
        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
        Are there any group sessions with mentor?
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2}}>
        Yes, mentors will have group sessions with 5-6 students to discuss resumes, linkedin, job search etc.
        </Typography>
        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
         How to utilise mentor sessions? 
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2}}>
        Make sure you book 1 session every month, and listen to your mentor.
        </Typography>
        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
        How can I contact my mentor? 
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2}}>
        You can contact them in the whatsapp group created for you with your mentor.
        </Typography>
        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
        Can I call mentor anytime?
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2}}>
        Mentor is like your elder brother or sister. You should contact them in the whatsapp group anytime.
        </Typography>
        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
        What if I have any problem/issues regarding my mentor/mentor sessions? 
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2}}>
        Drop and email to onboarding@bosscoderacademy.com and we will take care.
        </Typography>
        <Typography sx = {{fontSize : "18px", color : "#6B7B8A", pl  : 2, mt : 2, fontWeight : 700}}>
        I want to change my mentor? 
        </Typography>
        <Typography sx = {{color : "#6B7B8A", pl  : 2}}>
        Drop an email to onboarding@bosscoderacademy.com with a reason why you want to change your mentor. We will change your mentor.
        </Typography>
      
        
    </Box>
   
    </>
  )
}

export default InformationPanel;