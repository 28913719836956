import React from "react";
import {
 
  Paper,
  Typography,
  Grid,
  styled,
  List,
  ListItem,

} from "@mui/material";
import {Colors} from "../../../../assets/Colors"
const Img = styled("img")({
  marginTop: 40,
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const ProcessPage1 = () => {
 
  return (
    <>
      <Grid container spacing={4} sx={{ mt : "10px"}}>
        <Grid item xs={12} md={6}>
          <Item sx={{ backgroundColor: Colors.primaryLite, pt: "16px" ,pl : "56px", height : "420px"}}>
            <Typography sx={{ fontSize: "26px !important" }}>Step - 1</Typography>
            <Img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FProcess1.svg?alt=media&token=5dee3f9c-7b19-4f06-872e-82af87c518eb" alt="" sx={{mb : 1}}/>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ backgroundColor: Colors.primaryLite, pt: "16px !important",height : "420px" }}>
            <Typography sx={{ fontSize: "26px !important" }}>Know the process</Typography>
            <List sx={{pl : "16px", pr: "16px"}}>
              <ListItem>
                  We have 2 types of payment options:
              </ListItem>
              <ListItem>
                  <ol>
                    <li style={{marginBottom : "10px"}}><span style={{fontWeight : 700, }}>Direct Payment to Bossocoder </span>: This can be done in 1 instalment or 2 instalments. </li>
                    <li><span style={{fontWeight : 700}}>Financing options through our NBFC Partners </span>: If you want to pay in longer instalments, we can help you with easy financing options. </li>
                  </ol>
              </ListItem>
                 
              <ListItem style={{fontWeight : 700}}>
                    Payment Steps :
              </ListItem>
              <ListItem sx={{mt : "-16px"}}> 
                    <ol>
                      <li>Seat Booking: Pay Rs. 5000 for booking a seat in the batch</li>
                      <li>Complete registration: Pay remaining amount</li>
                    </ol>
              </ListItem>
            </List>
              
  
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default ProcessPage1;
