import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import Vimeo from "@u-wave/react-vimeo";
import axios from "../../../api/axios";
import Loading from "../../notificationPages/Loading";
import QuestionCard from "./components/QuestionCard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { color } from "@mui/system";
import { Link as a } from "@mui/material";
import PrerecordedSession from "./components/PrerecordedSession";
function CourseDetails({ topicName, notesLink, prerecorded_video_link }) {
  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#1D99BE",
        darker: "#1D99BE",
      },
      neutral: {
        main: "#1D99BE",
        contrastText: "#1D99BE",
      },
    },
  });

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#F7F7FB",
            borderRadius: 2,
            pl: "50px",
            pr: "50px",
            pt: "16px",
            pb: "16px",
            // height : "64px"
          }}
        >
          <Typography
            sx={{ fontWeight: 800, fontSize: 20, textAlign: "center" }}
          >
            {topicName}
          </Typography>
        </Box>
        <Divider sx={{ mt: "16px" }} />
        <Box sx={{ pl: "100px" }}>
          <Typography sx={{ fontWeight: 600, fontSize: 20, mt: "8px" }}>
            Manish Garg
          </Typography>
          <Typography sx={{ fontSize: 16, pl: 1, mb: "8px" }}>
            Course Tutor
          </Typography>
        </Box>
        <Divider sx={{ mt: 1 }} />
        <ThemeProvider theme={theme}>
          <Typography
            sx={{ fontSize: 16, fontWeight: 800, mt: "16px", color: "primary" }}
          >
            <a
              href={notesLink}
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <Button
                sx={{
                  width: "100%",
                  backgroundColor: "#F7F7FB",
                  borderRadius: 2,
                  p: 1,
                  height: "64px",
                }}
                variant="text"
                color="inherit"
              >
                Download Notes
              </Button>
            </a>
          </Typography>
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
              backgroundColor: "#F7F7FB",
              borderRadius: 2,
              mt: "16px",
            }}
            variant="outlined"
          >
            <Typography
              sx={{
                width: "100%",
                p: 1,
                fontSize: 16,
                fontWeight: 600,
                color: "inherit",
                backgroundColor: "#F7F7FB",
                borderRadius: 2,
                textAlign: "center",
              }}
            >
              PRERECORDED SESSIONS
            </Typography>
            <PrerecordedSession
              prerecorded_video_link={prerecorded_video_link}
            />
          </Paper>
        </ThemeProvider>
      </Box>
    </>
  );
}

const Lesson = () => {
  const { id } = useParams();
  const { name } = useParams();
  const auth = isAuthenticated()?.data;
  const [lessonDetails, setLessonDetails] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.status === 201) {
          if (res?.data) {
            var existing = JSON.parse(localStorage.getItem("jwt"));
            existing.data.idToken = res?.data?.access_token;
            localStorage.setItem("jwt", JSON.stringify(existing));
          }
          axios
            .post("/student/get_modules_detail_v3", {
              id_token: res?.data?.id_token,
              id: parseInt(id),
              module: name,
            })
            .then((res) => {
              if (isMounted) {
                if (res?.data?.success) {
                  setLessonDetails(res?.data?.response?.course);
                }
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);
  return lessonDetails ? (
    !lessonDetails.is_locked ? (
      <Box sx={{ overflow: "hidden", width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} s={12} md={12} lg={8}>
            {lessonDetails?.video_link ? (
              <Box sx={{ p: 2 }}>
                <Vimeo
                  video={lessonDetails.video_link}
                  speed
                  autopause
                  responsive
                />
              </Box>
            ) : (
              <div align="left">
                <Alert
                  sx={{
                    width: "50%",
                    mb: 2,
                    textAlign: "center",
                    backgroundColor: "#e5f6fd !important",
                  }}
                  severity="info"
                >
                  The Video will be available before 4 PM
                </Alert>
              </div>
            )}
          </Grid>
          <Grid item xs={12} s={12} md={12} lg={4}>
            <CourseDetails
              topicName={lessonDetails.topic}
              notesLink={lessonDetails?.notes ? lessonDetails?.notes[0] : ""}
              prerecorded_video_link={lessonDetails?.prerecorded_video_link}
            />
          </Grid>
        </Grid>

        {lessonDetails?.warmup_questions?.length > 0 && (
          <Paper sx={{ my: 1, mx: "auto", p: 2 }}>
            <QuestionCard
              name="Warmup questions"
              content={lessonDetails.warmup_questions}
            />
          </Paper>
        )}
        {lessonDetails?.assignment?.length > 0 && (
          <Paper sx={{ my: 1, mx: "auto", p: 2 }}>
            <QuestionCard
              name="Assignments"
              content={lessonDetails.assignment}
            />
          </Paper>
        )}

        {lessonDetails?.homework?.length > 0 && (
          <Paper sx={{ my: 1, mx: "auto", p: 2 }}>
            <QuestionCard name="Home Work" content={lessonDetails.homework} />
          </Paper>
        )}

        {lessonDetails?.practice?.length > 0 && (
          <Paper sx={{ my: 1, mx: "auto", p: 2 }}>
            <QuestionCard name="Practice" content={lessonDetails.practice} />
          </Paper>
        )}
      </Box>
    ) : (
      <div>Content is locked</div>
    )
  ) : (
    <Loading />
  );
};

export default Lesson;
