import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../api/authApi";
import axios from "../api/axios";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FormDialog() {
    const [leetcodeVerified, setLeetcodeVerified] = React.useState(false);
    const [error, setError] = React.useState("");
    const [input, setInput] = React.useState("")
    const [loading, setLoading] = useState(false);
    const [successToast, setsuccessToast] = useState(false);
    const [errorToast, seterrorToast] = useState(false);
    const auth = isAuthenticated()?.data;
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .post("/common/refresh_token", {
                refresh_token: auth?.refreshToken,
            })
            .then((res) => {
                if (res?.data?.access_token) {
                    var existing = JSON.parse(localStorage.getItem("jwt"));
                    existing.data.idToken = res?.data?.access_token;
                    localStorage.setItem("jwt", JSON.stringify(existing));

                    axios
                        .post("/student/is_leetcode_verified", {
                            id_token: res?.data?.id_token,
                        })
                        .then((res) => {
                            setLeetcodeVerified(!res.data.leetcode_verified)
                        });
                } else {
                    signout(auth?.localId, () => {
                        navigate("/");
                    });
                }
            });
    }, []);

    const onInputChange = (e) => {
        setInput(e.target.value)
    }
    const ColorButton = styled(LoadingButton)(({ theme }) => ({
        backgroundColor: "#1d99be",
        "&:hover": {
          backgroundColor: "#1d8cbe",
        },
      }));
    const handleToastClose = (e) => {
        setsuccessToast(false);
        seterrorToast(false);
    };

    const SubmitResponse = (e) => {
        setLoading(true);
        e.preventDefault();
        axios.post("/common/refresh_token", {
            refresh_token: auth?.refreshToken,
        })
            .then((res) => {
                if (res?.data?.access_token) {
                    var existing = JSON.parse(localStorage.getItem("jwt"));
                    existing.data.idToken = res?.data?.access_token;
                    localStorage.setItem("jwt", JSON.stringify(existing));
                    let obj = {
                        id_token: res?.data?.id_token,
                        username: input
                    }
                 
                    axios
                        .post("/student/verify_leetcode", obj)
                        .then((res) => {
                            if (res.data.success == true) {
                                setLoading(false);
                                setsuccessToast(true);
                                setLeetcodeVerified(false)
                            } else {
                                setError(res.data.error)
                                seterrorToast(true);
                            }
                        });
                } else {
                    signout(auth?.localId, () => {
                        navigate("/");
                    });
                }
            })
    }

    return (
        <>
       
        <div>
        <Snackbar
          open={successToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical : "top", horizontal : "center" }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Leetcode Added Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
      
        </div>
        <Dialog open={leetcodeVerified}>
            <DialogTitle style={{ backgroundColor: "#007FFF", color: "white" }}> Enter Leetcode Username </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ margin: "20px 0px" }}>
                    To continue to this website, please enter your leetcode username here. Please note that if your leetcode profile
                    page is "https://leetcode.com/username" you should only write the username here.
                </DialogContentText>

                <TextField
                    label="Leetcode Username"
                    onChange={onInputChange}
                    value={input}
                    id="input"
                    type="text"
                    variant="standard"
                    fullWidth
                />
                <p style={{ color: "red" }}> {error}</p>
            </DialogContent>
            <DialogActions>
                {/* <ColorButton onClick={SubmitResponse}>Submit</ColorButton> */}
                <ColorButton
                onClick={SubmitResponse}
                variant="contained"
                loadingPosition="center"
                loading={loading}
                sx={{mr : 2, mb: 1 }}
            >
              Submit
            </ColorButton>
            </DialogActions>
        </Dialog>
        </>
    );
}
