import React, { Component } from "react";
import Editor from "@monaco-editor/react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import "./editor.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import beta_tag from "../../assets/beta_tag.svg";
import bosscoder_white_logo from "../../assets/bosscoder_white_logo.svg";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: this.props.code,
      languageMenuOpen: false,
      language: this.props.language,
      anchorEl: null,
      loading: false,
    };
  }

  render() {
    const ColorButton = styled(LoadingButton)(({ theme }) => ({
      background:
        "linear-gradient(95.79deg, #202658 -5.72%, #202658 -5.72%, #06A1F9 -5.71%, #4032EB 106.18%, #4032EB 106.18%)",
      padding: "8px 32px",
      borderRadius: "8px",
      marginRight: "8px",
      textTransform: "none",
    }));

    return (
      <div
        style={{
          color: "#FAF9F6",
          marginLeft: "20px",
          width: "100%",
          height: "93vh",
        }}
      >
        <div style={{ marginTop: "20px" }}>
          <img src={beta_tag} />
        </div>
        <div
          style={{ float: "right", marginRight: "31.65px", marginTop: "4px" }}
        >
          <img
            style={{ height: "37.15px", width: "175.35px" }}
            src={bosscoder_white_logo}
          />
        </div>
        <div style={{ marginTop: "12px", marginBottom: "8px" }}>Language</div>
        <Button
          id="basic-button"
          aria-controls={this.props.languageMenuOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={this.props.languageMenuOpen ? "true" : undefined}
          onClick={this.props.handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{ border: 1, borderColor: "white", color: "white", mb: "26px" }}
        >
          {this.props.language}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={this.props.anchorEl}
          open={this.props.languageMenuOpen}
          onClose={this.props.handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => this.props.handleClose(e, "cpp")}> CPP </MenuItem>
          <MenuItem onClick={(e) => this.props.handleClose(e, "python")}>
            {" "}
            Python{" "}
          </MenuItem>
          <MenuItem onClick={(e) => this.props.handleClose(e, "java")}>
            {" "}
            Java{" "}
          </MenuItem>
        </Menu>
        <div
          style={{
            borderRadius: "8px",
            padding: "1px",
            backgroundColor: "#EEF2FF",
            marginRight: "24px",
            marginBottom: "24px",
          }}
        >
          <div className="editor" style={{ backgroundColor: "#1E1E1E" }}>
            <Editor
              language={this.props.language || "javascript"}
              value={this.props.code[this.props.language]}
              theme="vs-dark"
              onChange={(newcode) => this.props.changeCode(newcode)}
            />
          </div>
        </div>

        <LoadingButton
          variant="outlined"
          loading={this.props.runCodeLoading}
          onClick={() => {
            this.props.handleRunCode();
            this.props.handleOpenDrawer();
          }}
          style={{
            marginRight: "20px",
            borderColor: this.props.runCodeLoading ? "white" : "white",
            textTransform: "none",
            padding: "7px 24px",
            borderRadius: "8px",
          }}
        >
          {this.props.runCodeLoading ? (
            <div style={{ color: "white" }}>Running…</div>
          ) : (
            <div style={{ color: "white" }}>Run</div>
          )}
        </LoadingButton>
        <ColorButton
          variant="contained"
          loading={this.props.submitLoading}
          onClick={this.props.handleCodeSubmit}
          loadingIndicator=""
        >
          {this.props.submitLoading ? (
            <div style={{ color: "white" }}>Submitting...</div>
          ) : (
            <div style={{ color: "white" }}>Submit</div>
          )}
        </ColorButton>
      </div>
    );
  }
}

export default Question;
