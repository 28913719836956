import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import Loading from "../../notificationPages/Loading";
import CourseCard from "./component/CourseCard";
import ProgressBar from "./component/ProgressBar";
import QuestionCard from "./component/QuestionCard";
import { Grid } from "@mui/material";
import "./style.css";
import ReactGa from "react-ga";

const PrerequisiteCourse = () => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const [course, setCourse] = useState();
  const [progress, setProgress] = useState(0);

  let isMounted = true;
  const getBeginnerCourse = () => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/check_progress", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              const countJSON = res?.data?.response;
              setProgress(countJSON?.count);
            });

          {
            auth?.batch === "B11B"
              ? axios
                  .post("/student/temp_beginner_course", {
                    id_token: res?.data?.id_token,
                  })
                  .then((res) => {
                    if (isMounted) {
                      setCourse(res?.data?.response?.user_obj?.course);
                      localStorage.setItem(
                        "BeginnerCourse",
                        JSON.stringify(res?.data?.response?.user_obj?.course)
                      );
                    }
                  })
              : axios
                  .post("/student/get_beginner_course", {
                    id_token: res?.data?.id_token,
                  })
                  .then((res) => {
                    if (isMounted) {
                      setCourse(res?.data?.response?.user_obj?.course);
                      localStorage.setItem(
                        "BeginnerCourse",
                        JSON.stringify(res?.data?.response?.user_obj?.course)
                      );
                    }
                  });
          }
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };
  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
    try {
      if (
        localStorage.getItem("BeginnerCourse") &&
        localStorage.getItem("BeginnerCourse") !== undefined
      )
        setCourse(JSON.parse(localStorage.getItem("BeginnerCourse")));
      getBeginnerCourse();
    } catch {
      getBeginnerCourse();
    }

    return () => {
      isMounted = false;
    };
  }, [progress]);

  return course ? (
    <>
      <ProgressBar progress={progress} total={course.length} />
      <div>
        <Grid container spacing={2}>
          {course.map((c, i) => (
            <Grid key={c.id} item xs={12} sm={12} lg={12}>
              {" "}
              <CourseCard
                id={c.id}
                image_link={c.image_link}
                is_completed={c.is_completed}
                is_locked={c.is_locked}
                topic={c.topic}
                setProgress={setProgress}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default PrerequisiteCourse;
