import { Link, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system'
import Container from "@mui/material/Container";
import React from 'react'
import LanguageIcon from '@mui/icons-material/Language';
import {Colors} from "../../../../assets/Colors"
import ReactGA from "react-ga"
import {LibraryBooks,} from "@mui/icons-material";
const ImportantLinks = () => {

  const eventTrack = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }
  return (
    <>
        <Container sx={{mt : "50px"}}>
        <Box sx={{ ml : 30, mb : 2}}>
            <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FBosscoLogo.svg?alt=media&token=ad3940e5-fa8a-4b09-87b7-a0bb3e7d2102" alt="Bossocderlogo" />
        </Box>
        <Box sx={{pl :10}}>
        <Link href="https://discord.gg/6k9fen8kVB" target="_blank" sx={{textDecoration : "none"}} onClick={eventTrack.bind(this, "Link", "Discord", "Important Links")}>
        <Paper sx={{width : "600px", height : "83px", textAlign : "center", mb : 2, bgcolor :Colors.primaryLite,pr : 4}} variant ="outlined">
            <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2Fdiscord.svg?alt=media&token=a103317c-3eee-49ac-993b-24689cdb7a1a" alt="" style={{float : "left",marginLeft : "32px", marginTop : "20px"}}/>
           <Typography sx={{mt : "24px !important", fontSize : "26px !important",color : "#1D99BE"}}>Discord</Typography>
        </Paper>
        </Link> 
        <Link href="https://drive.google.com/file/d/1uIfqXuBWAV1UgP2OYuGxJux1-oWzgTYe/view?usp=sharing" target="_blank" sx={{textDecoration : "none"}} onClick={eventTrack.bind(this, "Link", "Prerequisite", "Important Links")}>
        <Paper sx={{width : "600px", height : "83px" ,mb : 2, textAlign : "center",bgcolor :Colors.primaryLite, pr : 4}}variant ="outlined">
        <Typography sx={{mt : "24px !important", fontSize : "26px !important" ,color : "#1D99BE"}}> <LibraryBooks fontSize="large"sx={{float : "left", color : "#1D99BE", ml : "32px"}}/>Prerequisite</Typography>
        </Paper>
        </Link>
        <Link href="https://www.bosscoderacademy.com/" target="_blank" sx={{textDecoration : "none"}} onClick={eventTrack.bind(this, "Link", "Bosscoder-Webside", "Important Links")}>
        <Paper sx={{width : "600px", height : "83px", mb : 2, textAlign : "center", bgcolor :Colors.primaryLite,pr : 4}} variant ="outlined">
        <Typography sx={{mt : "24px !important", fontSize : "26px !important" ,color : "#1D99BE"}}> <LanguageIcon fontSize="large"sx={{float : "left", color : "#1D99BE", ml : "32px"}}/> Official Website</Typography>
        </Paper>
        </Link>
        <Link href="https://www.linkedin.com/company/bosscoderacademy/" target="_blank" sx={{textDecoration : "none"}} onClick={eventTrack.bind(this, "Link", "Bossco-linkedIn", "Important Links")}>
        <Paper sx={{width : "600px", height : "83px", mb : 2, textAlign : "center", bgcolor :Colors.primaryLite,pr : 4}} variant ="outlined" >
        <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2Flinkedin.svg?alt=media&token=e5362356-69b4-48a2-8ac3-414090ceb1af" alt="" style={{float : "left",marginLeft : "32px", marginTop : "20px"}}/>
        <Typography sx={{mt : "24px !important", fontSize : "26px !important" ,color : "#1D99BE"}}>LinkedIn</Typography>
        </Paper>
        </Link>
        <Link href="https://www.youtube.com/c/BosscoderAcademy" target="_blank" sx={{textDecoration : "none"}} onClick={eventTrack.bind(this, "Link", "Bossco-Youtube", "Important Links")}>
        <Paper sx={{width : "600px", height : "83px", mb : 2, textAlign : "center",  bgcolor :Colors.primaryLite,pr : 4}} variant ="outlined">
        <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2Fyoutube.svg?alt=media&token=84bb8da4-d3ca-43d5-a902-f28dfe7df70b" alt="" style={{float : "left",marginLeft : "32px", marginTop : "20px"}}/>
        <Typography sx={{mt : "24px !important", fontSize : "26px !important" ,color : "#1D99BE"}}>Youtube</Typography>
        </Paper>
        </Link>
        </Box>
        </Container>
    </>
  )
}

export default ImportantLinks