import React from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import { isAuthenticated} from "../../../../api/authApi";
import {Colors} from "../../../../assets/Colors"
const LeaderBoard = (props) => {
    const {leaderboard} = props;
    const auth = isAuthenticated()?.data;
    
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
              backgroundColor: Colors.primaryLite,
              color: Colors.primaryMain,
            },
            [`&.${tableCellClasses.body}`]: {
              fontSize: 14,
            },
          }));
          
          const StyledTableRow = styled(TableRow)(({ theme }) => ({
            '&:nth-of-type(even )': {
              backgroundColor: Colors.primaryLite,
            },
            // hide last border
            '&:last-child td, &:last-child th': {
              border: 0,
            },
          }));
      
  return (
    <> 
    <TableContainer component={Paper}>
     <Table sx={{ minWidth: 650, }} aria-label="simple table">
      <TableHead >
        <StyledTableRow>
          <StyledTableCell>Rank</StyledTableCell>
          <StyledTableCell>Name</StyledTableCell>
          <StyledTableCell align="center">Total Count </StyledTableCell>
          <StyledTableCell align="center">Progress In Last Week</StyledTableCell>
          <StyledTableCell align="center">Total Points </StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>  
        {leaderboard?.map((row, i) => (
          <StyledTableRow
            key={i}
            sx={{ "&:last-child td, &:last-child th": { border: 0 },  }}
          >
           
           {("rank" in row) && (row.rank) > 5 ?  <StyledTableCell component="th" scope="row"> {row.rank}</StyledTableCell> :<StyledTableCell component="th" scope="row">{ i + 1}</StyledTableCell>}

            <StyledTableCell component="th" scope="row"> {row.name}</StyledTableCell>
            <StyledTableCell align="center" >{row.leetcode_count}</StyledTableCell>
            <StyledTableCell align="center">{row.last_week_count}</StyledTableCell>
            <StyledTableCell align="center">{row.points}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  </>
           
                
       
            
            
            
           
  )
}

export default LeaderBoard