import React, { useState, u } from "react";
import DatePicker from "react-multi-date-picker";
import { Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import "react-multi-date-picker/styles/layouts/mobile.css";
import style from "../style.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BlockDates = () => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successToast, setsuccessToast] = useState(false);
  const [errorToast, seterrorToast] = useState(false);

  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
  }));
  const dates = [];
  for (let i = 0; i < values.length; i++) {
    let year = values[i]?.year;
    let month = values[i]?.month?.number;
    let day = values[i]?.day;
    let actualDate;
    if (`${month}`.length === 1) {
      month = `0${month}`;
    }
    if (`${day}`.length === 1) {
      day = `0${day}`;
    }
    actualDate = `${year}-${month}-${day}`;
    dates.push(actualDate);
  }

  const handleToastClose = (e) => {  
    setsuccessToast(false);
    seterrorToast(false);
  };

  const obj = {
    id_token: isAuthenticated()?.data?.idToken,
    date: dates,
  };

  const SubmitResponse = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })

      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios.post("/mentors/block_dates", obj).then((res) => {
            if (res.data.success == true) {
              setLoading(false);
              setsuccessToast(true);
            } else {
              setLoading(false);
              seterrorToast(true);
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };
  return (
    <>
      <div>
        <Snackbar
          open={successToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Response Save
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
      </div>
      <Paper
        sx={{ padding: 2, mt: 8, justifyContent: "center" }}
        variant="outlined"
      >
        <Typography variant="h5" sx={{ mb: 1 }}>
          Block Dates
        </Typography>
        <Typography variant="caption" sx={{ mb: 1, display: "block" }}>
          Add dates when you will unavailable to take calls
        </Typography>
        <DatePicker
          multiple
          value={values}
          onChange={setValues}
          placeholder="Add Unvailable Dates"
          inputClass="custom-input"
          style={{
            backgroundColor: "aliceblue",
            height: "40px",
            borderRadius: "8px",
            fontSize: "14px",
            padding: "3px 10px",
          }}
        />

        <ColorButton
          onClick={SubmitResponse}
          variant="contained"
          loadingPosition="center"
          loadingIndicator="blocking…"
          loading={loading}
          sx={{ display: "flex", justifyContent: "center", mt: 2 }}
        >
          Block Dates
        </ColorButton>
      </Paper>
    </>
  );
};

export default BlockDates;
