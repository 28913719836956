import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Paper,
  TextField,
} from "@mui/material";
import axios from "../api/axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { authenticate, isAuthenticated, signin } from "../api/authApi";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import ReactGA from "react-ga"
const LOGIN_URL = "/common/login";

const Signin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    setErrorMessage("");
  }, [user, pwd]);

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setErrorMessage("");
    setLoading(true);
    signin({ email: user, password: pwd }).then((data) => {
      
      setTimeout(() => {
        setLoading(false)
      }, 200)
      if (data?.data?.code === 400) {
        setError(true)
        setErrorMessage(data?.data?.error)
      } else {
        authenticate(data, () => {
          setRedirect(true);
        });
      }
    });
    ReactGA.event({
      category: "Links",
      action: "Login",
    })
  };

  const performRedirect = () => {
    if (isAuthenticated()?.status === 201) {
      const auth = isAuthenticated()?.data;
        if (auth.role === "user") {
          return (auth.is_onboarding_done === true) ? <Navigate to={`/${isAuthenticated().data.role}/profile`} />: <Navigate to={`/${isAuthenticated().data.role}/user-onbording`} />
        }
        
        return <Navigate to={`/${isAuthenticated().data.role}/profile`} />;
      } 
         
  };

  return (
    <>
      {
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Paper variant="outline">
              <img src="https://uploads-ssl.webflow.com/60e1490057a19b506bd4ebb3/60fd54c519be8bccfd2922de_bosscoder_header_logo_2.svg" />
            </Paper>
            <Divider />

            <Box
              component="form"
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setUser(e.target.value.trim())}
                value={user}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value.trim())}
                value={pwd}
                autoComplete="current-password"
              />
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                // onClick={eventTrack.bind(this, "Links", "Login", "Login Success")}
              >
                Sign In
              </Button> */}
               <ColorButton
                type="submit"
                fullWidth
                variant="contained"
                // loadingPosition="center"
                loadingIndicator ="Sign In"
                loading={loading}
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
            >
              Sign In
            </ColorButton>
            </Box>
          </Box>

          {error && <Alert severity="error">{errorMessage}</Alert>}
        </Container>
      }
      {performRedirect()}
    </>
  );
};

export default Signin;
