import React, { useEffect } from "react";
import { Grid, styled } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import MentorCard from "./components/MentorCard";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../notificationPages/Loading";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/system";
import { Colors } from "../../../assets/Colors";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactGa from "react-ga";

let mentor_priority = [];

const Img = styled("img")({
  display: "block",
  maxWidth: "30%",
  maxHeight: "100%",
  borderRadius: "50%",
  marginLeft: "35%",
  marginTop: "-15%",
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ChooseMentor = () => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const [mentorList, setMentorList] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [open, setOpen] = React.useState(false);
  const SubmitButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: Colors.primaryMain,
    "&:hover": {
      backgroundColor: Colors.primaryButtonHover,
    },
    float: "right",
    // position: "fixed",
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    ReactGa.pageview(window.location.pathname)

    let isMounted = true;

    if (
      localStorage.getItem("mentorData") &&
      localStorage.getItem("mentorData") !== undefined &&
      localStorage.getItem("isMentorAlloted") &&
      localStorage.getItem("isMentorAlloted") !== undefined
    ) {
      setMentorList(JSON.parse(localStorage.getItem("mentorData")));
      setMessage(JSON.parse(localStorage.getItem("isMentorAlloted")));
    }
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("student/get_mentor_details_list", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (res?.data?.response) {
                localStorage.setItem(
                  "mentorData",
                  JSON.stringify(res?.data?.response?.mentor)
                );
                localStorage.setItem(
                  "isMentorAlloted",
                  JSON.stringify(res?.data?.response?.message)
                );
                setMentorList(res?.data?.response?.mentor);
                setMessage(res?.data?.response?.message);
              } else {
                setMentorList([]);
                isMounted = false;
                console.log("error");
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  let obj = {
    id_token: isAuthenticated()?.data?.idToken,
    mentor_priority,
  };

  const sendMentorList = () => {
    let isMounted = true;
    setLoading(true);

    if (obj?.mentor_priority?.length != 3) {
      setLoading(false);
      toast.error("Select 3 mentors", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      axios
        .post("/common/refresh_token", {
          refresh_token: auth?.refreshToken,
        })
        .then((res) => {
          if (res?.data?.access_token) {
            var existing = JSON.parse(localStorage.getItem("jwt"));
            existing.data.idToken = res?.data?.access_token;
            localStorage.setItem("jwt", JSON.stringify(existing));

            axios.post("/student/update_mentor_priority", obj).then((res) => {
              setLoading(false);
              if (res?.data?.response?.success) {
                toast.success("Your response recorded ! ", {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                toast.error(res?.data?.error, {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              window.location.reload();
            });
          } else {
            signout(auth?.localId, () => {
              navigate("/");
            });
          }
        });
    }
  };
  const shuffled = mentorList?.sort(() => 0.5 - Math.random());
  let selected = shuffled?.slice(0, 50);
  return mentorList ? (
    <>
      <ToastContainer />

      {auth.payment_status !== "PAID" ? (
        <SubmitButton
          onClick={() => {
            handleClickOpen();
          }}
          sx={{ boxShadow: 5, mt: 3, position: "fixed", right: 64 }}
          variant="contained"
          loading={loading}
        >
          Submit
        </SubmitButton>
      ) : message === "MENTOR NOT CHOSEN" ? (
        <SubmitButton
          onClick={() => {
            sendMentorList();
          }}
          sx={{ boxShadow: 5, mt: 3, position: "fixed", right: 64 }}
          variant="contained"
          loading={loading}
          disabled={!mentor_priority.length === 3}
        >
          Submit
        </SubmitButton>
      ) : (
        <div></div>
      )}

      <Box sx={{ width: "50%", mt: 2 }}>
        {message === "MENTOR NOT CHOSEN" ? (
          <Alert sx={{width: "max-content"}} severity="success" color="info">
            Choose 3 preferences for Mentor alottment. Limited Availability!
            Hurry!
          </Alert>
        ) : message === "MENTORS CHOSEN BUT NOT ALLOTED" ? (
          <Alert sx={{ width: "max-content" }} severity="success" color="info">
            We will assign you one of the mentors from below list soon!
          </Alert>
        ) : (
          <Alert sx={{ width: "max-content" }} severity="success" color="info">
            Mentor alloted.
          </Alert>
        )}
      </Box>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Premium Feature
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <img
              style={{ width: "100%" }}
              src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Flock2.svg?alt=media&token=f9d417c3-213e-405e-92c5-e86900a1e262"
              alt=""
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </div>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {selected?.map((c, i) => (
          <Grid item xs={12} sm={12} lg={12}>
            <MentorCard
              name={c.name}
              current_compant={c.current_compant}
              email={c.email}
              experience={c.experience}
              linkedin={c.linkedin}
              photo={c.photo}
              mentor_priority={mentor_priority}
              message={message}
              obj={obj}
            />
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <Loading />
  );
};

export default ChooseMentor;
