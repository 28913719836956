import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";
import {List, Box} from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";
import {Colors} from "../../../../assets/Colors"
import ReactGA from "react-ga"
const Enrollment = ()=> {
  const eventTrack = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }
  return (
    <>
    <div style= {{marginTop : "30px !important"}}>
    <Accordion sx={{backgroundColor : Colors.primaryLite, mb : 2}}  onClick={eventTrack.bind(this, "engagement", "View About Bosscoder", "Enrollment_page")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontSize : "24px !important"}}> About Bosscoder</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Bosscoder is a one stop destination to transform and accelerate your career growth.
          We are on a mission to make you the world’s best software engineer.

          As a part of the program, you will have access to:
          <List>

          <ul>
            <li>Network of people working at top product based companies.</li>
            <li>1:1 mentorship from tech leaders.</li>
            <li>Live Interactive classes to keep you structured and consistent.</li>
            <li>Daily assignment and homework to keep pushing you every day.</li>
            <li>Regular Mock interviews to make you interview-ready.</li>
            <li>Teaching Assistant (TA) Support 24/7 to help you resolve your doubts.</li>
            <li>An advanced Placement support team to help you with opportunities at top product-based companies.</li>
          </ul>
          </List>
          </Typography>
        </AccordionDetails>
      </Accordion>
    <Accordion sx={{backgroundColor : Colors.primaryLite,mb : 2}} onClick={eventTrack.bind(this, "engagement", "Experience Bosscoder", "Enrollment_page")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontSize : "24px !important"}}>Experience Bosscoder</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We created a small video from real feedback on the course structure and curriculum. 
          </Typography>
          <Box sx={{ p: 2 }}>
                <Vimeo video="https://vimeo.com/721844243" autopause responsive />
            </Box>
        </AccordionDetails>
      </Accordion>
    <Accordion sx={{backgroundColor : Colors.primaryLite, mb : "16px!important"}} onClick={eventTrack.bind(this, "engagement", "Know Onbording", "Enrollment_page")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontSize : "24px !important"}}>Know Your Onbording team</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{mt : "-24px!important"}}>
          <List>
          <Typography>
            <ul>
              <li>Kapil Patidar</li>
              <li>Shadabur Rahman</li>
                 

    Queries and escalations → help@bosscoderacademy.com

            </ul>
          </Typography>
          </List>
        </AccordionDetails>
      </Accordion>
   
      </div>
    </>
  )
}

export default Enrollment