import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";
 
function extractQuestionName(name) {
  var questionName = "";
  for (let i = name.length - 2; i >= 0; i--) {
    if (name[i] === "/") {
      break;
    }

    if (name[i] === "-") {
      questionName = " " + questionName;
    } else {
      questionName = name[i] + questionName;
    }
  }
  return questionName
    .trim()
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

function QuestionCard({ name, content }) {
  return (
    <div>
      <Typography varient="h5" sx={{ fontWeight: "bold" }}>
        {name}
      </Typography>

      <Divider />

      <List sx={{ bgcolor: "background.paper" }}>
        {content.map((value) => (
          <div key={value}>
            <ListItem
              button
              component="a"
              href={value}
              target="_blank"
              disableGutters
            >
              <ListItemText primary={extractQuestionName(value)} />
            </ListItem>
            <Divider component="li" />
          </div>
        ))}
      </List>
    </div>
  );
}

export default QuestionCard;
