import React, { useEffect, useState } from "react";
import { Tooltip, Button } from "@mui/material";
import lightning from "../../../assets/lightning.gif";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
const Streak = () => {
  const auth = isAuthenticated()?.data;
  const [streak, setStreak] = useState();
  const [points, setPoints] = useState();
  const navigate = useNavigate();
  let isMounted = true;
  const getStreakandPoints = () => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/get_points", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (isMounted) {
                setStreak(res?.data.streak);
                setPoints(res?.data?.points);
                localStorage.setItem(
                  "streakData",
                  JSON.stringify(res?.data?.streak)
                );
                localStorage.setItem(
                  "rewardData",
                  JSON.stringify(res?.data?.points)
                );
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };
  useEffect(() => {
    try {
      if (
        localStorage.getItem("rewardData") &&
        localStorage.getItem("streakData") &&
        localStorage.getItem("rewardData") !== undefined &&
        localStorage.getItem("streakData") !== undefined
      ) {
        setStreak(JSON.parse(localStorage.getItem("streakData")));
        setPoints(JSON.parse(localStorage.getItem("rewardData")));
      }
      getStreakandPoints();
    } catch {
      getStreakandPoints();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Tooltip
        title="Earn 10000 coins to get Amazon voucher worth Rs. 100. 1 question = 100 Points  and 1 chapter completion = 100 points"
        placement="bottom"
      >
        <Button
          sx={{
            cursor: "pointer",
            // position: "sticky",
            float: "left",
            size: 10,
            border: 0.2,
            borderRadius: 2,
            mr: 2,
          }}
        >
          <Box
            component="img"
            sx={{
              height: 23,
              width: 35,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt="rewards"
            src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Fcoins.gif?alt=media&token=d46acdc2-ee3f-4e2e-8756-cf669158a570"
          />
          <Typography>{points}</Typography>
        </Button>
      </Tooltip>

      <Button
        sx={{
          cursor: "pointer",
          position: "sticky",
          size: 10,
          border: 0.2,
          borderRadius: 2,
        }}
      >
        <Box
          component="img"
          sx={{
            height: 23,
            width: 35,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="rewards"
          src={lightning}
        />
        <Typography sx={{ ml: 1 }}>{streak}</Typography>
      </Button>
    </>
  );
};

export default Streak;
