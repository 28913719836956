import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LockIcon from '@mui/icons-material/Lock';
import Locking from '../Lock/Locking';

export default function StudentMentorSessions() {
    const [value, setValue] = React.useState('1');

    const [cancelBooking, setCancelBooking] = React.useState(false)
    const [cancelDate, setCancelDate] = React.useState("")
    const [cancelStart, setCancelStart] = React.useState("")
    const [cancelEnd, setCancelEnd] = React.useState("")

    const [viewFeedback, setViewFeedback] = React.useState(false)
    const [mentorFeedbackAvailable, setMentorFeedbackAvailable] = React.useState(false)
    const [studentFeedbackAvailable, setStudentFeedbackAvailable] = React.useState(false)
    const [studentFeedback, setStudentFeedback] = React.useState(0)
    const [studentActionItems, setStudentActionItems] = React.useState("")

    const [writeFeedback, setWriteFeedback] = React.useState(false)
    const [mentorRating, setMentorRating] = React.useState(0)
    const [mentorComment, setMentorComment] = React.useState("")
    const [roomId, setRoomId] = React.useState("")

    const [allSessions, setAllSessions] = React.useState([])
    const auth = isAuthenticated()?.data;
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .post("/common/refresh_token", {
                refresh_token: auth?.refreshToken,
            })
            .then((res) => {
                if (res?.data?.access_token) {
                    var existing = JSON.parse(localStorage.getItem("jwt"));
                    existing.data.idToken = res?.data?.access_token;
                    localStorage.setItem("jwt", JSON.stringify(existing));
                    axios
                        .post("/student/get_mentor_sessions", {
                            id_token: res?.data?.id_token,
                        })
                        .then((res) => {
                            setAllSessions(res.data.response.mentor_sessions)
                        });
                } else {
                    signout(auth?.localId, () => {
                        navigate("/");
                    });
                }
            });
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function sessionDone(done_state) {
        return function (x) {
            return x.done == done_state;
        }
    }

    const view_feedback = (row) => {
        setViewFeedback(true)
        if (row.mentor_feedback) {
            setMentorFeedbackAvailable(true)
            if (row.student_feedback) {
                setStudentFeedbackAvailable(true)
                setStudentFeedback(row.student_feedback.feedback)
                setStudentActionItems(row.student_feedback.action_items)
                setCancelDate(row.date);
                setCancelStart(row.start);
                setCancelEnd(row.end);
            } else {
                setStudentFeedbackAvailable(false)
                setStudentFeedback("")
                setStudentActionItems("")
            }
        }
        else {
            setMentorFeedbackAvailable(false)
        }
    };

    const handleViewDialogClose = (event) => {
        setViewFeedback(false)
    }

    const write_feedback = (row) => {
        setWriteFeedback(true)
        if (row.mentor_feedback) {
            setMentorRating(row.mentor_feedback.rating)
            setMentorComment(row.mentor_feedback.comments)
        } else {
            setMentorRating(0)
            setMentorComment("")
        }
        setCancelDate(row.date);
        setCancelStart(row.start);
        setCancelEnd(row.end);
        setRoomId(row.room_id)
    };

    const handleWriteDialogClose = (event) => {
        setWriteFeedback(false)
    }

    const handleSubmit = (event) => {
        axios
            .post("/student/write_mentor_feedback", {
                room_id: roomId,
                rating: mentorRating,
                comments: mentorComment
            })
            .then((res) => {
                setWriteFeedback(false)
                let all_sessions = [...allSessions]
                var temp_element;
                var id = 0;
                for (var i = 0; i < all_sessions.length; i++) {
                    if (all_sessions[i].room_id == roomId) {
                        id = i;
                        temp_element = all_sessions[i]
                    }
                }
                temp_element.mentor_feedback = {
                    rating: mentorRating,
                    comments: mentorComment
                }
                all_sessions[id] = temp_element;
                setAllSessions(all_sessions)

            });
    }

    const UpcomingComponent = <div>
        {allSessions.filter(sessionDone(false)).map((row) => {
            return (
                <div>
                    <Card style={{ padding: "30px", backgroundColor: "FBFCF8" }}>
                        <h2 style={{ color: "grey" }}> Mock Interview  </h2>
                        <hr />

                        <div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <CalendarMonthIcon />
                                <span style={{ marginLeft: "5px" }}> {row.date}</span>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <AccessTimeIcon />
                                <span style={{ marginLeft: "5px" }}>{row.start}:00 - {row.end}:00</span>
                            </div>
                            <hr />
                        </div>
                        <Grid container>
                            <Grid item xs={10}>
                                {row.mentor_id}
                            </Grid>
                            <Grid item xs={2} float="right">
                                <Button variant="contained">
                                    <a href={row.meet_link} target="_blank" style={{ color: "white" }}>
                                        Join meeting
                                    </a>
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </div >
            )
        })}
    </div >

    const PastComponent = <div>
        {allSessions.filter(sessionDone(true)).map((row) => {
            return (
                <div>
                    <Card style={{ padding: "30px", backgroundColor: "FBFCF8" }}>
                        <h2 style={{ color: "grey" }}> Mock Interview  </h2>
                        <hr />
                        <div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <CalendarMonthIcon />
                                <span style={{ marginLeft: "5px" }}> {row.date}</span>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <AccessTimeIcon />
                                <span style={{ marginLeft: "5px" }}>{row.start}:00 - {row.end}:00</span>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <TimelapseIcon />
                                <span style={{ marginLeft: "5px" }}>{row.duration} minutes </span>
                            </div>
                            <hr />
                        </div>
                        <Grid container>
                            <Grid item xs={8}>
                                {row.mentor_id}
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" onClick={() => view_feedback(row)}> View Feedback </Button>
                                <Dialog
                                    open={viewFeedback}
                                    onClose={handleViewDialogClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Feedback Details"}
                                    </DialogTitle>
                                    <DialogContent>
                                        {mentorFeedbackAvailable ?
                                            <div>
                                                {studentFeedbackAvailable ?
                                                    <div>
                                                        Feedback for the mentor session on {cancelDate} from {cancelStart}:00 to {cancelEnd}:00
                                                        <hr />
                                                        <Typography component="legend"> Feedback</Typography>
                                                        <div style={{ padding: "15px 5px", border: "1px solid grey" }}> {studentFeedback} </div>
                                                        <br />
                                                        <Typography component="legend"> Action Items </Typography>
                                                        <div style={{ padding: "15px 5px", border: "1px solid grey" }}> {studentActionItems} </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        Your Mentor haven't written your feedback yet!
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div style={{ textAlign: "center", color: "grey" }}>
                                                <hr />
                                                <LockIcon fontSize="large" style={{ marginTop: "10px", }} />
                                                <p> Provide the mentor Feedback to unlock your feedback </p>
                                            </div>
                                        }
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleViewDialogClose}> Close </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                            <Grid item xs={2} float="left">
                                <Button variant="contained" onClick={() => write_feedback(row)}>
                                    Mentor feedback
                                </Button>
                                <Dialog
                                    open={writeFeedback}
                                    onClose={handleWriteDialogClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Feedback Details"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <div>
                                            Mentor Feedback for the mentor session on {cancelDate} from {cancelStart}:00 to {cancelEnd}:00
                                            <hr />
                                            
                                            <Typography component="legend"> Mentor's Rating</Typography>
                                            <Rating name="read-only"
                                            value={mentorRating} 
                                            onChange={(event, newValue) => {
                                                setMentorRating(newValue);
                                              }}/>
                                            <br />
                                            <Typography component="legend"> Additional Comments </Typography>
                                            <TextField id="outlined-basic"
                                                variant="outlined"
                                                value={mentorComment}
                                                onChange={(event) => {
                                                    setMentorComment(event.target.value);
                                                  }}
                                                multiline
                                                style={{ width: "100%" }} />
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleWriteDialogClose}> Close </Button>
                                        <Button onClick={handleSubmit}> Submit </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </Card>
                </div >
            )
        })}
    </div>

    return auth.payment_status === "PAID" ? (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <h1> Mentor Sessions </h1>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Upcoming" value="1" />
                        <Tab label="Past" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1"> {UpcomingComponent} </TabPanel>
                <TabPanel value="2"> {PastComponent} </TabPanel>
            </TabContext>
        </Box>
    ):
    
   <Locking/>
}