import React from "react";
import {
  Button,
  ButtonBase,
  Grid,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import InputAdornment from "@mui/material/InputAdornment";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const ImgLocked = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  opacity: "50%",
});

const CourseCard = (props) => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const {
    video_link,
    zoom_link,
    date,
    id,
    image_link,
    is_locked,
    topic,
    batch,
    module_name,
  } = props;
  let extractNo = 0;

  if (video_link?.length > 0) extractNo = video_link?.replace(/^\D+/g, "");
  else extractNo = 0;

  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState(date ? date : "");
  const [recordings, setRecordings] = useState(video_link ? extractNo : "");
  const [zoom_links, setZoom_links] = useState(zoom_link ? zoom_link : "");
  const [lock, setLock] = useState(is_locked);
  const [successToast, setsuccessToast] = useState(false);
  const [errorToast, seterrorToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleToastClose = (e) => {
    setsuccessToast(false);
    seterrorToast(false);
  };

  let obj = {
    batch: batch,
    id: id,
    vimeo_link: ("https://player.vimeo.com/video/" + recordings).trim(),
    date: dates,
    module_name: module_name,
    zoom_link: zoom_links,
    is_locked: lock,
  };
  const sendResponse = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios.post("/admin/update_course_v3", obj).then((res) => {
         
            if (res?.data?.success) {
              setLoading(false);
              setsuccessToast(true);
            } else {
              setTimeout(() => {
                setLoading(false);
              }, 200);
              seterrorToast(true);
              setLoading(false);
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  return (
    <>
      <div>
        <Snackbar
          open={successToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Chapter Updated Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
      </div>

      <div className={!is_locked ? "course-card-container" : "course"}>
        <Paper
          sx={{ p: 2, margin: "auto", flexGrow: 1, mb: -4 }}
          variant="outlined"
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 303, height: 193 }} disabled={true}>
                {!is_locked && (
                  <Img
                    alt="complex"
                    src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/1_test.jpeg?alt=media&token=33cb83bc-6863-4c56-8ed4-1c58085029a0"
                  />
                )}
                {is_locked && <ImgLocked alt="complex" src={image_link} />}
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ fontSize: 20, fontWeight: 500 }}
                  >
                    {topic}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ fontSize: 16, fontWeight: 200 }}
                  >
                    Master Class and Practice Question
                  </Typography>
                  <Typography gutterBottom sx={{ fontSize: 16 }}>
                    Recording : {video_link}
                  </Typography>
                  <Typography gutterBottom sx={{ fontSize: 16 }}>
                    Date : {date}
                  </Typography>
                  <Typography
                    paragraph={true}
                    gutterBottom
                    sx={{ fontSize: 16 }}
                  >
                    Zoom Link : {zoom_link}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <Button variant="outlined" onClick={handleClickOpen}>
                Edit course
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle> Edit Course {topic} </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} columns={12}>
              <Grid item xs={6}>
                <TextField
                  select
                  id="is_lock"
                  label="is_lock"
                  value={lock == true ? true : false}
                  variant="outlined"
                  onChange={(e) => setLock(e.target.value)}
                  style={{ margin: "10px 15px", width: "90%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value={true} id="is_lock">
                    True
                  </MenuItem>
                  <MenuItem value={false} id="is_lock">
                    False
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="Date"
                  type="date"
                  onChange={(e) => setDates(e.target.value)}
                  value={dates}
                  halfWidth
                  style={{ margin: "10px 15px", width: "90%" }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={12}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  id="zoom_link"
                  label="zoom_link"
                  type="text"
                  onChange={(e) => setZoom_links(e.target.value)}
                  value={zoom_links}
                  halfWidth
                  style={{ margin: "10px 15px", width: "90%" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  id="recording"
                  label="recording"
                  type="text"
                  error ={recordings.length > 25 ? true : false }
                  onChange={(e) => setRecordings(e.target.value)}
                  value={recordings}
                  halfWidth
                  style={{ margin: "10px 15px", width: "90%" }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <ColorButton
              onClick={sendResponse}
              variant="contained"
              loadingPosition="center"
              loadingIndicator="Submiting…"
              loading={loading}
              sx={{ marginRight: 4, width: "20%" }}
            >
              Submit
            </ColorButton>
          </DialogActions>
        </Dialog>
        <Dialog
          // open={this.state.open2}
          // onClose={this.handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you Sure?"}</DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
                            you have changed the following fields :
                            {this.state.fields_changes.map((data) =>
                                <p style={{ color: "red" }}>  {data},  </p>
                            )}
                        </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}> Cancel </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default CourseCard;
