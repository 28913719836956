import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { Paper, Typography, Button } from "@mui/material";
import { Colors } from "../../../../assets/Colors";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import axios from "../../../../api/axios";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MenteeInfo = (props) => {
  const { batch, phone, date, email, last_leetcode_count, name } = props;
  const timer = React.useRef();
  const [loading, setLoading] = useState(false);
  const [successToast, setsuccessToast] = useState(false);
  const [errorToast, seterrorToast] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
  }));
  const handleToastClose = (e) => {
    setsuccessToast(false);
    seterrorToast(false);
  };
  const handleCancelDialogClose = (e) => {
    setOpen(false);
  };
  const SubmitResponse = (e) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 1000);
    e.preventDefault();
    axios
      .post("mentors/allow_extra_mentor_session_v3", { email: email })
      .then((res) => {
        if (res?.data?.success) {
          setsuccessToast(true);
          setLoading(false);
          setOpen(false);
        } else {
          seterrorToast(true);
          setLoading(false);
        }
      });
  };

  return (
    <>
      <div>
        <Snackbar
          open={successToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Success!
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
      </div>

      <Paper
        sx={{
          p: 4,
          mb: 2,
          flexGrow: 1,
          maxWidth: "100%",
          mr: 2,
          backgroundColor: Colors.primaryLite,
        }}
        variant="outlined"
      >
        <Typography sx={{ ml: 2, mt: 2, fontSize: 20, fontWeight: 700 }}>
          {name}
        </Typography>
        <Typography sx={{ ml: 2, fontSize: 14, fontWeight: 400 }}>
          Email : {email}
        </Typography>
        <Typography sx={{ ml: 2, fontSize: 14, fontWeight: 400 }}>
          Batch : {batch}
        </Typography>
        <Typography sx={{ ml: 2, fontSize: 14, fontWeight: 400 }}>
          Phone No : {phone}
        </Typography>
        <Typography sx={{ ml: 2, fontSize: 14, fontWeight: 400 }}>
          Last session date : <Moment format="MMMM Do YYYY">{date}</Moment>
        </Typography>
        <Typography sx={{ ml: 2, fontSize: 14, fontWeight: 400 }}>
          Leetcode count : {last_leetcode_count}
        </Typography>

        <ColorButton
          onClick={() => {
            setOpen(true);
          }}
          variant="contained"
          loadingPosition="center"
          sx={{ ml: 2, mt: 2 }}
        >
          Enable Extra Session
        </ColorButton>
        <Dialog open={open}>
          <DialogTitle style={{ backgroundColor: "#007FFF", color: "white" }}>
            {" "}
            Are you Sure ?{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ margin: "20px 0px" }}>
              This will allow the mentee to book another mentor session in this
              month. Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={handleCancelDialogClose}
            >
              {" "}
              NO{" "}
            </Button>
            <ColorButton
              onClick={SubmitResponse}
              variant="contained"
              loadingIndicator="yes"
              loading={loading}
              sx={{ mr: 2 }}
            >
              Yes
            </ColorButton>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
};

export default MenteeInfo;
