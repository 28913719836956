import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {
  Autocomplete,
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import Loading from "../../notificationPages/Loading";
import SessionCard from "./components/SessionCard";

const UpdateSessions = () => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const [allSessions, setAllSessions] = useState();

  useEffect(() => {
    let isMounted = true;
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/mentors/get_sessions", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              setAllSessions(res?.data?.response?.sessions);
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return allSessions ? (
    <div>
      <Grid container spacing={2}>
        {allSessions.map((c, i) => (
          <Grid key={i} item xs={12}>
            <SessionCard
              data={c}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  ) : (
    <Loading />
  );
};

export default UpdateSessions;
