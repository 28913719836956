import axios from "./axios";
import axiosRetry from "axios-retry";
import { RETRIES } from "./axios";

axiosRetry(axios, {
  retries: RETRIES,
  shouldResetTimeout: true,
  retryCondition: (_error) => true, // retry no matter what
});

export const addUser = async (data) => {
  return await axios.post("/v4_signup", data);
};

export const createBatch = async (data) => {
  return await axios.post("/admin/create_batch_v3", data);
};

export const getAllStudents = async (data) => {
  return await axios.post("/admin/get_all_students", data);
};

export const updateStudent = async (data) => {
  return await axios.post("/admin/update_student", data);
};

export const getAllMentors = async () => {
  return await axios.get("/mentors/get_all_mentors");
};

export const sendEmail = async (data) => {
  return await axios.post("/admin/send_login_details", data);
};

export const updateDisableState = async (data) => {
  return await axios.post("/admin/update_disable_state", data);
};

export const paymentStatus = async (data) => {
  return await axios.post("/admin/convert_to_paid_user", data);
};

export const getTopStudents = async (data) => {
  return await axios.post("/admin/get_top_100_students", data);
};

export const getNextStudents = async (data) => {
  return await axios.post("/admin/get_next_100_students", data);
};

export const getStudentDetails = async (data) => {
  return await axios.post("/admin/userprofile", data);
};

export const getMentorDetails = async (data) => {
  return await axios.post("/admin/mentorprofile", data);
};

export const updateStudentDetails = async (data) => {
  return await axios.post("/admin/update_user_profile", data);
};

export const updateMentorDetails = async (data) => {
  return await axios.post("/admin/update_mentor_profile", data);
};

export const getMentorsList = async (data) => {
  return await axios.post("/admin/get_all_mentors", data);
};

export const getMentorsListDetails = async (data) => {
  return await axios.post("/admin/get_all_mentors_details", data);
};

export const disableMentor = async (data) => {
  return await axios.post("/admin/update_mentor_disable_state", data);
};

export const createQuestion = async (data) => {
  return await axios.post("/admin/create_question", data);
};

export const editQuestions = async (data) => {
  return await axios.post("/admin/edit_question", data);
};

export const addSolutions = async (data) => {
  return await axios.post("/admin/add_solution", data);
};

export const enableIsInstructor = async (data) => {
  const headers = { "Content-Type": "application/json" };
  return await axios.post("/admin/enable_is_instructor", data, { headers });
};
