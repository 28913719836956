import React from "react";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10
}));

const ProgressBar = (props) => {
  const { progress, total } = props;

  return (
    <Paper sx={{ p: 2, marginBottom: 2, flexGrow: 1 }} variant="outlined">
      <Typography
        variant="h5"
        component="div"
        sx={{ fontSize: 24, fontWeight: 400 }}
      >
        Bosscoder Academy Curriculum
      </Typography>
      <Typography
        sx={{ mb: 1.5, fontSize: 16, fontWeight: 300 }}
        color="text.secondary"
      >
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <BorderLinearProgress
          variant="determinate"
          value={progress}
        />
      </Box>
    </Paper>
  );
};

export default ProgressBar;
