import React, { useState, useEffect } from "react";
import { Paper, Typography, Grid } from "@mui/material";

import { isAuthenticated, signout } from "../../../api/authApi";
import ResumeChecklist from "./components/ResumeChecklist";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import FeatureLocked from "./components/FeatureLocked";
import Loading from "../../notificationPages/Loading";
import Locking from "../Lock/Locking";
import ReactGa from "react-ga";
const Resume = () => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();

  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  let isMounted = true;
  const getPermission = () => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/resume_review_permission", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (isMounted) {
                setPermission(res?.data?.eligibility);
                localStorage.setItem(
                  "resume_lock",
                  JSON.stringify(res?.data?.eligibility)
                );
                setLoading(false);
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  useEffect(() => {
    let isMounted = true;
    ReactGa.pageview(window.location.pathname);
    try {
      if (
        localStorage.getItem("resume_lock") &&
        localStorage.getItem("resume_lock") !== undefined
      ) {
        setPermission(JSON.parse(localStorage.getItem("resume_lock")));
        setLoading(false);
      }
      getPermission();
    } catch {
      getPermission();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return loading ? (
    <Loading />
  ) : auth.payment_status !== "PAID" ? (
    <Locking />
  ) : permission ? (
    <>
      <Typography
        variant="h5"
        component="h5"
        sx={{ textAlign: "center", mb: 4, fontWeight: 600 }}
      >
        Your Resume
      </Typography>
      <Paper sx={{ p: 2, marginBottom: 2, flexGrow: 1 }} variant="outlined">
        <Grid container spacing={2}>
          <Grid item>
            <ResumeChecklist />
          </Grid>
          <Grid sx={{ ml: 18, mt: 10 }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FResume%2Fres.svg?alt=media&token=72c54142-8500-4147-846b-0c3c1d8c5de3" />
          </Grid>
        </Grid>
      </Paper>
    </>
  ) : (
    <FeatureLocked />
  );
};

export default Resume;
