import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { Box } from "@mui/system";
import { Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    padding: "40px",
  },
});
const drawerWidth = 240;

const Admin = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet/>
      </Box>
    </div>
  );
};

export default Admin;
