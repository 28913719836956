import React from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { batch, role } from "../../../assets/Data";
import { isAuthenticated } from "../../../api/authApi";
import { addUser } from "../../../api/adminApi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserSignup = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const response = addUser({
      email: data.get("email"),
      phone: data.get("phone"),
      batch: data.get("batch"),
      fees: parseInt(data.get("fees")),
      name: data.get("name"),
      course: data.get("course"),
      role: data.get("role"),
      experience: data.get("experience"),
      admin_uid: isAuthenticated()?.data?.localId,
    })
      .then((res) => {
        if (res?.data?.password) {
          toast.success("Student added successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(res?.data?.error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => console.log(err));

  };
  // email, phone, batch, fees, name, course, role, uid
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <ToastContainer />
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Add Student
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Course Type</InputLabel>
                <Select
                  name="course"
                  label="Course Type"
                  defaultValue={"student"}
                >
                  <MenuItem value={"student"}>Student</MenuItem>
                  <MenuItem value={"working"}>Working Professional</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Batch</InputLabel>
                <Select name="batch" label="Batch" defaultValue={batch[0]}>
                  {batch.map((b, index) => (
                    <MenuItem value={b} key={index}>
                      {b}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="experience"
                label="Experience"
                name="experience"
                autoComplete="experience"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="fees"
                label="Fees Amount"
                name="fees"
                autoComplete="fees"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select name="role" label="role" defaultValue={role[0]}>
                  {role.map((r, index) => (
                    <MenuItem value={r} key={index}>
                      {r}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UserSignup;
