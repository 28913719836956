import React from "react";
import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  Grid,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { toast, ToastContainer } from "react-toastify";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "80%",
  maxHeight: "100%",
});

const ImgLocked = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  opacity: "50%",
  // [theme.breakpoints.down('md')]: {

  //   maxWidth : "50%",
  //   height : "50%",
  // }
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CourseCard = (props) => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const { companyName, img, experienceRequired, jobTitle } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [loading, setLoading] = React.useState(false);

  function handleClick() {
    if (auth.payment_status !== "PAID") {
      toast.error("This is Premium Feature", {
        position: "bottom-left",
        autoClose: 5000,
        // hideProgressBar: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // draggable: true,
        // progress: undefined,
      });
    }
  }

  return (
    <>
      <Paper
        sx={{ p: 2, margin: "auto", flexGrow: 1, mb: 2, '&:hover': {
          boxShadow : 5,
        } }}
        variant="outlined"
      >
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase sx={{ width: 253, height: 143 }} disabled={true}>
              <Img alt="complex" src={img} />
              {/* {is_locked && <ImgLocked alt="complex" src={microsoft}/>} */}
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ fontSize: 20, fontWeight: 500 }}
                >
                  {companyName}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ fontSize: 16, fontWeight: 200, mt: 2 }}
                >
                  {experienceRequired}
                </Typography>
                <Typography paragraph={true} gutterBottom sx={{ fontSize: 16 }}>
                  {jobTitle}
                </Typography>
                <Typography
                  paragraph={true}
                  gutterBottom
                  align="justify"
                  sx={{ fontSize: 16 }}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>
          <ToastContainer />
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            {auth.payment_status === "PAID" ? (
              <Button
                size="small"
                href="https://docs.google.com/forms/d/1CojriSy3a2n6EAWWRvTaOSMJY413aUOzY7NNAhzT3-0/edit"
                sx={{ mr: 2 }}
                target="_blank"
                variant="contained"
              >
                Referral
              </Button>
            ) : (
              <Button
                size="small"
                variant="contained"
                onClick={handleClickOpen}
              >
                Referral
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Premium Feature
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <img
              style={{ width: "100%" }}
              src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Flock2.svg?alt=media&token=f9d417c3-213e-405e-92c5-e86900a1e262"
              alt=""
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </div>
      {/* </Link> */}
      {/* // </div> */}
    </>
  );
};

export default CourseCard;
