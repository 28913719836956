import {  Paper, Box } from "@material-ui/core";
import { Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import {Colors} from "../../../../assets/Colors"
const DescribeLeaderboard = (props) => {
  const { name } = props;

  const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: Colors.secoundaryLite,
      borderColor : Colors.secoundaryBackgorund,
    },
    
  }));

  const classes = useStyles();
  return (
    <>
      <Paper
        className={classes.paper}
        sx={{ flexGrow: 1}}
        variant="outlined"
      >
        <Box display={"flex"}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FLeaderboard%2FTrophy.svg?alt=media&token=e937429b-bf90-40dd-9d1a-422a48d0e870"
            alt=""
            style={{ marginLeft: "22px", marginTop: "11px" }}
          />
          <Box sx={{ alignText: "center" }}>
            <Typography
              // className={classes.typography}
              sx={{ fontSize: 30, marginLeft: "80px", marginTop: "80px" }}
            >
              {name}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default DescribeLeaderboard;
