import React, { Component } from "react";
import { Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import Tooltip from "@mui/material/Tooltip";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import Stack from "@mui/material/Stack";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

export default class Solutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testCases: this.props.runCodeSubmission,
      drawerValue: "1",
    };
  }
  render() {
    const {
      likes,
      liked,
      tags,
      question_number,
      problem_name,
      solutions,
      disliked,
      dislikes,
      question_details,
      changeDislikeButtonToFalse,
      changeDislikeButtonToTrue,
      changeLikeButtonToFalse,
      changeLikeButtonToTrue,
      difficulty_level,
    } = this.props;

    const BootstrapTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        paddingLeft: "32px",
        paddingRight: "32px",
        borderRadius: "8px",
      },
    }));
    return (
      <>
        <Chip
          label={
            difficulty_level[0]?.toUpperCase() + difficulty_level?.substring(1)
          }
          sx={{
            backgroundColor: "#4ADE80",
            color: "white",
            padding: "4px",
            height: "24px",
            mb: 2,
            mt: 6,
          }}
        />
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent="end"
          alignItems="end"
          sx={{ flexWrap: "wrap", marginTop: 6, width: "50%", float: "right" }}
        >
          <span style={{}}>
            <BootstrapTooltip title={likes} placement="top-start">
              {liked ? (
                <ThumbUpAltIcon
                  style={{ marginTop: "4px", color: "#A3A3A3" }}
                  onClick={changeLikeButtonToFalse}
                />
              ) : (
                <ThumbUpOffAltIcon
                  style={{ marginTop: "4px", color: "#A3A3A3" }}
                  onClick={changeLikeButtonToTrue}
                />
              )}
            </BootstrapTooltip>
          </span>

          <span style={{}}>
            <BootstrapTooltip title={dislikes} placement="top-start">
              {disliked ? (
                <ThumbDownAltIcon
                  style={{
                    marginTop: "4px",
                    marginLeft: "10px",
                    color: "#A3A3A3",
                  }}
                  onClick={changeDislikeButtonToFalse}
                />
              ) : (
                <ThumbDownOffAltIcon
                  style={{
                    marginTop: "4px",
                    marginLeft: "10px",
                    color: "#A3A3A3",
                  }}
                  onClick={changeDislikeButtonToTrue}
                />
              )}
            </BootstrapTooltip>
          </span>
          {/* <span style={{}}>
            <ShareOutlinedIcon
              sx={{ marginTop: "4px", color: "#A3A3A3", ml: "10px" }}
            />
          </span>
          <span style={{}}>
            <StarOutlineOutlinedIcon
              sx={{ marginTop: "4px", color: "#A3A3A3", ml: "10px" }}
            />
          </span> */}
        </Stack>

        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 700,
            mt: 1,
            mb: "14px",
            lineHeight: "21.94px",
            color: "#312E81",
          }}
        >
          {question_number}. {problem_name}
        </Typography>
        {/* <Stack sx={{ mt: 4, mb: 4 }}> */}

        <Stack
          direction={"row"}
          spacing={1}
          sx={{ flexWrap: "wrap", mt: 4, mb: 4 }}
        >
          <span>
            {tags.map((tag) => {
              return (
                <span style={{}}>
                  <Chip
                    label={tag[0]?.toUpperCase() + tag.substring(1)}
                    sx={{
                      height: "24px",
                      color: "#312E81",
                      borderColor: "#312E81",
                      pt: "4px",
                      pb: "4px",
                      pr: "21px",
                      pl: "21px",
                      mr: 2,
                      borderRadius: 1,
                    }}
                    variant="outlined"
                  />
                </span>
              );
            })}
          </span>
        </Stack>
        <div>
          <div dangerouslySetInnerHTML={{ __html: question_details }} />
        </div>

        {solutions.map((item) => {
          return (
            <>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 700,
                  mt: 1,
                  mb: "14px",
                  lineHeight: "21.94px",
                  color: "#312E81",
                }}
              >
                {item.heading === "Heading"
                  ? ""
                  : item.heading === "Subheading"
                  ? ""
                  : item.heading}
              </Typography>
              {item.heading.startsWith("Code") ? (
                <div
                  style={{
                    marginBottom: "5%",
                    backgroundColor: "white",
                    color: "black",
                    padding: "20px",
                    borderRadius: "8px",
                    overflow: "auto",
                    border: "3px solid #9A9A9A",
                  }}
                >
                  {/* <Button style={{float : "right", bottom : "16px"}}> Copy</Button> */}
                  <pre>
                    <code>{item.description}</code>
                  </pre>
                </div>
              ) : (
                <div
                  style={{
                    // marginBottom: "5%",
                    // backgroundColor: "white",
                    color: "black",
                    // padding: "20px",
                    // borderRadius: "8px",

                    overflow: "auto",
                  }}
                >
                  <Typography>
                    {item.heading === "Heading"
                      ? ""
                      : item.heading === "Subheading"
                      ? ""
                      : item.description}
                  </Typography>
                </div>
              )}
            </>
          );
        })}
      </>
    );
  }
}
