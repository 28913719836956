import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import RedeemRoundedIcon from '@mui/icons-material/RedeemRounded';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  LibraryBooks,
  Logout,
  Menu,
  Person,
  Event,
  IntegrationInstructions

} from "@mui/icons-material";

import Streak from "../user/Streak/Streak"
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { withRouter } from "../../helpers/withRouter";
import { isAuthenticated, signout } from "../../api/authApi";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const drawerWidth = 240;

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "80%",
  maxHeight: "80%",
});

const Sidebar = (props) => {
  const [colors, setColors] = useState("Profile");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [onbording, setOnbording] = useState();


  const auth = isAuthenticated()?.data;
  useEffect(() => {
    let isMounted = true;
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/get_onboarding_state", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (isMounted) {
                let onbording = res?.data.onboarding_state;
                setOnbording(res?.data.onboarding_state);
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);
  let lock_sidebar = parseInt((Object.values(onbording || {})[2]))
  const { window } = props;
  let navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

 
  const onbording_item = [

      {
        text: "Onboarding",
        icon: <FollowTheSignsIcon />,
        onClick: () => {
          navigate("/user/user-onbording")
          setColors("Onboarding")
        }
      },
      {
        text: "Logout",
        icon: <Logout />,
        onClick: () => {
          setColors("Logout")
          signout(isAuthenticated()?.data?.localId, () => {
            navigate("/");
          });
        },
      },

    ];

const itemsList = [
  {
    text: "Onboarding",
    icon: <FollowTheSignsIcon />,
    onClick: () => {
      navigate("/user/user-onbording")
      setColors("Onboarding")
    }
  },

  {
    text: "Profile",
    icon: <Person />,
    onClick: () => {
      navigate("/user/profile")
      setColors("Profile")
    }
  },
  {
    text: "Curriculum",
    icon: <LibraryBooks />,
    onClick: () => {
      navigate("/user/curriculum")
      setColors("Curriculum")
    }
  },
  {
    text: "Beginner Course (Optional)",
    icon: <IntegrationInstructions />,
    onClick: () => {
      navigate("/user/prerequisite-curriculum")
      setColors("Beginner Course (Optional)")
    }
  },
  {
    text: "My mentor",
    icon: <EscalatorWarningIcon />,
    onClick: () => {
      navigate("/user/select-mentor")
      setColors("My mentor")
    }
  },
  {
    text: "Book Mentor Session",
    icon: <CalendarMonthIcon />,
    onClick: () => {

      setColors("Book Mentor Session")
      navigate("/user/mentor-sesson-booking")
    }
  },
  {
    text: "All Mentor Sessions",
    icon: <Event />,
    onClick: () => {
      navigate("/user/all-mentor-sessions")
      setColors("All Mentor Sessions")
    }
  },

  {
    text: "Leaderboard",
    icon: <LeaderboardIcon />,
    onClick: () => {
      navigate("/user/leader-board")
      setColors("Leaderboard")
    }
  },
  {
    text: "Resume",
    icon: <FactCheckTwoToneIcon />,
    onClick: () => {
      navigate("/user/uploadResume")
      setColors("Resume")
    }
  },
  {
    text: "Placement",
    icon: <DirectionsRunIcon />,
    onClick: () => {
      navigate("/user/user-placement")
      setColors("Placement")
    }
  },
  {
    text: "Refer & Earn",
    icon: <RedeemRoundedIcon />,
    onClick: () => {
      navigate("/user/refer-and-earn")
      setColors("Refer & Earn")
    }
  },
  {
    text: "Logout",
    icon: <Logout />,
    onClick: () => {
      setColors("Logout")
      signout(isAuthenticated()?.data?.localId, () => {
        navigate("/");
      });
    },
  },
];

const drawer = (
  <div>
    <Toolbar>
      <Img
        src="https://uploads-ssl.webflow.com/60e1490057a19b506bd4ebb3/60fd54c519be8bccfd2922de_bosscoder_header_logo_2.svg"
        alt="logo"
      />
    </Toolbar>
    <Divider />
    <List>
      {lock_sidebar >= 10 ?
          itemsList.map((item) => {
            const { text, icon, onClick } = item;
            if (colors === item.text) {
              return (
                <ListItem button key={text} onClick={onClick} sx={{ color: "#1D99BE " }}>
                  {icon && <ListItemIcon onClick={onClick} sx={{ color: "#1D99BE " }}>{icon}</ListItemIcon>}
                  <ListItemText primary={text} />
                </ListItem>
              );
            }
            return (
              <ListItem button key={text} onClick={onClick}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} />
              </ListItem>
            );
           
           })
           :
           onbording_item.map((item) => {
            const { text, icon, onClick } = item;
            if (colors === item.text) {
              return (
                <ListItem button key={text} onClick={onClick} sx={{ color: "#1D99BE " }}>
                  {icon && <ListItemIcon onClick={onClick} sx={{ color: "#1D99BE " }}>{icon}</ListItemIcon>}
                  <ListItemText primary={text} />
                </ListItem>
              );
            }
            return (
              <ListItem button key={text} onClick={onClick}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} />
              </ListItem>
            );
           
          })
      }
   
    </List>
  </div>
);

const container =
  window !== undefined ? () => window().document.body : undefined;

return (
  <div>
    <Box sz={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        elevation={1}
        color="inherit"
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Menu />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, mr: 2, display: { sm: "none" } }}
          >
            Bosscoder Academy
          </Typography>
          <Streak/>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        // className = {{width: "500px"}}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  </div>
);
};

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default withRouter(Sidebar);
