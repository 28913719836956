import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {  Paper} from "@mui/material";
import {Colors} from "../../../../assets/Colors"
const LeadersList = (props) => {
  const { leaderboard } = props;
  

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.primaryLite,
      color: Colors.primaryMain,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even )": {
      backgroundColor: Colors.primaryLite,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));


 
  return (
    <>
     

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">Rank</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Batch</StyledTableCell>
              <StyledTableCell align="center">Total Count </StyledTableCell>
              <StyledTableCell align="center">
                Progress In Last Week
              </StyledTableCell>
              <StyledTableCell align="center">Total Points </StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {leaderboard.slice(0, 3).map((row, i) => (
              <StyledTableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {i + 1 === 1 ? (
                  <StyledTableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{ mt: -2 }}
                  >
                    {" "}
                    <div>
                      <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FLeaderboard%2FRank1.png?alt=media&token=7b0e2ab9-51f7-4ac3-9a93-2053704880f8" alt="" style={{ height: "40px",marginTop : "10px" }}/>

                    </div>
                  </StyledTableCell>
                ) : i + 1 === 2 ? (
                  <StyledTableCell
                    align="center"
                    component="th"
                    scope="row"
        
                  >
                    <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FLeaderboard%2FRank2.png?alt=media&token=1ff89c3f-39c4-4f60-b5e8-c070989147d5" alt="" style={{ height: "30px" }} />
                  </StyledTableCell>
                ) : i + 1 === 3 ? (
                  <StyledTableCell align="center" component="th" scope="row">
                    <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FLeaderboard%2FRank3.png?alt=media&token=fee023a5-e8aa-4264-b1d3-0921c857ece4" alt="" style={{ height: "30px" }} />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center" component="th" scope="row">
                    {" "}
                    {i + 1}
                  </StyledTableCell>
                )}

                <StyledTableCell align="center" component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {row.batch}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  
                >
                  {row.leetcode_count}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  
                >
                  {row.last_week_count}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                 
                >
                  {row.points}
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {leaderboard.slice(3, 2000).map((row, i) => (
              <StyledTableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell align="center" component="th" scope="row">
                  {" "}
                  {i + 4}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {" "}
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {" "}
                  {row.batch}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.leetcode_count}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.last_week_count}
                </StyledTableCell>
                <StyledTableCell align="center">{row.points}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LeadersList;
