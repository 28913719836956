import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
import DetailsTable from "./DetailsTable";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";

const ChooseDates = () => {
  const [startTime, setStartTime] = useState();
  const [endTime, setendTime] = useState();
  const [loading, setLoading] = useState();
  const [allSessions, setAllSessions] = useState();
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();

  const handleStartChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndChange = (event) => {
    setendTime(event.target.value);
  };

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
    right: 70,
  }));

  const requestResponse = () => {
    setLoading(true);
    let obj = {
      id_token: isAuthenticated()?.data?.idToken,
      start: startTime,
      end: endTime,
    };
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/mentors/fetch_monthly_mentor_session", obj)
            .then((res) => {
              setLoading(false);
              if (res?.data?.success) {
                setAllSessions(res?.data?.all_session);
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            component="h5"
            sx={{
              textAlign: "center",
              mb: 4,
              fontWeight: 600,
            }}
          >
            Mentor Sessions
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="someDate"
                label="From"
                InputLabelProps={{ shrink: true, required: true }}
                type="date"
                onChange={handleStartChange}
                value={startTime}
                sx={{width : "100%"}}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="someDate"
                label="To"
                InputLabelProps={{ shrink: true, required: true }}
                onChange={handleEndChange}
                type="date"
                value={endTime}
                sx={{width : "100%"}}
              />
              {startTime && endTime ? (
                <div>
                  <ColorButton
                    onClick={requestResponse}
                    variant="contained"
                    loadingPosition="center"
                    loadingIndicator="viewing…"
                    loading={loading}
                    sx={{ boxShadow: 5, mt: 3, pl: 4, pr: 4 }}
                  >
                    view
                  </ColorButton>
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      {allSessions ? <DetailsTable allSessions={allSessions} /> : <div></div>}
    </>
  );
};

export default ChooseDates;
