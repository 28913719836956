import axios from "axios";
const BASE_URL = 'https://heroes-dot-bosscoderplatformindia.el.r.appspot.com/'
// const BASE_URL = 'http://43.205.228.31'
// const BASE_URL = 'http://127.0.0.1:8080' 
export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate =  axios.create({
    baseURL: BASE_URL
})

export const RETRIES  = 5;