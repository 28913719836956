import React from "react";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  LibraryBooks,
  Logout,
  Menu,
  Person,
  StickyNote2,
} from "@mui/icons-material";
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { withRouter } from "../../helpers/withRouter";
import { isAuthenticated, signout } from "../../api/authApi";
import AddExtraClasses from "./addExtraClasses/AddExtraClasses";
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
const drawerWidth = 240;

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "80%",
  maxHeight: "80%",
});

const Sidebar = (props) => {
  const { window } = props;
  let navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const itemsList = [
    {
      text: "Profile",
      icon: <PersonOutlineOutlinedIcon />,
      onClick: () => navigate("/admin/profile"),
    },
    {
      text: "Student Dashboard",
      icon: <DashboardCustomizeOutlinedIcon />,
      onClick: () => navigate("/admin/student-dashboard"),
    },
    {
      text: "Mentor Dashboard",
      icon: <DashboardOutlinedIcon/>,
      onClick: () => navigate("/admin/mentor-dashboard"),
    },
    {
      text: "Questions Dashboard",
      icon: <LightbulbOutlinedIcon/>,
      onClick: () => navigate("/admin/questions-dashboard"),
    },
    {
      text: "Add Solutions",
      icon: <LightbulbOutlinedIcon/>,
      onClick: () => navigate("/admin/add-solutions"),
    },
    {
      text: "Add Student",
      icon: <PersonAddAlt1OutlinedIcon/>,
      onClick: () => navigate("/admin/user-signup"),
    },
    {
      text: "Add Batch",
      icon: <AddCircleOutlineOutlinedIcon/>,
      onClick: () => navigate("/admin/create-batch"),
    },
    {
      text: "Update Course",
      icon: <AutoAwesomeMotionOutlinedIcon />,
      onClick: () => navigate("/admin/update-course"),
    },
    // {
    //   text: "Add Class Video",
    //   icon: <  VideoCameraFrontIcon />,
    //   onClick: () => navigate("/admin/add-class-link"),
    // },
    {
      text: "Add Extra Class Video",
      icon: <  VideoLibraryOutlinedIcon />,
      onClick: () => navigate("/admin/add-other-class-link"),
    },
    {
      text: "Update Batch",
      icon: <SyncAltOutlinedIcon />,
      onClick: () => navigate("/admin/update-batch"),
    },
    {
      text: "Unlock Session",
      icon: <LockOpenOutlinedIcon />,
      onClick: () => navigate("/admin/unlock-session"),
    },
    {
      text: "Logout",
      icon: <Logout />,
      onClick: () => {
        signout(isAuthenticated()?.data?.localId, () => {
          navigate("/");
        });
      },
    },
  ];

  const drawer = (
    <div>
      <Toolbar>
        <Img
          src="https://uploads-ssl.webflow.com/60e1490057a19b506bd4ebb3/60fd54c519be8bccfd2922de_bosscoder_header_logo_2.svg"
          alt="logo"
        />
      </Toolbar>
      <Divider />
      <List>
        {itemsList.map((item) => {
          const { text, icon, onClick } = item;
          return (
            <ListItem button key={text} onClick={onClick}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box sz={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          color="inherit"
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <Menu />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, mr: 2, display: { sm: "none" } }}
            >
              Bosscoder Academy
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          // className = {{width: "500px"}}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default withRouter(Sidebar);
