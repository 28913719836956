import Container from "@mui/material/Container";
import React from 'react'

const BatchStarts = () => {
  return (
    <>
        <Container>
         <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FBatchStart.svg?alt=media&token=7f763d26-3f90-4f70-a15e-b6b672ed4296" alt="" style={{}}/>
        </Container>
    </>
  )
}

export default BatchStarts