import React from 'react'
import Container from "@mui/material/Container";
import ReactGA from "react-ga"
const ClassSchedule = () => {
  const eventTrack = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }
  return (
    <>
        <Container >
        <img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FOnbording%2FClassScheduleImg.svg?alt=media&token=1d53c890-22f6-41dd-9733-c578535d0ad9" alt="" onClick={eventTrack.bind(this, "Link", "Class-link", "Class Schedule Link")}/>
        </Container>
    </>
  )
}

export default ClassSchedule