import React from 'react'
import TimeSlot from './components/TimeSlot'
import { isAuthenticated, signout } from "../../../api/authApi";
import Locking from '../Lock/Locking';

const SessionBooking =  () => {

  const auth = isAuthenticated()?.data;
  return auth.payment_status === "PAID" ?(
    <>
       <TimeSlot/>
    </>
  ) :
<Locking/>
}

export default SessionBooking
