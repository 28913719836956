import React from "react";

const Locking = () => {
  return (
    <div align="center">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2FLocking_img.svg?alt=media&token=0f0cb1a7-b640-4584-8d85-f60f58b7a51a"
        alt="lock"
      />
    </div>
  );
}

export default Locking;
