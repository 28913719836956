import React, { useState } from "react";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Link, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import { Colors } from "../../../../assets/Colors";
import TextField from '@mui/material/TextField';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
}));

const NextButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: Colors.primaryMain,
  "&:hover": {
    backgroundColor: Colors.primaryButtonHover,
  },
  float: "right",
  top: 0,
}));

const ProgressBar = (props) => {
  const { progress, total , searchChapter, onSearchInputChange} = props;
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const markAttendance = () => {
    setLoading(true);
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/put_user_attendance", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (res?.data?.success) {
                setLoading(false);
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  return (
    <Paper sx={{ p: 2, marginBottom: 2, flexGrow: 1 }} variant="outlined">
      {/* {auth.batch === "B10A" ?
      <NextButton
        loadingIndicator="redirecting…"
        onClick={() => markAttendance()}
        sx={{ boxShadow: 5 }}
        target="_blank"
        href="https://us06web.zoom.us/j/85177965298?pwd=eEdDY0U1L2FqbDlQVmVWbmNPRUZ6dz09"
        variant="contained"
        loading={loading}
      >
        Join Class
      </NextButton>
       : <div></div>
      } */}
      <TextField sx={{float : "right", }} id="outlined-search" label="Search Chapter" type="text" value={searchChapter}
        onChange={onSearchInputChange}
      />
      <Typography
        variant="h5"
        component="div"
        sx={{ fontSize: 24, fontWeight: 400 }}
      >
        Bosscoder Academy Curriculum
      </Typography>
      <Typography
        sx={{ mb: 1.5, fontSize: 16, fontWeight: 300 }}
        color="text.secondary"
      >
        Current Progress {progress}/{total}
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Box>
    </Paper>
  );
};

export default ProgressBar;
