import React from "react";
import {Colors} from "../../../../assets/Colors"
import { Grid, Paper, Typography } from "@mui/material";

const StudentProgress = ({ total_leetcode_count, batch,last_week_count, points }) => {
  return (
    <>
        <Grid container spacing={2} columns = {5} >
          <Grid item xs={3} md={2} sx={{mt :1}}>
            <Paper sx={{height : "97px",bgcolor : "#084652", color : "white"}} variant="outlined">
              <Typography
                sx={{fontSize: 16, pt : 1, pl :2 }}
                textAlign={"left"}
                margin
              >
                TOTAL SOLVED
              </Typography>
              <Typography
                sx={{ color: "#20B2AA", fontSize: 26, fontWeight: 700, pl :2 }}
                textAlign={"left"}
                margin
              >
              
                {total_leetcode_count}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} md={2} sx={{mt :1}}>
            <Paper sx={{ height : "97px",bgcolor : Colors.secoundaryBackgorund, color : "white"}} variant="outlined"> 
              <Typography
                sx={{ color: "6B7B8A", fontSize: 16,bgcolor : Colors.secoundaryBackgorund, color : "white",pt : 1, pl :2 }}
                textAlign={"left"}
                margin
              >
                COINS
              </Typography>
              <Typography
                sx={{color: "white", fontSize: 26, fontWeight: 700,pl : 2 }}
                textAlign={"left"}
                margin
              >
                {points}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3} md={2} padding={0}>
            <Paper sx={{height : "97px", bgcolor : Colors.primaryMain, color : "white"}} variant="outlined">
              <Typography
                sx={{ color: "white", fontSize: 16,pt : 1, pl :2 }}
                textAlign={"left"}
                margin
              >
                THIS WEEK
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: 26, fontWeight: 700,pl : 2 }}
                textAlign={"left"}
                margin

             >
                {last_week_count}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sx={{}} padding={0}>
            <Paper sx={{height : "97px", bgcolor : Colors.primaryMain, color : "white"}} variant="outlined">
              <Typography
                sx={{ color: "6B7B8A", fontSize: 16, pt : 1, pl :2 }}
                textAlign={"left"}
                margin
              >
                BATCH
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: 26, fontWeight: 700, pl : 2 }}
                textAlign={"left"}
                margin
              >
                
                {batch}
              </Typography>
            </Paper>
          </Grid> 
       </Grid>
    </>
  );
};

export default StudentProgress;
