import React from "react";
import {
  Typography,
  Grid,
  styled,
  Paper,
  ButtonBase,
} from "@mui/material";
import { Colors } from "../../../../assets/Colors";
const PersonalInfo = (props) => {
  const { name, email, current_company, experience, photo } = props;
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "80%",
    maxHeight: "100%",
    borderRadius : "50%"
  });
  return (
    
    <>
      <Paper
        sx={{
          p: 2,
          bgcolor: Colors.primaryLite,
          margin: "auto",
          flexGrow: 1,
          mb: 2,
          // "&:hover": {
          //   boxShadow: 5,
          // },
          marginTop : 2
        }}
        variant="outlined"
      >
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase sx={{ width: 253, height: 143 }} disabled={true}>
              {/* <Img alt="complex" src={photo} /> */}
              <Img src={photo} alt="" srcset="" />
              {/* {is_locked && <ImgLocked alt="complex" src={microsoft}/>} */}
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: 500 }}
                >
                  Name : {name}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ fontSize: 16, fontWeight: 200 }}
                >
                  Email : {email}
                </Typography>
                <Typography  gutterBottom sx={{ fontSize: 16 }}>
                  Currunt Company : {current_company}
                </Typography>
                <Typography paragraph={true} gutterBottom sx={{ fontSize: 16 }}>
                  Experience : {experience} year
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          ></Grid>
        </Grid>
      </Paper>
      ;
    </>
  );
};

export default PersonalInfo;


//     <>
    //       <List
    //       >
    //         <Box sx={{  mt : -4, justifyContent: "center", display: "flex"}}>
    //
    //         </Box>
    //         <ListItem alignItems="flex-start">
    //           <ListItemText
    //             primary={
    //               <Typography sx={{ fontSize: 16, fontWeight: 600, mb : "8px" }}>
    //                Full Name
    //               </Typography>
    //             }
    //             secondary={<Typography sx={{ fontSize: 16 }}>{name}</Typography>}
    //           />
    //         </ListItem>
    //         <Divider variant="middle" component="li" />
    //         <ListItem alignItems="flex-start">
    //           <ListItemText
    //             primary={
    //               <Typography sx={{ fontSize: 16, fontWeight: 600 , mb : "8px",overflow :"hidden"}}>
    //                 Email
    //               </Typography>
    //             }
    //             secondary={<Typography sx={{ fontSize: 16 }}>

    //               {/* {email.length() <= 27 ? {email} : email.substring(0,24) + "..."} */}
    //               {email}
    //               </Typography>}

    //           />
    //         </ListItem>
    //         <Divider variant="middle" component="li" />
    //         <ListItem alignItems="flex-start">
    //           <ListItemText
    //             primary={
    //               <Typography sx={{ fontSize: 16, fontWeight: 600 , mb : "8px"  }}>
    //                 Currunt Company
    //               </Typography>
    //             }
    //             secondary={<Typography sx={{ fontSize: 16 }}>{current_company}</Typography>}
    //           />
    //         </ListItem>
    //         <Divider variant="middle" component="li" />
    //         <ListItem alignItems="flex-start">
    //           <ListItemText
    //             primary={
    //               <Typography sx={{ fontSize: 16, fontWeight: 600 , mb : "8px" }}>
    //                 Experience
    //               </Typography>
    //             }
    //             secondary={<Typography sx={{ fontSize: 16 }}>{experience}</Typography>}
    //           />
    //         </ListItem>
    //       </List>
    //     </>
    //   );
