import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import { isAuthenticated } from "../../../../api/authApi";
const Notification = () => {
  const auth = isAuthenticated()?.data;

  return (
    <>
      {auth?.notification?.map((message) => (
        <div align="left">
          <Alert
            sx={{
              width: "max-content",
              mb: 2,
              textAlign: "center",
            }}
            severity="error"
          >
            {message}
          </Alert>
        </div>
      ))}
    </>
  );
};

export default Notification;
