import React, { Component } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { getMentorsListDetails,disableMentor, enableIsInstructor } from "../../../api/adminApi";
import { isAuthenticated } from "../../../api/authApi";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from '@mui/material/DialogContentText';
import Switch from '@mui/material/Switch';
import { previousSaturday } from "date-fns/esm";

function searchingfor(searchemail) {
    return function (x) {
        return x.email.toLowerCase().includes(searchemail.toLowerCase()) || !searchemail;
    }
}

class Dashboard extends Component {
    state = {
        searchemail: "",
        mentors_list: [],
    };

    componentDidMount() {
        getMentorsListDetails({
            admin_uid: isAuthenticated()?.data?.localId
        }).then(res => {
            this.setState({ mentors_list: res.data.response.mentors_list })
        })
            .catch(err => console.log(err))
    }

    onSearchInputChange = (event) => {
        if (event.target.value) {
            this.setState({ searchemail: event.target.value })
        } else {
            this.setState({ searchemail: '' })
        }
    }
    handleInstructor(id){
        let ind = 0;
        let togglestate = false
        for (var i = 0; i < this.state.mentors_list?.length; i++) {
            if (this.state.mentors_list[i].email == id) {
                ind = i;
                togglestate = !this.state.mentors_list[i]?.is_instructor
                break;
            }
        }
        let req = {
            email: id,
            is_instructor: togglestate
        }
        enableIsInstructor(req).then(res => {
            let data = [...this.state.mentors_list];
            data[ind].is_instructor = togglestate
            this.setState({mentors_list :  data });
        })
    }
    
    handleToggle(id) {
        let ind = 0;
        let togglestate = false
        for (var i = 0; i < this.state.mentors_list?.length; i++) {
            if (this.state.mentors_list[i].email == id) {
                ind = i;
                togglestate = !this.state.mentors_list[i]?.disabled
                break;
            }
        }
        let req = {
            email: id,
            disable_user: togglestate
        }
        disableMentor(req).then(res => {
            let data = [...this.state.mentors_list];
            data[ind].disabled = togglestate
            this.setState({mentors_list :  data });
        })
    }

    render() {
        return (
            <div>
                <TableContainer component={Paper} style={{
                    borderStyle: "inset", borderColor: '#ADD8E6',
                }}>
                    <TextField
                        label="Search by Email"
                        onChange={this.onSearchInputChange}
                        value={this.state.searchemail}
                        id="searchEmail"
                        type="text"
                        style={{ margin: "20px" }}
                    />
                    <Table aria-label="simple table">
                        <colgroup>
                            <col width="10%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="5%" />
                            <col width="5%" />
                        </colgroup>
                        <TableHead>

                            <TableRow>
                                <TableCell scope="row">

                                </TableCell>
                                <TableCell scope="row">
                                    General Info
                                </TableCell>
                                <TableCell scope="row">
                                    Contact
                                </TableCell>
                                <TableCell scope="row">
                                    Current Company
                                </TableCell>
                                <TableCell scope="row">
                                    Experience
                                </TableCell>
                                <TableCell scope="row">
                                    Instructor
                                </TableCell>
                                <TableCell scope="row">
                                    Disable 
                                </TableCell>
                                <TableCell scope="row">
                                    Edit
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.mentors_list.filter(searchingfor(this.state.searchemail)).map((row) => (
                                <TableRow key={row.email}

                                >
                                    <TableCell scope="row" >
                                        <Avatar alt="Remy Sharp" src={row.photo} sx={{ width: 55, height: 55 }} />
                                    </TableCell>
                                    <TableCell scope="row" >
                                        <p style={{ margin: 0 }}> {row.name} </p>
                                        <b style={{ margin: 0 }}> {row.email} </b>
                                    </TableCell>
                                    <TableCell scope="row">
                                        <p style={{ margin: 0 }}> {row.phone} </p>
                                    </TableCell>
                                    <TableCell scope="row" >
                                        <p style={{ margin: 0 }}> {row.current_company} </p>
                                    </TableCell>
                                    <TableCell scope="row" >
                                        <p style={{ margin: 0 }}> {row.experience} </p>
                                    </TableCell>
                                    <TableCell scope="row" >
                                            <Switch
                                                checked={row.is_instructor}
                                                onChange={() => this.handleInstructor(row.email)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                color="success"
                                            />
                                        </TableCell>
                                    <TableCell scope="row" >
                                            <Switch
                                                checked={row.disabled}
                                                onChange={() => this.handleToggle(row.email)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                color="error"
                                            />
                                        </TableCell>
                                    <TableCell scope="row" >
                                        <a href={`/admin/mentor-profile/edit/${row.email}`}>
                                            <button
                                                onClick={() => this.edit_user(row.email)}
                                                style={{
                                                    margin: "auto",
                                                    borderColor: "white",
                                                    backgroundColor: "white",
                                                }}>
                                                <EditIcon />
                                            </button>
                                        </a>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default Dashboard