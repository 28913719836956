import React from "react";
import ChooseDates from "./components/ChooseDates";
const MonthlySessions = () => {
  return (
    <>
      <div>
        <ChooseDates />
      </div>
    </>
  );
};

export default MonthlySessions;
