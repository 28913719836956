import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isAuthenticated } from "../api/authApi";

const RequireAuth = ({ allowedRole }) => {
  const auth = isAuthenticated()?.data;
  const location = useLocation();
  return !auth ? (
    <Navigate to="/signin" state={{ from: location }} replace />
  ) : auth?.role === allowedRole ? (
    <Outlet />
  ) : auth?.email ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default RequireAuth;
