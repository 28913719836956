export const batch = [
  "B11A",
  "B11B",
  "B12A",
  "B12B",
  "B13A",
  "B13B",
  "B14A",
  "B14B",
  "B15A",
  "B15B",
  "B16A",
  "B16B",
  "B17A",
  "B17B",
  "B18A",
  "B18B",
  "B19A",
  "B19B",
  "B20A",
  "B20B",
  "B_REC",
];

export const role = ["user", "mentor", "admin"];
