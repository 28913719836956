import React, { useState, useEffect } from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import { LoadingButton } from '@mui/lab';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const UpdateBatch = () => {
  const [batch, setBatch] = useState();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [successToast, setsuccessToast] = useState(false);
  const [errorToast, seterrorToast] = useState(false);
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const handleToastClose = (e) => {
    setsuccessToast(false);
    seterrorToast(false);
  };

  let obj = {
    "email": email,
    "batch": batch
  }

  useEffect(() => {
    axios
    .post("/common/refresh_token", {
      refresh_token: auth?.refreshToken,
    })
    .then((res) => {
      if (res?.data?.access_token) {
        var existing = JSON.parse(localStorage.getItem("jwt"));
        existing.data.idToken = res?.data?.access_token;
        localStorage.setItem("jwt", JSON.stringify(existing));
    }
    })
  }, []);
  const sendResponse = (e) => {
    setLoading(true)
    e.preventDefault();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios.post("/admin/change_student_batch_v3", obj).then((res) => {
            if (res?.data?.success) {
              setLoading(false);
              setsuccessToast(true);
            } else {
              setLoading(false);
              seterrorToast(true);
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };
  return (
    <>
      <div>
        <Snackbar
          open={successToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Batch Change Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
            Change Student Batch
          </Typography>

          <TextField

            id="BATCH"
            label="Batch"
            type="text"
            onChange={(e) => setBatch(e.target.value)}
            value={batch}
            style={{ marginTop: "10px", width: "100%" }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> </InputAdornment>
              ),
            }}
          >

          </TextField>

          <TextField
            id="email"
            type="text"
            label="Email"
            value={email}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginTop: "10px", width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> </InputAdornment>
              ),
            }}
          >

          </TextField>

          <LoadingButton
            type="submit"
            fullWidth
            loading={loading}
            loadingIndicator="Submitting"
            variant="contained"
            onClick={sendResponse}
            sx={{
              mt: 3, 
              mb: 2,
              backgroundColor: "#1d99be",
              "&:hover": {
                backgroundColor: "#1d8cbe",
              },
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Container>
    </>

  )
}

export default UpdateBatch