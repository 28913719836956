import { Container } from '@mui/material'
import React from 'react'

import FourZeroFour from '../../assets/FourZeroFour.svg'

const Missing = () => {
  return (
    <Container sx={{heighh: '100%', ml : 50}}>
    <img src={FourZeroFour}/>
   </Container>

  )
}

export default Missing