import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import axios from "../../../../api/axios";
import { isAuthenticated, signout } from "../../../../api/authApi";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "scroll",
  p: 4,
  display: "block",
  height: "80%",
};

const SessionCard = (props) => {
  const navigate = useNavigate();
  const auth = isAuthenticated()?.data;

  const { data } = props;
  const { batch, student_email, student_name, session_no } = data;

  const [formData, setFormData] = useState(data);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          const finalJson = { ...formData, id_token: res?.data?.id_token };
          axios.post("/mentors/update_session", finalJson).then((res) => {
            if (res?.data?.success) {
              toast.success("Session details updated successfully", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setOpen(false);
            } else {
              toast.error(res?.data?.error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  const form = (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Container>
          <Typography variant="h5" component="div">
            {student_name}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {student_email}
          </Typography>

          {/* session_no */}
          <Typography variant="subtitle2" gutterBottom component="div">
            Session Number
          </Typography>
          <TextareaAutosize
            name="session_no"
            value={formData.session_no}
            onChange={handleChange}
            style={{ width: "100%" }}
            minRows={3}
          />
          {/* leetcode_counter */}
          <Typography variant="subtitle2" gutterBottom component="div">
            Leetcode Counter
          </Typography>
          <TextareaAutosize
            name="leetcode_counter"
            value={formData.leetcode_counter}
            onChange={handleChange}
            style={{ width: "100%" }}
            minRows={3}
          />

          {/* how_course_work_is_ongoing */}
          <Typography variant="subtitle2" gutterBottom component="div">
            Feedback To Mentee
          </Typography>
          <TextareaAutosize
            name="how_course_work_is_ongoing"
            value={formData.how_course_work_is_ongoing}
            onChange={handleChange}
            style={{ width: "100%" }}
            minRows={3}
          />

          {/* confidence */}
          <Typography variant="subtitle2" gutterBottom component="div">
            Milestones for mentee
          </Typography>
          <TextareaAutosize
            name="confidence"
            value={formData.confidence}
            onChange={handleChange}
            style={{ width: "100%" }}
            minRows={3}
          />

          <Box textAlign="center" sx={{ m: 1 }}>
            <Button type="submit" onClick={handleSubmit} variant="contained">
              Submit
            </Button>
          </Box>
        </Container>
      </Box>
    </Modal>
  );

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {student_email}
        </Typography>
        <Typography variant="h5" component="div">
          {student_name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Session Number {session_no}
        </Typography>
        <Typography variant="body2">Batch {batch}</Typography>
        <CardActions>
          <Button size="small" onClick={handleOpen}>
            Edit Details
          </Button>
        </CardActions>
      </CardContent>
    </React.Fragment>
  );

  return (
    <div>
      <ToastContainer />
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">{card}</Card>
        {form}
      </Box>
    </div>
  );
};

export default SessionCard;
