
import React from "react";
import {
 AreaChart,
 Area,  
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import {Colors} from "../../../../assets/Colors"

function extractDate(date) {
  let dd = date[6] + date[7];
  let mm = date[4] + date[5];

  return dd + "/" + mm;
}

const LCGraph = ({ leetcodeProgress }) => {
 

  const data = [];
  for (var i = 0; i < leetcodeProgress?.length; i++) {
    data.push({
      date: extractDate(leetcodeProgress[i].date),
      count: leetcodeProgress[i].count,
    });
  }

  return (
    <ResponsiveContainer height={325}>
      <AreaChart data={data} margin={{ top: 25 }}>
        <Area type="monotone" dataKey="count" stroke= {Colors.primaryMain} />
        <CartesianGrid stroke="#ccc"  strokeDasharray="3 3"/>
        <XAxis dataKey="date" />
        <YAxis domain={["dataMin", "dataMax + 10"]} allowDecimals={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LCGraph;
