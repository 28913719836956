import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import LeaderBoard from "./components/LeaderBoard";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import Loading from "../../notificationPages/Loading";
import StudentProgress from "./components/StudentProgress";
import PersonalInfo from "./components/PersonalInfo";
import LCGraph from "./components/LCGraph";
import LeetcodeForm from "../../leetcode_form";
import ReactGa from "react-ga";
import NameDesk from "./components/NameDesk";
import { Colors } from "../../../assets/Colors.js";
import { RETRIES } from "../../../api/axios";
import axiosRetry from "axios-retry";
import Notification from "./notifications/Notification";

const UserProfile = () => {
  const navigate = useNavigate();
  const auth = isAuthenticated()?.data;
  const [userDetails, setUserDetails] = useState();

  let isMounted = true;
  const getUserFromBackend = () => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.status === 201) {
          if (res?.data) {
            var existing = JSON.parse(localStorage.getItem("jwt"));
            existing.data.idToken = res?.data?.access_token;
            localStorage.setItem("jwt", JSON.stringify(existing));
          }
          axios
            .post("/student/get_user_details", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (isMounted) {
                if (res?.data?.success) {
                  setUserDetails(res?.data?.response);
                  localStorage.setItem(
                    "profileData",
                    JSON.stringify(res?.data?.response)
                  );
                }
              }
            })
            .catch((e) => {
              console.log("error", e);
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  useEffect(() => {
    axiosRetry(axios, {
      retries: RETRIES,
      shouldResetTimeout: true,
      retryCondition: (_error) => true, // retry no matter what
    });
    ReactGa.pageview(window.location.pathname);
    try {
      if (
        localStorage.getItem("profileData") &&
        localStorage.getItem("profileData") !== undefined
      )
        setUserDetails(JSON.parse(localStorage.getItem("profileData")));
      getUserFromBackend();
    } catch {
      getUserFromBackend();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  //   const handleClick = () => {
  //     navigate("/user/curriculum");
  // }
  return userDetails ? (
    <>
    <Notification/>
      <LeetcodeForm />
      <Grid container spacing={2} columns={12} paddingTop={0}>
        <Grid item xs={12} md={7} lg={7}>
          <NameDesk name={userDetails.name} />
        </Grid>
        <Grid item xs={12} md={5} lg={5} sx={{ mt: -1 }}>
          <StudentProgress
            total_leetcode_count={userDetails.total_leetcode_count}
            points={userDetails.points}
            last_week_count={userDetails.last_week_count}
            batch={userDetails.batch}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} columns={12} paddingTop={0} sx={{ mt: 2 }}>
        <Grid item xs={7} md={7} s>
          <Paper
            sx={{
              bgcolor: Colors.primaryLite,
              pr: 4,
              pt: 5,
              height: "416px",
              borderRadius: 2,
            }}
            variant="outlined"
          >
            <LCGraph leetcodeProgress={userDetails.leetcode_progress} />
          </Paper>
        </Grid>
        <Grid item xs={5} md={4} sx={{ mt: -3 }}>
          <Paper
            sx={{
              mt: 3,
              bgcolor: Colors.primaryLite,
              borderRadius: 2,
            }}
            variant="outlined"
          >
            <PersonalInfo
              name={userDetails.name}
              email={userDetails.email}
              leetcode={userDetails.leetcode}
              batch={userDetails.batch}
            />
          </Paper>
        </Grid>
        <Grid container spacing={0} columns={12} paddingTop={0} sx={{ mt: 2 }}>
          <Grid item xs={12} md={11} sx={{ pl: 2 }}>
            <LeaderBoard leaderboard={userDetails.leaderboard} />
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <Loading />
  );
};

export default UserProfile;
