import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import Loading from "../../notificationPages/Loading";
import CourseCard from "./components/CourseCard";
import ProgressBar from "./components/ProgressBar";
import Tabs from "@mui/material/Tabs";
import LeetcodeForm from "../../leetcode_form";
import "./style.css";
import ReactGa from "react-ga";
import UpcomingClassCard from "./components/UpcomingClassCard";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ExtraClass from "./components/ExtraClass";

const Curriculum = () => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const [course, setCourse] = useState();
  const [allModules, setAllModules] = useState([]);
  const [progress, setProgress] = useState(0);
  const [upcomingClass, setupcomingClass] = useState();
  const [extraClasses, setExtraClasses] = useState();
  const [searchChapter, setSearchChapter] = useState();
  const [value, setValue] = React.useState("1");
  const [chaptervalue, setChaptervalue] = useState(0);
  const [modules, setModules] = useState();
  const [moduleName, setModuleName] = useState(null);
  const [defaultModule, setDefaultModule] = useState(
    JSON.parse(localStorage.getItem("allModules"))
  );
  let isMounted = true;
  function searchingfor(searchChapter) {
    return function (x) {
      return (
        x.topic?.toLowerCase().includes(searchChapter?.toLowerCase()) ||
        !searchChapter
      );
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handelModuleChange = (event, newValue) => {
    setChaptervalue(newValue);
    setModuleName(allModules[newValue]?.course[0].module_name);
  };
  function upcomingLectures(value) {
    if (value.is_locked) {
      return value;
    }
  }
  function removeUpcomingLectures(value) {
    if (value.is_locked == false) {
      return value;
    }
  }
  function movedExtraLectures(value) {
    if (parseInt(value.id) > 999) {
      return value;
    }
  }
  function removeExtraLectures(value) {
    if (parseInt(value.id) < 999) {
      return value;
    }
  }
  const getUserCourse = () => {
     if (allModules?.length > 0) {
       setCourse(allModules[chaptervalue]?.course);
     } 
      axios
        .post("/common/refresh_token", {
          refresh_token: auth?.refreshToken,
        })
        .then((res) => {
          if (res?.data?.access_token) {
            var existing = JSON.parse(localStorage.getItem("jwt"));
            existing.data.idToken = res?.data?.access_token;
            localStorage.setItem("jwt", JSON.stringify(existing));
            axios
              .post("/student/get_all_modules_v3", {
                id_token: res?.data?.id_token,
              })
              .then((res) => {
                if (isMounted) {
                  setAllModules(res?.data?.course);
                  setupcomingClass(res?.data?.upcoming_session)
                  setCourse(res?.data?.course[chaptervalue]?.course);
                  setModules(res?.data?.modules);
                  localStorage.setItem(
                    "upcomingSession",
                    JSON.stringify(res?.data?.upcoming_session)
                  );
                  localStorage.setItem(
                    "allModules",
                    JSON.stringify(res?.data?.course)
                  );
                  localStorage.setItem(
                    "each_module",
                    JSON.stringify(res?.data?.course[chaptervalue]?.course)
                  );
                  localStorage.setItem(
                    "modules_name",
                    JSON.stringify(res?.data?.modules)
                  );
                }
              });
            if (moduleName === null) {
              axios
                .post("/student/check_progress_v3", {
                  id_token: isAuthenticated()?.data?.idToken,
                  module: defaultModule[0].course[0].module_name,
                })
                .then((res) => {
                  const countJSON = res.data.response;
                  setProgress(countJSON.count);
                });
            }
          } else {
            signout(auth?.localId, () => {
              navigate("/");
            });
          }
        });
    // }
    if (moduleName !== null) {
      axios
        .post("/student/check_progress_v3", {
          id_token: isAuthenticated()?.data?.idToken,
          module: moduleName,
        })
        .then((res) => {
          const countJSON = res.data.response;
          setProgress(countJSON.count);
        });
    }
  };

  useEffect(() => {
    ReactGa.pageview(window.location.pathname + window.location.search);
    try {
      if (
        localStorage.getItem("allModules") &&
        localStorage.getItem("allModules") !== undefined &&
        localStorage.getItem("modules_name") &&
        localStorage.getItem("modules_name") !== undefined &&
        localStorage.getItem("each_module") &&
        localStorage.getItem("each_module") !== undefined &&
        localStorage.getItem("upcomingSession") &&
        localStorage.getItem("upcomingSession") !== undefined
      ) {
        setModules(JSON.parse(localStorage.getItem("modules_name")));
        setCourse(JSON.parse(localStorage.getItem("each_module")));
        setAllModules(JSON.parse(localStorage.getItem("allModules")));
        setupcomingClass(JSON.parse(localStorage.getItem("upcomingSession")));
      }
      getUserCourse();
    } catch {
      getUserCourse();
    }

    return () => {
      isMounted = false;
    };
  }, [chaptervalue, progress, moduleName]);

  let onSearchInputChange = (event) => {
    if (event.target.value) {
      setSearchChapter(event.target.value);
    } else {
      setSearchChapter("");
    }
  };

  return modules ? (
    <>
      <div>
        <ProgressBar
          progress={progress}
          total={course?.length}
          onSearchInputChange={onSearchInputChange}
          searchChapter={searchChapter}
        />
      </div>
      <LeetcodeForm />
      <div>
        {upcomingClass?.map((c, i) => (
          <UpcomingClassCard
            id={c.id}
            image_link={c.image_link}
            module_name={c.module_name}
            is_completed={c.is_completed}
            is_locked={c.is_locked}
            topic={c.topic}
            zoom_link={c.zoom_link}
            date={c.date}
          />
        ))}
      </div>
   
      <Box sx={{ marginBottom: 5, marginTop: 4 }}>
        <Tabs
          value={chaptervalue}
          onChange={handelModuleChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            "& button.Mui-selected": {
              backgroundColor: "#1976D2",
              color: "white",
            },
          }}
          indicatorColor="white"
        >
          {modules?.map((c, i) => (
            <Tab label={c} sx={{ border: 1 }} />
          ))}
        </Tabs>
      </Box>
     
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Upcoming Lectures" value="1" />
              <Tab label="Recorded Lectures" value="2" />
              <Tab label="Extra / Recorded Lectures" value="3" />
            </TabList>
          </Box>

          {course
            ?.filter(removeUpcomingLectures)
            ?.reverse()
            ?.filter(searchingfor(searchChapter))
            ?.filter(removeExtraLectures)
            ?.map((c, i) => (
              <TabPanel value="2">
                {
                  <CourseCard
                    id={c.id}
                    image_link={c.image_link}
                    doc_id={c.doc_id}
                    module_name={c.module_name}
                    is_completed={c.is_completed}
                    is_locked={c.is_locked}
                    topic={c.topic}
                    setProgress={setProgress}
                    date={c.date}
                  />
                }
              </TabPanel>
            ))}

          {course
            ?.filter(searchingfor(searchChapter))
            ?.filter(movedExtraLectures)
            ?.reverse()
            ?.map((c, i) => (
              <TabPanel value="3">
                {" "}
                {
                  <ExtraClass
                    id={c.id}
                    image_link={c.image_link}
                    doc_id={c.doc_id}
                    is_completed={c.is_completed}
                    module_name={c.module_name}
                    is_locked={c.is_locked}
                    topic={c.topic}
                    setProgress={setProgress}
                    date={c.date}
                  />
                }
              </TabPanel>
            ))}
          {course
            ?.filter(upcomingLectures)
            ?.filter(searchingfor(searchChapter))
            ?.map((c, i) => (
              <TabPanel value="1">
                {
                  <CourseCard
                    id={c.id}
                    image_link={c.image_link}
                    doc_id={c.doc_id}
                    is_completed={c.is_completed}
                    module_name={c.module_name}
                    is_locked={c.is_locked}
                    topic={c.topic}
                    setProgress={setProgress}
                    date={c.date}
                  />
                }
              </TabPanel>
            ))}
        </TabContext>
      </Box>
    </>
  ) : (
    <Loading />
  );
};

export default Curriculum;
