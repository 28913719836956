import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  Grid,
  Paper,
  styled,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated} from "../../../../api/authApi";
import { toast, ToastContainer } from "react-toastify";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Link from "@mui/material/Link";
import {Colors} from "../../../../assets/Colors"
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  borderRadius: "50%",
});

const MentorCard = (props) => {
  const {
    name,
    current_compant,
    email,
    experience,
    linkedin,
    photo,
    mentor_priority,
    message,
    obj,
  } = props;
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();

  const [mentorList, setMentorList] = useState();
  const [Select, setSelect] = useState(false);

  return (
    <>
      <div>
        <Paper sx={{ p: 2, margin: "auto", flexGrow: 1, '&:hover': { boxShadow : 5}}} variant="outlined">
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 301, height: 133 }} disabled={true}>
                {photo === null ? (
                  <Img src="https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/assets%2Favatar-svgrepo-com%20(1).svg?alt=media&token=78d21828-cf37-40f6-b7e2-82d52e60e45f"></Img>
                ) : (
                  <Img src={photo}></Img>
                )}
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    variant="title-1"
                    component="div"
                    sx={{ fontSize: 20, fontWeight: 500 }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ fontSize: 16, fontWeight: 200 }}
                  >
                    {current_compant}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ fontSize: 16, fontWeight: 200 }}
                  >
                    {experience} Years of experience
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ fontSize: 16, fontWeight: 200 }}
                  >
                    <Link href={linkedin} underline="none" target="_blank">
                      <LinkedInIcon sx={{ color: Colors.primaryMain }} />
                    </Link>
                  </Typography>
                </Grid>
                <ToastContainer />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              {message === "MENTORS CHOSEN BUT NOT ALLOTED" ? (
                <Button variant="outlined" color="success">
                  <CheckCircle sx={{ m: 1 }} />
                  Selected
                </Button>
              ) : message === "MENTOR ALLOTED" ? (
                <Button variant="outlined" color="success">
                  <CheckCircle sx={{ m: 1 }} />
                  Alloted
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (!Select && obj?.mentor_priority?.length <= 2) {
                      mentor_priority.push(email);
                      setSelect(!Select);
                    } else if (!Select) {
                      toast.error("You just select 3 mentors", {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    } else {
                      let index = mentor_priority.indexOf(email);
                      mentor_priority.splice(index, 1);
                      setSelect(!Select);
                    }
                  }}
                  color={Select ? "success" : "primary"}
                  disabled={message === "MENTORS CHOSEN BUT NOT ALLOTED"}
                >
                  <CheckCircle sx={{ m: 1 }} />
                  {Select === true ? "Selected" : "Select a Mentor"}
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default MentorCard;
