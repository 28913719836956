import React, {useEffect} from "react";
import { Colors } from "../../../../assets/Colors";
import { Grid, Paper, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import Link from '@mui/material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const MentorStats = ({ mentees, referral_code, mentorRating,calendly_link,linkedin_profile}) => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    
  setValue(mentorRating)
    
  }, )
  
  return (
    <>
      <Grid container spacing={2} columns={6}>
        <Grid item xs={3} md={3} lg={3} sx={{ mt: 1 }}>
          <Paper
            sx={{ height: "97px", bgcolor: "#084652", color: "white" }}
            variant="outlined"
          >
            <Typography
              sx={{
              paddingTop: "8px",}}
              textAlign={"center"}
              margin
              variant="h6"
            >
              Total Mentees
            </Typography>
            <Typography
              sx={{ color: "#20B2AA",  fontWeight: 700, pl: 2 }}
              textAlign={"center"}
              margin
              variant="h5"
            >
              {mentees?.length}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3} md={3} lg={3} sx={{ mt: 1 }}>
          <Paper
            sx={{
              height: "97px",
              bgcolor: Colors.secoundaryBackgorund,
              color: "white",
            }}
            variant="outlined"
          >
            <Typography
              sx={{
                color: "6B7B8A",
                bgcolor: Colors.secoundaryBackgorund,
                color: "white",
                pl: 2,
                paddingTop: "8px",
              }}
              textAlign={"center"}
              margin
              variant="h6"
            >
              Referral Code
            </Typography>
            <Typography
              sx={{ color: "white",fontWeight: 700, pl: 2 }}
              textAlign={"center"}
              margin
              variant="h6"
            >
              {" "}
              {referral_code}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3} md={3} lg={3} padding={0}>
          <Paper
            sx={{ height: "97px", bgcolor: Colors.primaryMain, color: "white" }}
            variant="outlined"
          >
            <Typography
              sx={{ color: "white",pl: 2, paddingTop: "8px",}}
              textAlign={"center"}
              margin
              variant="h6"
            >
              Rating
            </Typography>
            <Typography
              sx={{ color: "white", fontSize: 26, fontWeight: 700, pl: 2 }}
              textAlign={"center"}
              margin
            >
               <Rating name="read-only" precision={0.50} value={value} readOnly />  
             
               {/* {mentorRating} / 5 */}
            </Typography>
          </Paper>
        </Grid>
          
      <Grid item xs={3} md={3} lg={3} sx={{}} padding={0}>
        <Link href={calendly_link} target="_blank" underline="none">
          <Paper
            sx={{
              height: "97px",
              bgcolor: Colors.primaryMain,
              color: "white",
              "&:hover": { boxShadow: 5 },
            }}
            variant="outlined"
          >
            <Typography
              sx={{ color: "6B7B8A", 
              paddingTop: "10%", }}
              textAlign={"center"}
              margin
              variant="h6"  
            >
              <Link href={linkedin_profile} target="_blank" sx={{textDecoration : "none"}}>
               <LinkedInIcon sx={{ fontSize: "40px !important" , color : "white"}} />{" "}
            </Link>
            </Typography>
            <Typography
              sx={{ color: "white", fontSize: 26, fontWeight: 700, pl: 2 }}
              textAlign={"left"}
              margin
            >
              {/* {batch} */}
            </Typography>
          </Paper>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default MentorStats;
