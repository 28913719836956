import { Route, Routes } from "react-router-dom";
import React from "react"
import Signin from "./auth/Login";
import RequireAuth from "./auth/RequireAuth";
import Admin from "./components/admin/Admin";
import AdminProfile from "./components/admin/AdminProfile/AdminProfile";
import CreateBatch from "./components/admin/CreateBatch/CreateBatch";
import LockUnlockSession from "./components/admin/LockUnlockBatch/LockUnlockSession";
import UpdateBatch from "./components/admin/UpdateBatch/UpdateBatch";
import UserSignup from "./components/admin/UserSignup/UserSignup";
import Unauthorized from "./components/notificationPages/Unauthorized";
import Mentor from "./components/mentor/Mentor";
import MentorProfile from "./components/mentor/mentorProfile/MentorProfile";
import Curriculum from "./components/user/curriculum/Curriculum";
import User from "./components/user/User";
import UserProfile from "./components/user/userProfile/UserProfile";
import Layout from "./layout/Layout";
import Lesson from "./components/user/curriculum/Lesson";
import Dashboard from "./components/admin/Dashboard/user_dashboard";
import MentorDashboard from "./components/admin/Dashboard/mentor_dashboard.jsx";
import MentorSession from "./components/user/mentorSessions/MentorSession";
import UpdateSessions from "./components/mentor/updateSessions/UpdateSessions";
import AddSessions from "./components/mentor/addSessions/AddSessions";
import UpdateSessionForm from "./components/mentor/updateSessions/UpdateSessionForm";
import Resume from "./components/user/resume_build/Resume";
import Availability from "./components/mentor/Availability/Availability"; 
import Notes from "./components/user/curriculum/Notes";
import StartMeeting from "./components/mentor/startMeeting/StartMeeting";
import Missing from "./components/notificationPages/Missing";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReferAndEarn from "./components/user/refer&Earn/ReferAndEarn";
import MyMentee from "./components/mentor/MyMentee/MyMentee";
import LeaderBoard from "./components/user/leaderboard/LeaderBoard";
import SessionBooking from "./components/user/mentorSessionBooking/SessionBooking";
import MentorsCurriculum from "./components/mentor/curriculum/MentorsCurriculum";
import LessonMentor from "./components/mentor/curriculum/LessonMentor";
import NotesMentor from "./components/mentor/curriculum/NotesMentor";
import AllMentorSessions from "./components/mentor/allMentorSessions/allMentorSessions"
import StudentMentorSessions from "./components/user/allMentorSessions/allMentorSessions"
import PrerequisiteCourse from "./components/user/prerequisite/PrerequisiteCourse";
import BeginnerLesson from "./components/user/prerequisite/BeginnerLesson";
import BeginnerNotes from "./components/user/prerequisite/BeginnerNotes";
import Onbording from "./components/user/onbordingv2/Onbording";
import PrerecordedSession from "./components/user/curriculum/components/PrerecordedSession";
import Placement from "./components/user/placement/Placement";
import ChooseMentor from "./components/user/selectMentor/ChooseMentor"
import ReactGa from "react-ga"
import ProfilePage from "./components/admin/Dashboard/profile_page"
import ProfilePageMentor from "./components/admin/Dashboard/profile_page_mentor"
import AddLink from "./components/admin/AddVideoLink/AddLink";
import MonthlySessions from "./components/mentor/monthlyMentorSessions/MonthlySessions";
import UpdateCourse from "./components/admin/UpdateCourse/UpdateCourse";
import AddExtraClasses from "./components/admin/addExtraClasses/AddExtraClasses"
import CodeEditor from "./components/codeEditor/codeEditor.jsx"
import CreateQuestionForm from "./components/admin/QuestionCreator/createQuestionForm";
import QuestionsDashboard from "./components/admin/questionsDashboard/QuestionsDashboard";
import QuestionsUpdateForm from "./components/admin/questionsDashboard/components/QuestionsUpdateForm";
import Solution from "./components/admin/addSolution/Solution";

const Tracking_ID = "UA-240264393-1" //--> Tracking ID of Google Analytics
ReactGa.initialize(Tracking_ID);
function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Nunito"],
    },
  });

  return (
   
    <ThemeProvider theme={theme}>
      <Routes>
        {/* Public Routes */}
        <Route path="*" element={<Missing />} />
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Signin />} />
          <Route path="signin" element={<Signin />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="/user/codeEditor/:id" element={<CodeEditor />} />
          <Route path="/admin/createQuestionForm" element={ <CreateQuestionForm />} />
          <Route path="admin/update-question-form/edit/:id" element={<QuestionsUpdateForm/>} />
              <Route path="admin/add-solutions" element={<Solution />} />

          {/* Admin Routes */}

          <Route path="admin" element={<RequireAuth allowedRole={"admin"} />}>
            <Route element={<Admin />}>
              <Route path="profile" element={<AdminProfile />} />
              <Route path="user-signup" element={<UserSignup />} />
              <Route path="create-batch" element={<CreateBatch />} />
              <Route path="unlock-session" element={<LockUnlockSession />} />
              <Route path="update-batch" element={<UpdateBatch />} />
              <Route path="student-dashboard" element={<Dashboard />} />
              <Route path="mentor-dashboard" element={<MentorDashboard />} />
              <Route path="questions-dashboard" element={< QuestionsDashboard/>} />
              <Route path="profile/edit/:id" element={<ProfilePage />} />
              <Route path="mentor-profile/edit/:id" element={<ProfilePageMentor />} />
              <Route path="add-class-link" element={<AddLink />} />
              <Route path="update-course" element={<UpdateCourse />} />
              <Route path="add-other-class-link" element={<AddExtraClasses />} />
            </Route>
          </Route>

          {/* Mentor Routes */}
          <Route path="mentor" element={<RequireAuth allowedRole={"mentor"} />}>
            <Route element={<Mentor />}>
              <Route path="profile" element={<MentorProfile />} />
              <Route path="start-meeting" element={<StartMeeting />} />
              <Route path="update-sessions" element={<UpdateSessions />} />
              <Route path="add-sessions" element={<AddSessions />} />
              <Route path="mentor-curriculum" element={<MentorsCurriculum />} />
              <Route path="mentor-curriculum/:id" element={<LessonMentor />} />
              <Route path="all-mentor-sessions" element={<AllMentorSessions />} />
              <Route
                path="mentor-curriculum/:id/:noteslink"
                element={<NotesMentor />}
              />
            </Route>
          </Route>

          {/* User Routes */}

          <Route path="user" element={<RequireAuth allowedRole={"user"}/>}>
            {/* <Route path="onboarding" element={<Onboarding />} /> */}
            <Route element={<User />}>        
              <Route path="profile" element={<UserProfile />} />
              <Route path="curriculum" element={<Curriculum />} />
              <Route path="curriculum/:name/:id" element={<Lesson />} />
              <Route path="curriculum/:id/:noteslink" element={<Notes />} />
              <Route path="curriculum/:id/:pre-recorded-sessions" element={<PrerecordedSession/>} />
              <Route path="mentor-sessions" element={<MentorSession />} />
              <Route path="refer-and-earn" element={<ReferAndEarn />} />
              <Route path="leader-board" element={<LeaderBoard />} />
              <Route path="mentor-sessions" element={<MentorSession />} />
              <Route path="uploadResume" element={<Resume />} />
              <Route path="all-mentor-sessions" element={<StudentMentorSessions />} />
              <Route path="prerequisite-curriculum" element={<PrerequisiteCourse />} />
              <Route path="prerequisite-curriculum/:id" element={<BeginnerLesson />} />
              <Route path="prerequisite-curriculum/:id" element={<BeginnerNotes />} />
              <Route path="mentor-sesson-booking" element={<SessionBooking />} />
              <Route path="user-onbording" element={<Onbording/>} />
              <Route path="user-placement" element={<Placement/>} />
              <Route path="select-mentor" element={<ChooseMentor/>} />
            </Route>
          </Route>
        </Route>

        {/* Mentor Routes */}
        <Route path="mentor" element={<RequireAuth allowedRole={"mentor"} />}>
          <Route element={<Mentor />}>
            <Route path="profile" element={<MentorProfile />} />
            <Route path="update-sessions" element={<UpdateSessions />} />
            <Route path="update-sessions/:id" element={<UpdateSessionForm />} />
            <Route path="add-sessions" element={<AddSessions />} />
            <Route path="my-mentee" element={<MyMentee />} />
            <Route path="availability" element={<Availability />} />
            <Route path="monthly-sessions" element={<MonthlySessions />} />
          </Route>
        </Route>

      </Routes>
    </ThemeProvider>
  );
}

export default App;
