import React, { useEffect, useState } from "react";
import SessionDetails from "./Components/SessionDetails";
import Loading from "../../notificationPages/Loading";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";


const MentorSession = () => {
  const [session, setSession] = useState();
  const auth = isAuthenticated()?.data;

  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/get_sessions", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              if (isMounted) {
                setSession(res?.data?.response?.sessions);
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);


  return session ? (
    <>
      <Typography
        variant="h5"
        component="h5"
        sx={{ textAlign: "center", mb: 4, fontWeight: 600 }}
      >
        Sessions Activity
      </Typography>

      {(session.length > 0) &&
        <div>
          <Grid container spacing={2}>
            {session.map((c, i) => (
              <Grid key={c.id} item xs={12} sm={12} lg={12}>
                {" "}
                <SessionDetails
                  additional_comments={c.additional_comments}
                  batch={c.batch}
                  confidence={c.confidence}
                  date={c.date}
                  details={c.details}
                  explanation_clarity={c.explanation_clarity}
                  how_course_work_is_ongoing={c.how_course_work_is_ongoing}
                  implementation_speed={c.implementation_speed}
                  leetcode_counter={c.leetcode_counter}
                  mentor_email={c.mentor_email}
                  mentor_name={c.mentor_name}
                  naming_convention={c.naming_convention}
                  progress={c.progress}
                  reacting_to_hints={c.reacting_to_hints}
                  session_no={c.session_no}
                  student_email={c.student_email}
                  student_name={c.student_name}
                  thinking_process={c.thinking_process}
                  time_complexity_analysis={c.time_complexity_analysis}
                  unique_id={c.unique_id}
                />
              </Grid>
            ))}
          </Grid>
        </div>}

      {(session.length === 0) &&


        <Typography
          variant="h6"
          component="h6"
          sx={{ textAlign: "center", mb: 4, fontWeight: 400 }}
        >
          No mentor sessions yet.
        </Typography>
      }
    </>
  ) : (
    <Loading />
  );
};

export default MentorSession;
