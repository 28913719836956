import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Loading from "../../notificationPages/Loading"
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { isAuthenticated, signout } from "../../../api/authApi";
import axiosRetry from "axios-retry";
import { RETRIES } from "../../../api/axios";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddExtraClasses = () => {
  const [batch, setBatch] = useState();
  const [topic, setTopic] = useState();
  const [videoLink, setVideoLink] = useState("https://player.vimeo.com/video/");
  const [successToast, setsuccessToast] = useState(false);
  const [errorToast, seterrorToast] = useState(false);
  const [allBatches, setAllBatches] = useState();
  const [moduleName, setModuleName] = useState();
  const [selectModule, setSelectModule] = useState();
  const [loading, setLoading] = useState(false);
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();

  const handleToastClose = (e) => {
    setsuccessToast(false);
    seterrorToast(false);
  };
  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
  }));
  useEffect(() => {
    axiosRetry(axios, {
      retries: RETRIES,
      shouldResetTimeout: true,
      retryCondition: (_error) => true, // retry no matter what
    });

    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios.post("/admin/get_all_chapters", {}).then((res) => {
            if (res?.data?.success) {
              setAllBatches(res?.data?.response?.all_batches);
              setModuleName(res?.data?.response?.modules_name);
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  }, []);

  let obj = {
    batch: batch,
    module: selectModule,
    chapter_name: ("Zoom Class Recording-B8-Others-" + topic).trim(),
    vimeo_link: ("https://player.vimeo.com/video/" + videoLink).trim(),
  };
  const sendResponse = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios.post("/admin/update_other_classes_v3", obj).then((res) => {
            if (res?.data?.success) {
              setLoading(false);
              setsuccessToast(true);
            } else {
              setLoading(false);
              seterrorToast(true);
            }
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  return allBatches ? (
    <>
      <div>
        <Snackbar
          open={successToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Video Uploaded Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorToast}
          autoHideDuration={3000}
          onClose={handleToastClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Some Error Occured!
          </Alert>
        </Snackbar>
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
            Add Extra Class Video To Portal
          </Typography>

          <TextField
            select
            id="BATCH"
            label="Batch"
            type="text"
            onChange={(e) => setBatch(e.target.value)}
            value={batch}
            style={{ marginTop: "10px", width: "100%" }}
            variant="outlined"
          >
            {allBatches?.map((batch) => (
              <MenuItem value={batch} id="topic">
                {batch}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="Topic"
            type="text"
            label="Topic"
            value={topic}
            variant="outlined"
            onChange={(e) => setTopic(e.target.value)}
            style={{ marginTop: "10px", width: "100%" }}
          ></TextField>
          <TextField
            id="Module"
            select
            label="Module"
            value={selectModule}
            onChange={(e) => setSelectModule(e.target.value)}
            style={{ marginTop: "10px", width: "100%" }}
            variant="outlined"
          >
            {moduleName?.map((name) => (
              <MenuItem value={name} id="topic">
                {name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="video_link"
            label="Video Link"
            type="number"
            onChange={(e) => setVideoLink(e.target.value)}
            value={videoLink}
            style={{ marginTop: "10px", width: "100%" }}
            variant="outlined"
          />

          <ColorButton
            type="submit"
            fullWidth
            variant="contained"
            // loadingPosition="center"
            loadingIndicator="Submitting"
            loading={loading}
            sx={{ mt: 3, mb: 2 }}
            onClick={sendResponse}
          >
            Submit
          </ColorButton>
        </Box>
      </Container>
    </>
  ):
  <Loading/>
};

export default AddExtraClasses;
