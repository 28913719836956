import React from "react";
import VideoLink from "./components/VideoLink";

const AddLink = () => {
  return (
    <>
      <VideoLink />
    </>
  );
};

export default AddLink;
