import React from "react";
import { Typography } from "@mui/material";


const ReferInfo = () => {
  return (
    <>

      <Typography variant="h6" sx={{ ml: 4, mt: 4, fontWeight: 600 }}>
        Refer your friends to any course at Bosscoder & Earn cash <br />
        rewards and Goodies
      </Typography>

      <Typography
        sx={{
          ml: 4,
          mt: 4,
          fontSize: 18,
        }}
      >
        For each successful referral you get a cash award of Rs. 3000 <br />
        & your friend gets an discount of Rs. 3000.
      </Typography>
      
    </>
  );
}

export default ReferInfo;
