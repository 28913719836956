import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  Grid,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const ImgLocked = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  opacity: "50%",
});

const CourseCard = (props) => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const { id, image_link, is_completed, is_locked, topic, setProgress } = props;
  const text = "";

  const [isCompleted, setIsCompleted] = useState(is_completed);

  const changeIsComplete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/mark_lesson_complete", {
              id_token: res?.data?.access_token,
              lesson_id: id,
              status: !isCompleted,
            })
            .then((res) => {
              if (res?.data?.success) {
                setIsCompleted((isCompleted) => !isCompleted);
                axios
                  .post("/student/check_progress", {
                    id_token: isAuthenticated()?.data?.idToken
                  })
                  .then((res) => {
                    const countJSON = res.data.response
                    setProgress(countJSON.count)
                  });
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  return (
    <div className={!is_locked ? "course-card-container" : "course"}>
      <Link
        style={{ textDecoration: "none" }}
        to={!is_locked ? { pathname: `${id}` } : "#"}
      >
        <Paper sx={{ p: 2, margin: "auto", flexGrow: 1 }} variant="outlined">
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 303, height: 193 }} disabled={true}>
                {!is_locked && <Img alt="complex" src={image_link} />}
                {is_locked && <ImgLocked alt="complex" src={image_link} />}
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ fontSize: 20, fontWeight: 500 }}
                  >
                    {topic}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ fontSize: 16, fontWeight: 200 }}
                  >
                    Master Class and Practice Question
                  </Typography>
                  <Typography
                    paragraph={true}
                    gutterBottom
                    sx={{ fontSize: 16 }}
                  >
                    {text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
            </Grid>
          </Grid>
        </Paper>
      </Link>
    </div>
  );
};

export default CourseCard;
