import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {
  Autocomplete,
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import { batch } from "../../../assets/Data";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddSessions = () => {
  const navigate = useNavigate();
  const auth = isAuthenticated()?.data;

  const [currentBatch, setCurrentBatch] = useState("B1");
  const [students, setStudents] = useState([]);
  const [currentStudent, setCurrentStudent] = useState();
  const [date, setDate] = useState(new Date());
  const [lcCount, setLcCount] = useState("0")

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("");
  };

  const initialValue = {
    details: "",
    session_no: "",
    how_course_work_is_ongoing: "",
    leetcode_counter: "",
    progress: "",
    confidence: "",
    naming_convention: "",
    reacting_to_hints: "",
    time_complexity_analysis: "",
    thinking_process: "",
    implementation_speed: "",
    explanation_clarity: "",
    additional_comments: "",
  };

  const [form, setForm] = useState(initialValue);

  useEffect(() => {
    let isMounted = true;
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/mentors/get_batch_student", {
              id_token: res?.data?.id_token,
              batch: currentBatch,
            })
            .then((res) => {
              if (isMounted) {
                const stuJson = res?.data?.response?.students;
                const temp = [];
                for (let i = 0; i < stuJson.length; i++) {
                  temp.push(stuJson[i].name + "  [" + stuJson[i].email + "]" + stuJson[i].count);
                }
                setStudents(temp);
              }
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [currentBatch]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newForm = {
      ...form,
      date: formatDate(date),
      student_email: currentStudent,
      student_name: "",
      batch: currentBatch,
      leetcode_counter: lcCount
    };

    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          const finalJson = { ...newForm, id_token: res?.data?.id_token };
          axios.post("/mentors/create_session", finalJson).then((res) => {
            if (res?.data?.success) {
              toast.success("Session details added successfully", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              
            } else {
              toast.error(res?.data?.error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setForm(initialValue)
          });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <ToastContainer/>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Batch</InputLabel>
            <Select
              name="batch"
              label="Batch"
              onChange={(e) => {
                setCurrentBatch(e.target.value);
              }}
              defaultValue={batch[0]}
            >
              {batch.map((b, index) => (
                <MenuItem value={b} key={index}>
                  {b}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            onChange={(event, value) => {
              const end = value.lastIndexOf("]");
              const start = value.lastIndexOf("[") + 1;
              const email = value.substring(start, end);
              setCurrentStudent(email);
              const lcStart = value.lastIndexOf("]")+1
              const lcEnd = value.length
              const lccounter = value.substring(lcStart, lcEnd)
              setLcCount(lccounter)
            }}
            options={students}
            renderInput={(params) => <TextField {...params} label="Student" />}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: 1, mb: 1 }} />

      {/* date */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Date desktop"
          inputFormat="dd/MM/yyyy"
          value={date}
          onChange={(newDate) => {
            setDate(newDate);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      
      {/* session_no */}
      <Typography variant="subtitle2" gutterBottom component="div">
        Session Number
      </Typography>
      

      {/* leetcode_counter */}
      <Typography variant="subtitle2" gutterBottom component="div">
        Leetcode Count {lcCount}
      </Typography>
      
      {/* how_course_work_is_ongoing */}
      <Typography variant="subtitle2" gutterBottom component="div">
        Feedback To Mentee
      </Typography>
      <TextareaAutosize
        name="how_course_work_is_ongoing"
        value={form.how_course_work_is_ongoing}
        onChange={handleChange}
        style={{ width: "100%" }}
        minRows={3}
      />

      {/* confidence */}
      <Typography variant="subtitle2" gutterBottom component="div">
        Milestones for mentee
      </Typography>
      <TextareaAutosize
        name="confidence"
        value={form.confidence}
        onChange={handleChange}
        style={{ width: "100%" }}
        minRows={3}
      />
     
      <Box textAlign="center" sx={{ m: 1 }}>
        <Button type="submit" onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default AddSessions;
