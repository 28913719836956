import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import CourseCard from "./components/CourseCard";
import { Box, Container, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";

import ReactGa from "react-ga";

import { LoadingButton } from "@mui/lab";
import OtherChaptersCourseCard from "./components/OtherChaptersCourseCard";
import RecordedLectures from "./components/RecordedLectures";
const UpdateCourse = () => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const [course, setCourse] = useState();
  const [loading, setLoading] = useState(false);
  const [allModules, setAllModules] = useState([]);
  const [chaptervalue, setChaptervalue] = useState(0);
  const [modules, setModules] = useState([]);
  const [batch, setBatch] = useState();
  const [value, setValue] = useState("1");
  const [extraClasses, setExtraClasses] = useState();

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#1d99be",
    "&:hover": {
      backgroundColor: "#1d8cbe",
    },
  }));

  const handelModuleChange = (event, newValue) => {
    setAllModules(JSON.parse(localStorage.getItem("allModules")));
    setChaptervalue(newValue);
    if (allModules?.length > 0) {
      setCourse(allModules[chaptervalue]?.course);
    }
    setCourse(allModules[newValue]?.course);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function upcomingLectures(value) {
    if (value.is_locked) {
      return value;
    }
  }
  function removeUpcomingLectures(value) {
    if (value.is_locked == false) {
      return value;
    }
  }
  function movedExtraLectures(value) {
    if (parseInt(value.id) > 999) {
      return value;
    }
  }
  function removeExtraLectures(value) {
    if (parseInt(value.id) < 999) {
      return value;
    }
  }
  useEffect(() => {
    axios
    .post("/common/refresh_token", {
      refresh_token: auth?.refreshToken,
    })
    .then((res) => {
      if (res?.data?.access_token) {
        var existing = JSON.parse(localStorage.getItem("jwt"));
        existing.data.idToken = res?.data?.access_token;
        localStorage.setItem("jwt", JSON.stringify(existing));
    }
    })
  }, []);

  const sendResponse = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/admin/get_batch_wise_course_v3", {
              batch,
            })
            .then((res) => {
              if (res?.data?.success == true) {
                setAllModules(res?.data?.course);
                setLoading(false);
                setCourse(res?.data?.course[chaptervalue]?.course);
                // setExtraClasses(res?.data?.other_lectures);
                setModules(res?.data?.modules);
              } else {
                setLoading(false);
                setTimeout(() => {
                  setLoading(false);
                }, 2000);
              }
            });
        }
      });
  };
  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ marginBottom: 5 }}>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Typography component="h1" variant="h5">
            Update Course
          </Typography> */}
          <Box component="form" sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="batch_name"
                  label="Batch Name"
                  name="batch_name"
                  autoComplete="batch_name"
                  value={batch}
                  onChange={(e) => setBatch(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <ColorButton
              onClick={sendResponse}
              variant="contained"
              loadingPosition="center"
              loadingIndicator="searching…"
              loading={loading}
              fullWidth
            >
              Search
            </ColorButton>
          </Box>
        </Box>
      </Container>
      <TabContext value={chaptervalue}>
        <Box sx={{ marginBottom: 5, marginTop: 4 }}>
          <Tabs
            value={chaptervalue}
            onChange={handelModuleChange}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{
              "& button.Mui-selected": {
                backgroundColor: "#1976D2",
                color: "white",
              },
            }}
            indicatorColor="white"
          >
            {modules?.map((c, i) => (
              <Tab label={c} sx={{ border: 1 }} />
            ))}
          </Tabs>
        </Box>
      </TabContext>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Upcoming Lectures" value="1" />
              <Tab label="Recorded Lectures" value="2" />
              <Tab label="Extra Lectures" value="3" />
            </TabList>
          </Box>

          {course?.filter(upcomingLectures)?.map((c, i) => (
            <TabPanel value="1">
              {
                <CourseCard
                  id={c.id}
                  image_link={c.image_link}
                  is_completed={c.is_completed}
                  is_locked={c.is_locked}
                  topic={c.topic}
                  date={c.date}
                  video_link={c.video_link}
                  batch={batch}
                  zoom_link={c.zoom_link}
                  module_name={c.module_name}
                />
              }
            </TabPanel>
          ))}
          {course
            ?.filter(removeUpcomingLectures)
            ?.reverse()
            ?.filter(removeExtraLectures)
            ?.map((c, i) => (
              <TabPanel value="2">
                {
                  <RecordedLectures
                    id={c.id}
                    image_link={c.image_link}
                    is_completed={c.is_completed}
                    is_locked={c.is_locked}
                    topic={c.topic}
                    date={c.date}
                    video_link={c.video_link}
                    batch={batch}
                    zoom_link={c.zoom_link}
                    module_name={c.module_name}
                  />
                }
              </TabPanel>
            ))}

          {course
            ?.filter(movedExtraLectures)
            ?.reverse()
            ?.map((c, i) => (
              <TabPanel value="3">
                {" "}
                {
                  <OtherChaptersCourseCard
                    id={c.id}
                    image_link={c.image_link}
                    is_completed={c.is_completed}
                    is_locked={c.is_locked}
                    topic={c.topic}
                    date={c.date}
                    video_link={c.video_link}
                    zoom_link={c.zoom_link}
                    module_name={c.module_name}
                  />
                }
              </TabPanel>
            ))}
        </TabContext>
      </Box>
    </>
  );
};

export default UpdateCourse;
