import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../api/authApi";
import axios from "../../../api/axios";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import Moment from "react-moment";
export default function AllMentorSessions() {
  const [value, setValue] = React.useState("1");

  const [cancelBooking, setCancelBooking] = React.useState(false);
  const [cancelDate, setCancelDate] = React.useState("");
  const [cancelStart, setCancelStart] = React.useState("");
  const [cancelEnd, setCancelEnd] = React.useState("");

  const [viewFeedback, setViewFeedback] = React.useState(false);
  const [mentorFeedbackAvailable, setMentorFeedbackAvailable] =
    React.useState(false);
  const [mentorRating, setMentorRating] = React.useState(0);
  const [mentorComment, setMentorComment] = React.useState("");

  const [writeFeedback, setWriteFeedback] = React.useState(false);
  const [studentFeedback, setStudentFeedback] = React.useState(0);
  const [studentActionItems, setStudentActionItems] = React.useState("");
  const [roomId, setRoomId] = React.useState("");

  const [allSessions, setAllSessions] = React.useState([]);
  const [cancelledSessions, setCancelledSessions] = React.useState([]);
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    // backgroundColor: " #1D99BE",
    // "&:hover": {
    //   backgroundColor: "#33A3C4",
    // },
    float: "right",
  }));

  useEffect(() => {
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/mentors/get_mentor_sessions", {
              id_token: res?.data?.id_token,
            })
            .then((res) => {
              setAllSessions(res.data.response.mentor_sessions);
              setUpcomingSessions(res.data.response.mentor_sessions);
              setCancelledSessions(res.data.response.cancelled_sessions);
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  }, []);

  const cancel_booking = (date, start, end) => {
    setCancelBooking(true);
    setCancelDate(date);
    setCancelStart(start);
    setCancelEnd(end);
  };

  const handleCancelDialogClose = (event) => {
    setCancelBooking(false);
  };

  const handleCancelDialogSubmit = (event) => {
    setLoading(true);
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/mentors/cancel_session", {
              id_token: res?.data?.id_token,
              date: cancelDate,
              start: cancelStart,
              end: cancelEnd,
            })
            .then((res) => {
              setLoading(false);
              setCancelBooking(false);
              setAllSessions(
                allSessions.filter(
                  (session) =>
                    !(
                      session.date == cancelDate &&
                      session.start == cancelStart &&
                      session.end == cancelEnd
                    )
                )
              );
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function sessionDone(done_state) {
    return function (x) {
      return x.done == done_state;
    };
  }

  const view_feedback = (row) => {
    setViewFeedback(true);
    if (row.student_review) {
      setMentorFeedbackAvailable(true);
      setMentorRating(row.student_review.rating);
      setMentorComment(row.student_review.comment);
      setCancelDate(row.date);
      setCancelStart(row.start);
      setCancelEnd(row.end);
    } else {
      setMentorFeedbackAvailable(false);
    }
  };

  const handleViewDialogClose = (event) => {
    setViewFeedback(false);
  };

  const write_feedback = (row) => {
    setWriteFeedback(true);
    if (row.mentor_feedback) {
      setStudentFeedback(row.mentor_feedback.feedback);
      setStudentActionItems(row.mentor_feedback.action_items);
    } else {
      setStudentFeedback("");
      setStudentActionItems("");
    }
    setCancelDate(row.date);
    setCancelStart(row.start);
    setCancelEnd(row.end);
    setRoomId(row.room_id);
  };

  const handleWriteDialogClose = (event) => {
    setWriteFeedback(false);
  };

  const changeStudentFeedback = (event) => {
    setStudentFeedback(event.target.value);
  };

  const changeStudentActionItems = (event) => {
    setStudentActionItems(event.target.value);
  };

  const handleSubmit = (event) => {
    axios
      .post("/mentors/write_student_feedback", {
        room_id: roomId,
        feedback: studentFeedback,
        action_items: studentActionItems,
      })
      .then((res) => {
        setWriteFeedback(false);
        let all_sessions = [...allSessions];
        var temp_element;
        var id = 0;
        for (var i = 0; i < all_sessions.length; i++) {
          if (all_sessions[i].room_id == roomId) {
            id = i;
            temp_element = all_sessions[i];
          }
        }
        temp_element.student_feedback = {
          feedback: studentFeedback,
          action_items: studentActionItems,
        };
        all_sessions[id] = temp_element;
        setAllSessions(all_sessions);
      });
  };

  const UpcomingComponent = (
    <div>
      {allSessions
        ?.sort(function (a, b) {
          return a.date?.localeCompare(b.date);
        })
        ?.filter(sessionDone(false))
        ?.map((row) => {
          return (
            <div>
              <Paper
                sx={{ p: "30px", backgroundColor: "FBFCF8", mb: 2 }}
                variant="outlined"
              >
                <h2 style={{ color: "grey" }}> Mock Interview </h2>
                <hr />

                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <CalendarMonthIcon />
                    <span style={{ marginLeft: "5px" }}>
                      <Moment format="MMM D, YYYY " date={row.date} />{" "}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <AccessTimeIcon />
                    <span style={{ marginLeft: "5px" }}>
                      {row.start}:00 - {row.end}:00
                    </span>
                  </div>
                  <hr />
                </div>
                <Grid container>
                  <Grid item xs={8}>
                    {row.student_id}
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() =>
                        cancel_booking(row.date, row.start, row.end)
                      }
                    >
                      {" "}
                      Cancel Booking
                    </Button>
                    <Dialog
                      open={cancelBooking}
                      onClose={handleCancelDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Cancel Session"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to cancel session on{" "}
                          {cancelDate} from {cancelStart}:00 to {cancelEnd}:00
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCancelDialogClose}> No </Button>
                        <ColorButton
                          onClick={handleCancelDialogSubmit}
                          variant="text"
                          loadingPosition="center"
                          loading={loading}
                        >
                          Yes
                        </ColorButton>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                  <Grid item xs={2} float="left">
                    <Button
                      variant="contained"
                      href={row.meet_link}
                      target="_blank"
                    >
                      Join meeting
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          );
        })}
    </div>
  );

  const PastComponent = (
    <div>
      {allSessions
        ?.sort(function (a, b) {
          return b.date?.localeCompare(a.date);
        })
        ?.filter(sessionDone(true))
        ?.map((row) => {
          return (
            <div>
              <Paper
                sx={{ p: "30px", backgroundColor: "FBFCF8", mb: 2 }}
                variant="outlined"
              >
                <h2 style={{ color: "grey" }}> Mock Interview </h2>
                <hr />
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <CalendarMonthIcon />
                    <span style={{ marginLeft: "5px" }}>
                      {" "}
                      <Moment format="MMM D, YYYY " date={row.date} />
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <AccessTimeIcon />
                    <span style={{ marginLeft: "5px" }}>
                      {row.start}:00 - {row.end}:00
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <TimelapseIcon />
                    <span style={{ marginLeft: "5px" }}>
                      {row.duration} minutes{" "}
                    </span>
                  </div>
                  <hr />
                </div>
                <Grid container>
                  <Grid item xs={6}>
                    {row.student_id}
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() =>
                        cancel_booking(row.date, row.start, row.end)
                      }
                    >
                      {" "}
                      Cancel Booking
                    </Button>
                    <Dialog
                      open={cancelBooking}
                      onClose={handleCancelDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Cancel Session"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to cancel session on{" "}
                          {cancelDate} from {cancelStart}:00 to {cancelEnd}:00
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCancelDialogClose}> No </Button>
                        <ColorButton
                          onClick={handleCancelDialogSubmit}
                          variant="text"
                          loadingPosition="center"
                          loading={loading}
                        >
                          Yes
                        </ColorButton>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={() => view_feedback(row)}
                    >
                      {" "}
                      View Review{" "}
                    </Button>
                    <Dialog
                      open={viewFeedback}
                      onClose={handleViewDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Review Details"}
                      </DialogTitle>
                      <DialogContent>
                        {mentorFeedbackAvailable ? (
                          <div>
                            Feedback for the mentor session on {cancelDate} from{" "}
                            {cancelStart}:00 to {cancelEnd}:00
                            <hr />
                            <Typography component="legend">
                              {" "}
                              Your rating given by Mentee
                            </Typography>
                            <Rating
                              name="read-only"
                              value={mentorRating}
                              readOnly
                            />
                            <br />
                            <Typography component="legend">
                              {" "}
                              Additional Comments{" "}
                            </Typography>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              value={mentorComment}
                              multiline
                              style={{ width: "100%" }}
                            />
                          </div>
                        ) : (
                          <div>
                            Your Mentee haven't written your feedback yet!
                          </div>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleViewDialogClose}> Close </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                  <Grid item xs={2} float="left">
                    <Button
                      variant="contained"
                      onClick={() => write_feedback(row)}
                    >
                      Student feedback
                    </Button>
                    <Dialog
                      open={writeFeedback}
                      onClose={handleWriteDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Feedback Details"}
                      </DialogTitle>
                      <DialogContent>
                        <div>
                          Student Feedback for the mentor session on{" "}
                          {cancelDate} from {cancelStart}:00 to {cancelEnd}:00
                          <hr />
                          <Typography component="legend"> Feedback</Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={studentFeedback}
                            onChange={changeStudentFeedback}
                            multiline
                            style={{ width: "100%" }}
                          />
                          <br />
                          <Typography component="legend">
                            {" "}
                            Action Items{" "}
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={studentActionItems}
                            onChange={changeStudentActionItems}
                            multiline
                            style={{ width: "100%" }}
                          />
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleWriteDialogClose}>
                          {" "}
                          Close{" "}
                        </Button>
                        <Button onClick={handleSubmit}> Submit </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          );
        })}
    </div>
  );

  const CancelledSessions = (
    <div>
      {cancelledSessions?.map((row) => {
        return (
          <div>
            <Paper
              sx={{ p: "30px", backgroundColor: "FBFCF8", mb: 2 }}
              variant="outlined"
            >
              <h2 style={{ color: "grey" }}> Mock Interview </h2>
              <hr />
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <CalendarMonthIcon />
                  <span style={{ marginLeft: "5px" }}>
                    {" "}
                    <Moment format="MMM D, YYYY " date={row.date} />
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <AccessTimeIcon />
                  <span style={{ marginLeft: "5px" }}>
                    {row.start}:00 - {row.end}:00
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <TimelapseIcon />
                  <span style={{ marginLeft: "5px" }}>
                    {row.duration} minutes{" "}
                  </span>
                </div>
                <hr />
              </div>
              <Grid container>
                <Grid item xs={6}>
                  {row.student_id}
                </Grid>
                <Grid item xs={2}>
                  <Dialog
                    open={cancelBooking}
                    onClose={handleCancelDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Cancel Session"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to cancel session on {cancelDate}{" "}
                        from {cancelStart}:00 to {cancelEnd}:00
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCancelDialogClose}> No </Button>
                      <ColorButton
                        onClick={handleCancelDialogSubmit}
                        variant="text"
                        loadingPosition="center"
                        loading={loading}
                      >
                        Yes
                      </ColorButton>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={2}>
                  {/* <Button variant="contained" onClick={() => view_feedback(row)}> View Feedback </Button>
                        <Dialog
                            open={viewFeedback}
                            onClose={handleViewDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Feedback Details"}
                            </DialogTitle>
                            <DialogContent>
                                {mentorFeedbackAvailable ?
                                    <div>
                                        Feedback for the mentor session on {cancelDate} from {cancelStart}:00 to {cancelEnd}:00
                                        <hr />
                                        <Typography component="legend"> Your rating given by Mentee</Typography>
                                        <Rating name="read-only" value={mentorRating} readOnly />
                                        <br />
                                        <Typography component="legend"> Additional Comments </Typography>
                                        <TextField id="outlined-basic"
                                            variant="outlined"
                                            value={mentorComment}
                                            multiline
                                            style={{ width: "100%" }} />
                                    </div>
                                    :
                                    <div>
                                        Your Mentee haven't written your feedback yet!
                                    </div>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleViewDialogClose}> Close </Button>
                            </DialogActions>
                        </Dialog> */}
                </Grid>
                <Grid item xs={2} float="left">
                  {/* <Button variant="contained" onClick={() => write_feedback(row)}>
                            Student feedback
                        </Button>
                        <Dialog
                            open={writeFeedback}
                            onClose={handleWriteDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Feedback Details"}
                            </DialogTitle>
                            <DialogContent>
                                <div>
                                    Student Feedback for the mentor session on {cancelDate} from {cancelStart}:00 to {cancelEnd}:00
                                    <hr />
                                    <Typography component="legend"> Feedback</Typography>
                                    <TextField id="outlined-basic"
                                        variant="outlined"
                                        value={studentFeedback}
                                        onChange={changeStudentFeedback}
                                        multiline
                                        style={{ width: "100%" }} />
                                    <br />
                                    <Typography component="legend"> Action Items </Typography>
                                    <TextField id="outlined-basic"
                                        variant="outlined"
                                        value={studentActionItems}
                                        onChange={changeStudentActionItems}
                                        multiline
                                        style={{ width: "100%" }} />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleWriteDialogClose}> Close </Button>
                                <Button onClick={handleSubmit}> Submit </Button>
                            </DialogActions>
                        </Dialog> */}
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
      })}
    </div>
  );

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <h1> Mentor Sessions </h1>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Upcoming" value="1" />
            <Tab label="Past" value="2" />
            <Tab label="Cancelled" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1"> {UpcomingComponent} </TabPanel>
        <TabPanel value="2"> {PastComponent} </TabPanel>
        <TabPanel value="3"> {CancelledSessions} </TabPanel>
      </TabContext>
    </Box>
  );
}
