import { useEffect } from "react";
import { CheckCircle } from "@mui/icons-material";
import { Grid, Paper, styled, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isAuthenticated, signout } from "../../../../api/authApi";
import axios from "../../../../api/axios";
import LoadingButton from "@mui/lab/LoadingButton";
import Moment from "react-moment";
import Chip from "@mui/material/Chip";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const ImgLocked = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  opacity: "50%",
});

const CourseCard = (props) => {
  const auth = isAuthenticated()?.data;
  const navigate = useNavigate();
  const {
    date,
    id,
    doc_id,
    is_completed,
    is_locked,
    topic,
    setProgress,
    module_name,
  } = props;
  const text = "";

  const [isCompleted, setIsCompleted] = useState(is_completed);
  const [loading, setLoading] = useState(false);
  const [progress, setprogress] = useState(0);

  const getProgress = (e) => {
    axios
      .post("/student/check_progress_v3", {
        id_token: isAuthenticated()?.data?.idToken,
        module: module_name,
      })
      .then((res) => {
        const countJSON = res.data.response;
        setProgress(countJSON.count);
        // localStorage.setItem(
        //   "progress",
        //   JSON.stringify(countJSON.count)
        // );
      });
  };
  // useEffect(() => {
  //  getProgress()
  // }, [progress])
  
  const changeIsComplete = (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    axios
      .post("/common/refresh_token", {
        refresh_token: auth?.refreshToken,
      })
      .then((res) => {
        if (res?.data?.access_token) {
          var existing = JSON.parse(localStorage.getItem("jwt"));
          existing.data.idToken = res?.data?.access_token;
          localStorage.setItem("jwt", JSON.stringify(existing));

          axios
            .post("/student/mark_lesson_complete_v3", {
              id_token: res?.data?.access_token,
              lesson_id: id,
              status: !isCompleted,
              module: module_name,
            })
            .then((res) => {
              if (res?.data?.success) {
                setIsCompleted((isCompleted) => !isCompleted);
                setLoading(false);
              }
              getProgress();
            });
        } else {
          signout(auth?.localId, () => {
            navigate("/");
          });
        }
      });
  };

  const replaceStartingTA = (str) => {
    if (str.startsWith("TA")) {
        return "Implementation lab" + str.slice(2);
    } else {
        return str;
    }
}

  return (
    <div className={!is_locked ? "course-card-container" : "course"}>
      <Link
        style={{ textDecoration: "none" }}
        to={!is_locked ? { pathname: `${doc_id}/${id}` } : "#"}
      >
        <Paper
          sx={{ p: 2, margin: "auto", flexGrow: 1, mb: -4 }}
          variant="outlined"
        >
          <Grid container>
            <Grid item>
              {/* <ButtonBase sx={{ width: 303, height: 80 }} disabled={true}>
                {!is_locked && <Img alt="complex" src={image_link} />}
                {is_locked && <ImgLocked alt="complex" src={image_link} />}
              </ButtonBase> */}
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ fontSize: 20, fontWeight: 500 }}
                  >
                    {replaceStartingTA(topic)}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ fontSize: 16, fontWeight: 200 }}
                  >
                    Master Class and Practice Question
                  </Typography>
                  <Typography
                    paragraph={true}
                    gutterBottom
                    sx={{ fontSize: 16 }}
                  >
                    {date ? (
                      <Chip
                        label={<Moment format="MMMM Do YYYY">{date}</Moment>}
                        color="primary"
                      />
                    ) : (
                      <div></div>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <LoadingButton
                variant="outlined"
                color={isCompleted ? "success" : "primary"}
                disabled={is_locked}
                onClick={changeIsComplete}
                loading={loading}
              >
                <CheckCircle sx={{ m: 1 }} />
                {is_completed && "Lesson Completed"}
                {!is_completed && "I completed this"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </Link>
    </div>
  );
};
export default CourseCard;
