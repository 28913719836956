import React, { Component } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import Loading from "../notificationPages/Loading";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import RunCodeDrawer from "./RunCodeDrawer";

import { IconButton, Tab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      topic: "",
      difficulty_level: "easy",
      likes: 0,
      dislikes: 0,
      question_details: "",
      liked: false,
      disliked: false,
      leetcode_link: "",
      question_number: 1,
      problem_name: "",
      submissons: [],
      value: this.props.value,
      testCases: [],
      status: "Running...",
    };
  }

  componentDidMount() {
    const q = this.props;
    this.setState({
      tags: q.tags,
      topic: q.topic,
      difficulty_level: q.difficulty_level,
      likes: q.likes,
      dislikes: q.dislikes,
      question_details: q.question_details,
      question_number: q.question_number,
      leetcode_link: q.leetcode_link,
      problem_name: q.problem_name,
      liked: q.liked,
      disliked: q.disliked,
      inputs: q.inputs,
      constraints: q.constraints,
      testCases: this.props.runCodeSubmission,
    });
  }

  changeLikeButtonToTrue = (e) => {
    if (this.state.disliked) {
      this.setState({
        testCases: this.props.runCodeSubmission,
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.runCodeSubmission !== this.props.runCodeSubmission) {
      this.setState({
        testCases: this.props.runCodeSubmission,
      });
    }

    if(prevProps.status !== this.props.status) {
      this.setState({
        status : this.props.status,
      });
    }
  }
  render() {
    
    const BootstrapTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        paddingLeft: "32px",
        paddingRight: "32px",
        borderRadius: "8px",
      },
    }));
    let input_output = "";
    if (this.state && this.state.inputs) {
      this.state?.inputs?.map((input, index) => {
        input_output += input.value + " ";
      });
    }
    //   const htmlString_ = `
    // <div>
    //   <p>${this.state.question_details}</p>
    //   <p style="background-color: white;  color: black; padding: 20px; border-radius: 8px; box-shadow: 0px 4px 16px rgba(32, 76, 188, 0.2)">${input_output}</div>
    //   <p style="background-color: white; color: black; padding: 20px; border-radius: 8px; box-shadow: 0px 4px 16px rgba(32, 76, 188, 0.2)">${this.state.constraints}</p>
    //   </div>
    //   `;
    //   // <p>${this.state.constraints}</p>

    return this.props.loading == false ? (
      <div style={{ ml: 4, mr: 4, color: "#000000" }}>
        <span>
          <Chip
            label={
              this.state.difficulty_level[0].toUpperCase() +
              this.state.difficulty_level.substring(1)
            }
            sx={{
              backgroundColor: "#4ADE80",
              color: "white",
              padding: "4px",
              height: "24px",
              mb: 2,
              mt: 6,
            }}
          />
        </span>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent="end"
          alignItems="end"
          sx={{ flexWrap: "wrap", marginTop: 6, width: "50%", float: "right" }}
        >
          <span style={{}}>
            <BootstrapTooltip title={this.props.likes} placement="top-start">
              {this.props.liked ? (
                <ThumbUpAltIcon
                  style={{ marginTop: "4px", color: "#A3A3A3" }}
                  onClick={this.props.changeLikeButtonToFalse}
                />
              ) : (
                <ThumbUpOffAltIcon
                  style={{ marginTop: "4px", color: "#A3A3A3" }}
                  onClick={this.props.changeLikeButtonToTrue}
                />
              )}
            </BootstrapTooltip>
          </span>

          <span style={{}}>
            <BootstrapTooltip title={this.props.dislikes} placement="top-start">
              {this.props.disliked ? (
                <ThumbDownAltIcon
                  style={{
                    marginTop: "4px",
                    marginLeft: "10px",
                    color: "#A3A3A3",
                  }}
                  onClick={this.props.changeDislikeButtonToFalse}
                />
              ) : (
                <ThumbDownOffAltIcon
                  style={{
                    marginTop: "4px",
                    marginLeft: "10px",
                    color: "#A3A3A3",
                  }}
                  onClick={this.props.changeDislikeButtonToTrue}
                />
              )}
            </BootstrapTooltip>
          </span>
        </Stack>

        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 700,
            mt: 1,
            mb: "14px",
            lineHeight: "21.94px",
            color: "#312E81",
          }}
        >
          {this.props.question_number}. {this.props.problem_name}
        </Typography>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{ flexWrap: "wrap", mt: 4, mb: 4 }}
        >
          <span>
            {this.props.tags.map((tag) => {
              return (
                <span style={{}}>
                  <Chip
                    label={tag[0]?.toUpperCase() + tag.substring(1)}
                    sx={{
                      height: "24px",
                      color: "#312E81",
                      borderColor: "#312E81",
                      pt: "4px",
                      pb: "4px",
                      pr: "21px",
                      pl: "21px",
                      mr: 2,
                      borderRadius: 1,
                    }}
                    variant="outlined"
                  />
                </span>
              );
            })}
          </span>
        </Stack>
        {/* {typeof this.state.inputs === "A"} */}
        {/* <div
          dangerouslySetInnerHTML={{ __html: this.state.question_details  + input_output + this.state.constraints}}
        /> */}
        <div
          dangerouslySetInnerHTML={{ __html: this.props.question_details }}
        />
        {this.state?.inputs?.length < 2
          ? null
          : this.state &&
            this.state.inputs &&
            this.state.inputs.map((input, index) => (
              <div
                style={{
                  marginBottom: "5%",
                  backgroundColor: "white",
                  color: "black",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 16px rgba(32, 76, 188, 0.2)",
                }}
                key={index}
                dangerouslySetInnerHTML={{ __html: input.value }}
              />
            ))}
        {this.state.constraints === "" ? null : (
          <div
            style={{
              marginBottom: "5%",
              backgroundColor: "white",
              color: "black",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 4px 16px rgba(32, 76, 188, 0.2)",
            }}
            dangerouslySetInnerHTML={{ __html: this.state.constraints }}
          />
        )}
        {/* <RunCodeDrawer
          handleCloseDrawer={this.props.handleCloseDrawer}
          handleChangeDrawerTab={this.props.handleChangeDrawerTab}
          value={this.props.value}
          isOpen={this.props.isOpen}
          runCodeSubmission={this.props.runCodeSubmission}
          errorMessage={this.props.errorMessage}
          status={this.props.statusMessage}
          runCodeLoading={this.props.runCodeLoading}
          handleOpenDrawer={this.props.handleOpenDrawer}
          handleAddTestCase={this.props.handleAddTestCase}
          handleChipClick={this.props.handleChipClick}
          handleRemoveTestCase={this.props.handleRemoveTestCase}
          selectedTestCase={this.props.selectedTestCase}
        /> */}

        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "white",
            height: "80%",
            boxShadow: "0px 4px 16px rgba(32, 76, 188, 0.2)",
            transition: "0.6s ease",
            transform: `translateY(${this.props.isOpen ? "0" : "92.5%"})`,
            borderRadius: "4px",
            marginLeft: "24px",
            width: "calc(100% - 60px)",
            overflow: "auto",
          }}
        >
          {this.props.isOpen ? (
            <IconButton
              style={{ position: "absolute", top: 8, right: 10, zIndex: 10 }}
              onClick={this.props.handleCloseDrawer}
            >
              <RemoveIcon />
            </IconButton>
          ) : (
            <IconButton
              style={{ position: "absolute", top: 8, right: 10, zIndex: 10 }}
              onClick={this.props.handleOpenDrawer}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
          )}
          <div>
            <div>
              <TabContext value={this.props.drawerValue}>
                <Box sx={{ borderBottom: 1, borderColor: "#EEF2FF" }}>
                  <TabList
                    onChange={this.props.handleChangeDrawerTab}
                    aria-label="lab API tabs example"
                    sx={{
                      "& button.Mui-selected": {
                        // backgroundColor: "#312E81",

                        color: "#312E81",
                      },
                      ".MuiTabs-indicator": {
                        backgroundColor: "inherit",
                      },
                    }}
                  >
                    <Tab
                      label="Run Code"
                      value="1"
                      style={{
                        // color: "#312E81",

                        textTransform: "none",
                        fontWeight: 700,
                      }}
                    />
                    {/* <Tab
                        label="Test Case"
                        value="2"
                        style={{
                          // color: "#312E81",
                          textTransform: "none",
                          fontWeight: 700,
                        }}
                        indicatorColor="white"
                      /> */}
                  </TabList>
                </Box>
                <TabPanel
                  value="1"
                  sx={{
                    minHeight: "100%",
                  }}
                >
                  {this.props.runCodeLoading === true ? (
                    <div>{this.state.status}</div>
                  ) : (
                    <div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {this.state.testCases?.map((testCase, i) => (
                          <Chip
                            key={testCase.id}
                            label={"Testcase " + (1 + i)}
                            // deleteIcon={<RemoveIcon />}
                            onClick={() => this.props.handleChipClick(testCase)}
                            // onDelete={() =>
                            //   this.handleRemoveTestCase(testCase)
                            // }
                            sx={{
                              "& .MuiChip-label": {
                                color:
                                  testCase.result === 0 ? "#F87171" : "#22C55E",
                                // testCase.result !== 1  || 0 ? "#22C55E" : "#312E81"
                              },
                              "&&:focus": {
                                // borderColor: "#312E81",
                                border: "1px solid #312E81",
                              },
                              backgroundColor: "#F5FDFF",
                              borderRadius: "4px",
                              margin: "8px",
                            }}
                            // variant="outlined"
                          />
                        ))}

                        {/* <Chip
                            label="Add"
                            variant="contained"
                            sx={{
                              backgroundColor: "#F5FDFF",
                              borderRadius: "4px",
                              marginTop: "8px",
                              color: "grey",
                            }}
                            onClick={this.handleAddTestCase}
                          >
                            {" "}
                          </Chip> */}
                        <div className="container">
                          <h4>{this.props.errorMessage}</h4>
                        </div>
                      </div>

                      {this.props.selectedTestCase && (
                        <div>
                          <div
                            style={{
                              color: "#737373",
                              marginBottom: "8px",
                              fontSize: "16px",
                            }}
                          >
                            Input:
                          </div>

                          <div
                            style={{
                              backgroundColor: "#F5FDFF",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "16px",
                              color: "#312E81",
                              marginBottom: "16px",
                            }}
                          >
                            {this.props.selectedTestCase.input}
                          </div>

                          <div
                            style={{
                              color: "#737373",
                              marginBottom: "8px",
                              fontSize: "16px",
                            }}
                          >
                            Output:
                          </div>
                          <div
                            style={{
                              backgroundColor: "#F5FDFF",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "16px",
                              marginBottom: "16px",
                              color:
                                this.props.selectedTestCase.expected_output ===
                                this.props.selectedTestCase.output
                                  ? "#22C55E"
                                  : "#F87171",
                            }}
                          >
                            {this.props.selectedTestCase.output?.length === 0
                              ? 0
                              : this.props.selectedTestCase.output}
                          </div>
                          <div
                            style={{
                              color: "#737373",
                              marginBottom: "8px",
                              fontSize: "16px",
                            }}
                          >
                            Expected Output:
                          </div>
                          <div
                            style={{
                              backgroundColor: "#F5FDFF",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "16px",
                              marginBottom: "16px",
                            }}
                          >
                            {this.props.selectedTestCase.expected_output}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </TabPanel>
                <TabPanel
                  value="2"
                  sx={{
                    // backgroundColor: "#EEF2FF",
                    minHeight: "100%",
                  }}
                >
                  <div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {this.state.testCases?.map((testCase, i) => (
                        <Chip
                          key={testCase.id}
                          label={"Testcase " + (1 + i)}
                          deleteIcon={<RemoveIcon />}
                          onClick={() => this.props.handleChipClick(testCase)}
                          onDelete={() =>
                            this.props.handleRemoveTestCase(testCase)
                          }
                          sx={{
                            "& .MuiChip-label": {
                              color:
                                testCase.result === 0 ? "#F87171" : "#22C55E",
                            },
                            backgroundColor: "#F5FDFF",
                            borderRadius: "4px",
                            margin: "8px",
                          }}
                          variant="contained"
                        />
                      ))}

                      <Chip
                        label="Add"
                        variant="contained"
                        sx={{
                          backgroundColor: "#F5FDFF",
                          borderRadius: "4px",
                          marginTop: "8px",
                          color: "grey",
                        }}
                        onClick={this.props.handleAddTestCase}
                      >
                        {" "}
                      </Chip>
                      <div className="container">
                        <h4>{this.props.errorMessage}</h4>
                      </div>
                    </div>

                    {this.props.selectedTestCase && (
                      <div>
                        <div
                          style={{
                            color: "#737373",
                            marginBottom: "8px",
                            fontSize: "16px",
                          }}
                        >
                          Input:
                        </div>

                        <div
                          style={{
                            backgroundColor: "#F5FDFF",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            paddingLeft: "16px",
                            color: "#312E81",
                            marginBottom: "16px",
                          }}
                        >
                          {this.props.selectedTestCase.input}
                        </div>

                        <div
                          style={{
                            color: "#737373",
                            marginBottom: "8px",
                            fontSize: "16px",
                          }}
                        >
                          Output:
                        </div>
                        <div
                          style={{
                            backgroundColor: "#F5FDFF",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            paddingLeft: "16px",
                            marginBottom: "16px",
                            color:
                              this.props.selectedTestCase.expected_output ===
                              this.props.selectedTestCase.output
                                ? "green"
                                : "red",
                          }}
                        >
                          {this.props.selectedTestCase.output?.length === 0
                            ? 0
                            : this.props.selectedTestCase.output}
                        </div>
                        <div
                          style={{
                            color: "#737373",
                            marginBottom: "8px",
                            fontSize: "16px",
                          }}
                        >
                          Expected Output:
                        </div>
                        <div
                          style={{
                            backgroundColor: "#F5FDFF",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            paddingLeft: "16px",
                            marginBottom: "16px",
                          }}
                        >
                          {this.props.selectedTestCase.expected_output}
                        </div>
                      </div>
                    )}
                  </div>
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }
}
export default Question;
